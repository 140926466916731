import { Button, Form, Group, Link, Text, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { defaults, isEmpty } from 'lodash';
import { useEffect } from 'react';

import { EmailLoginInputModel, FormWidgetProps } from '../../types';
import { useEmailValidationFormSchema } from './useEmailValidationFormSchema.ts';

export type EmailVerificationFormCardProps = FormWidgetProps<EmailLoginInputModel> & {
  isReadOnly?: boolean;
  onDirty?: (isDirty: boolean, cb: () => void | Promise<void>) => void;
  contactUrl: string;
  onValidationError?: (isRequiredError: boolean) => void;
  onContactSupportClick: () => void;
};

export const EmailVerificationFormCardWidget = forwardRef<EmailVerificationFormCardProps, 'div'>(
  (
    {
      isSaving,
      isReadOnly,
      defaultValues: _defaultValues,
      contactUrl,
      onContactSupportClick,
      onSubmissionStateChange,
      onValidationError,
      onSubmit,
      onDirty,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const defaultValues = defaults(_defaultValues, {
      email: '',
    });
    const {
      registerField,
      formProps,
      submitButtonProps,
      trigger,
      formState: { isDirty, isValid, errors },
    } = useMelioForm<EmailLoginInputModel>({
      onSubmit,
      schema: useEmailValidationFormSchema(),
      onSubmissionStateChange,
      defaultValues,
      isSaving,
    });

    useEffect(() => {
      if (onDirty) {
        const { onClick } = submitButtonProps;
        onDirty(isDirty && !isValid, onClick);
      }
    }, [isDirty, isValid, trigger, onDirty, submitButtonProps]);

    useEffect(() => {
      if (onValidationError && !isEmpty(errors)) {
        const isRequiredError = !!Object.values(errors).some((error) => error?.type === 'required');
        onValidationError(isRequiredError);
      }
    }, [errors, onValidationError]);

    const readOnlyHelperLabel = formatMessage('vex.widgets.vendorOnboarding.emailVerification.email.helperText', {
      contactSupportLink: (
        <Text textStyle="body4" color="brand.main">
          <Link
            onClick={onContactSupportClick}
            color="inherit"
            href={contactUrl || ''}
            label={formatMessage('vex.widgets.vendorOnboarding.emailVerification.email.helperTextContactSupportLink')}
            newTab
          />
        </Text>
      ),
    });

    return (
      <Group
        spacing="l"
        variant="vertical"
        width="full"
        ref={ref}
        data-component="EmailVerificationFormCardWidget"
        data-testid="EmailVerificationFormCardWidget"
        {...props}
      >
        <Form {...formProps} isReadOnly={isReadOnly} data-testid="email-verification-form">
          <Group variant="vertical">
            <Form.TextField
              labelProps={{ label: formatMessage('vex.widgets.vendorOnboarding.emailVerification.email.label') }}
              {...registerField('email')}
              columns={0}
              helperTextProps={isReadOnly ? { label: readOnlyHelperLabel } : undefined}
            />
          </Group>
        </Form>
        <Group justifyContent="flex-end">
          <Button
            data-testid="send-code"
            {...submitButtonProps}
            label={formatMessage('vex.widgets.vendorOnboarding.emailVerification.sendCode')}
            size="medium"
          />
        </Group>
      </Group>
    );
  }
);

EmailVerificationFormCardWidget.displayName = 'EmailVerificationFormCardWidget';
