import { ApiCreateRequest, extractData } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';

import { ApiError, ApiQueryKeyV2, MutationCallbackOptions, MutationContext, PromiseFunctionReturnData } from './types';
import { useMelioQueryClientV2 } from './useMelioQueryClient';

export function useCreateMutationV2<
  TQueryFn extends ApiCreateRequest,
  TVariables = Parameters<TQueryFn>[0],
  TData = PromiseFunctionReturnData<TQueryFn>,
  TError = ApiError
>(
  _mutationFn: TQueryFn | undefined,
  queryKey: ApiQueryKeyV2,
  options: MutationCallbackOptions<
    PromiseFunctionReturnData<TQueryFn>,
    Parameters<TQueryFn>[0],
    TVariables,
    TError
  > = {}
) {
  const queryClient = useMelioQueryClientV2();

  const mutationFn =
    _mutationFn &&
    ((params: TVariables) => _mutationFn(options.prepareData?.(params) ?? params)?.then(extractData<unknown, TData>));

  const mutation = useMutation<PromiseFunctionReturnData<TQueryFn>, TError, TVariables, MutationContext<TData>>(
    queryKey,
    mutationFn,
    {
      onSuccess: (data, variable, context) => {
        void queryClient.invalidateQueries(queryKey[0], 'collection');
        void queryClient.invalidateQueries(queryKey[0], 'paginated-collection');
        void options.onSuccess?.(options.select ? options.select(data) : data, variable, context);
        void options.onSuccessInternal?.(data, variable, context);
      },
      onError: options.onError,
    }
  );

  return mutation;
}
