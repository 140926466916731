import { isString } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { useMutation, useQueryClient } from 'react-query';

// eslint-disable-next-line no-restricted-imports, import/no-unresolved
import {
  ApiQueryKey,
  EntityQueryKey,
  ModelId,
  OnMutationError,
  OnMutationMutate,
  QueryKeyEnum,
  Role,
  Scope,
} from './types';

export const defaultScope: Scope = 'default';

type QueryKeyParams = {
  queryKey: EntityQueryKey;
  role: Role;
  scope: Scope;
  id?: ModelId;
  params?: unknown[];
};

export const useMelioMutation = useMutation;

const cleanQueryKey = (queryKey: EntityQueryKey): [QueryKeyEnum, unknown[]] => {
  if (isString(queryKey)) {
    return [queryKey, []];
  }
  if (Array.isArray(queryKey)) {
    const [key, ...rest] = queryKey;
    if (!isString(key)) {
      throw new Error(`expected the first element of queryKey to be a string, found ${typeof key}`);
    }
    return [key, rest.filter((value) => !!value)];
  }
  throw new Error('expected queryKey to be a string or an array');
};

export function createQueryKey({ queryKey: key, role, scope, id, params = [] }: QueryKeyParams): ApiQueryKey {
  const [queryKey, extraParams] = cleanQueryKey(key);
  if (role === 'collection') {
    return [queryKey, role, scope, ...params, ...extraParams];
  } else {
    return [queryKey, role, id, scope, ...params, ...extraParams];
  }
}

export function useOnUpdateMutate<TData, TVariables>(queryKey: ApiQueryKey): OnMutationMutate<TData, TVariables> {
  const queryClient = useQueryClient();
  return async (data: TVariables) => {
    await queryClient.cancelQueries(queryKey);
    const previousData = queryClient.getQueryData<TData>(queryKey);
    queryClient.setQueriesData(queryKey, data);
    return previousData ? { previousData } : {};
  };
}

export function useOnUpdateError<TData, TVariables>(queryKey: ApiQueryKey) {
  const queryClient = useQueryClient();
  const onError: OnMutationError<TData, TVariables> = (_error, _dataToUpdate, context) => {
    if (context?.previousData) {
      queryClient.setQueriesData(queryKey, context.previousData);
    }
    return Promise.reject(_error);
  };
  return onError;
}
