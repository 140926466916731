import React, { useMemo, useState } from 'react';

import { useIsSubscriptionsEnabled } from '../hooks/useIsSubscriptionsEnabled';
import { ContextualPaywallModalActivity, OnboardingPaywallModalActivity } from '../ui';
import { SubscriptionContext } from './SubscriptionContext';
import { SubscriptionContextualPaywallProps, SubscriptionProviderProps } from './types';
import { useSubscriptionData } from './useSubscriptionData';

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({
  LoaderComponent,
  ErrorComponent,
  partnerGroup,
  children,
}) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  const {
    isFetching: isSubscriptionDataFetching,
    isError: isSubscriptionDataError,
    subscription,
    plans,
  } = useSubscriptionData({
    // parent comp may useSubscriptionData() to prefetch subscription data. when org has no subscription, we return a 404 error.
    // retrying on mount will invalidate the parent's query. since parent may unmount the provider while data is loading we can end up with an infinite remount loop.
    retryOnMount: false,
  });

  const [paywallProps, setPaywallProps] = useState<SubscriptionContextualPaywallProps | null>(null);

  const value = useMemo(
    () => ({
      partnerGroup,
      plans,
      subscription,
      contextualPaywall: {
        paywallProps,
        showPaywall: setPaywallProps,
        hidePaywall: () => setPaywallProps(null),
      },
    }),
    [partnerGroup, plans, subscription, paywallProps]
  );

  if (isSubscriptionDataFetching) {
    return <LoaderComponent />;
  }

  if (isSubscriptionDataError) {
    return <ErrorComponent />;
  }

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
      {isSubscriptionsEnabled && <OnboardingPaywallModalActivity />}
      {isSubscriptionsEnabled && <ContextualPaywallModalActivity />}
    </SubscriptionContext.Provider>
  );
};
