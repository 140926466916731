import { useMemo } from 'react';
import { getPartnerLocalStorage } from '@melio/local-storage';

import { getPartnerNameFromUrl } from '@/utils/partner.utils';

export const usePartnerLocalStorage = () => {
  const partnerName = getPartnerNameFromUrl();

  return useMemo(() => getPartnerLocalStorage({ partnerName }), [partnerName]);
};
