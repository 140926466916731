import { getPartnerLocalStorage, LocalStorageKeys } from '@melio/local-storage';

import { getPreferences } from '@/api/preferences.api';
import { defaultConfig } from '@/configByPartner/default.config';
import { getPartnerNameFromUrl } from '@/utils/partner.utils';
import { getEnvironmentName } from './url.utils';

const getBasePath = () => {
  const configBasePath = defaultConfig.sizzersApi?.baseUrl;

  if (configBasePath) {
    return configBasePath;
  }

  // Solution for new environments: extracting dynamic environment name from the domain
  const envName = getEnvironmentName();
  return `https://app.${envName}.meliopayments.com`;
};
const getMelioMeBasePath = () => {
  const configBasePath = defaultConfig.sizzersApi?.meliomeUrl;
  if (configBasePath) {
    return configBasePath;
  }

  // Solution for new environments: extracting dynamic environment name from the domain
  const envName = getEnvironmentName();
  return `https://app.${envName}.meliopayments.com/meliome/pay`;
};

export const generateMelioRedirectPath = (accessToken: string, redirectUrl: string) => {
  const basePath = getBasePath();

  return `${basePath}/api/platform/redirect-to-meliocom?access_token=${accessToken}&redirect_url=${encodeURIComponent(
    redirectUrl,
  )}`;
};

export const generatePaymentPagePath = (ownedVendorHandle: string) => {
  const basePath = getMelioMeBasePath();

  return `${basePath}/${ownedVendorHandle}`;
};

export const generateDeepLinkToPaymentRequestPayDashboard = (paymentRequestId: string) => {
  const meliocomWebBasePath = getBasePath();

  return `${meliocomWebBasePath}/orgs/payment-request/${paymentRequestId}/pay`;
};

enum MelioMigrationPreferences {
  MigratedWhitelist = 'isMigratedToPlatform',
  revertedBlacklist = 'isRevertedToLegacy',
}
const shouldReturnToMelioByWhitelist = async () => {
  const orgPreferences = await getPreferences();
  const isMigrated = orgPreferences.find((pre) => pre.key === MelioMigrationPreferences.MigratedWhitelist);

  return !(isMigrated && isMigrated.value == 'true');
};

const shouldReturnToMelioByBlacklist = async () => {
  const orgPreferences = await getPreferences();
  const isReverted = orgPreferences.find((pre) => pre.key === MelioMigrationPreferences.revertedBlacklist);

  return isReverted && isReverted.value == 'true';
};

export const getMeliocomOrgUrl = async ({
  isMelioMigrationWhitelistToBlacklistEnabled,
}: {
  isMelioMigrationWhitelistToBlacklistEnabled: boolean;
}) => {
  const shouldReturnToMelio = isMelioMigrationWhitelistToBlacklistEnabled
    ? await shouldReturnToMelioByBlacklist()
    : await shouldReturnToMelioByWhitelist();
  if (shouldReturnToMelio) {
    const partnerName = getPartnerNameFromUrl();
    const localStorage = getPartnerLocalStorage({ partnerName });
    const accessToken = localStorage.getItem(LocalStorageKeys.accessToken) || '';
    localStorage.removeItem(LocalStorageKeys.accessToken);

    return generateMelioRedirectPath(accessToken, '');
  }

  return '';
};
