import type { BoxProps } from '@chakra-ui/react';
import { DataToPrefillAnOnboardingForm, useVendorOnboardingSession } from '@melio/platform-api';

type VendorOnboardingData = {
  theme: {
    leftSideColor: BoxProps['backgroundColor'];
    textColor: BoxProps['backgroundColor'];
  };
  integrationName: string;
  logoUrl: string;
  faviconUrl: string;
  isLoading: boolean;
  returnUrl: string | undefined;
  feeData: {
    feeCap: number;
    feePercentage: number;
  };
  prePopulatedData: DataToPrefillAnOnboardingForm;
};

export const useVendorOnboardingData = (onboardingSessionUuid: string): VendorOnboardingData => {
  const { data, isLoading } = useVendorOnboardingSession({ onboardingSessionUuid });
  const leftSideColor = (data?.partner?.primaryColor || 'brand.main') as BoxProps['backgroundColor'];
  const textColor = (data?.partner?.secondaryColor || 'neutral.white') as BoxProps['backgroundColor'];
  const integrationName = data?.partner?.name || '';
  const logoUrl = data?.partner?.logoUrl || '';
  const faviconUrl = data?.partner?.faviconUrl || '';
  const returnUrl = data?.redirectUrl ?? undefined;
  const feeCap = data?.platformFee?.cap || 5;
  const feePercentage = data?.platformFee?.value || 1;
  const prePopulatedData = data?.formData || {};

  return {
    theme: {
      leftSideColor,
      textColor,
    },
    feeData: {
      feeCap,
      feePercentage,
    },
    integrationName,
    logoUrl,
    faviconUrl,
    isLoading,
    returnUrl,
    prePopulatedData,
  } as const;
};
