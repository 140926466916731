import { Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

export type AccountUpgradeRequestModalScreenProps = {
  handleDismiss: VoidFunction;
};

export const AccountUpgradeRequestModalScreen = ({ handleDismiss }: AccountUpgradeRequestModalScreenProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Modal
      isOpen
      onClose={handleDismiss}
      data-testid="account-upgrade-request-modal-screen"
      header={formatMessage('activities.subscription.accountUpgradeRequest.header')}
      primaryButton={{
        onClick: handleDismiss,
        variant: 'primary',
        label: formatMessage('activities.subscription.accountUpgradeRequest.done'),
      }}
    >
      <Text textStyle="body3">{formatMessage('activities.subscription.accountUpgradeRequest.content')}</Text>
    </Modal>
  );
};

AccountUpgradeRequestModalScreen.displayName = 'AccountUpgradeRequestModalScreen';
