import { Container, Group, Icon, NakedButton, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

type ContextualFeaturesListProps = {
  featureCapabilities: string[];
  isUpgrade: boolean;
};

export const ContextualFeaturesList = ({ featureCapabilities, isUpgrade }: ContextualFeaturesListProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const onSeeAllFeaturesClick = () => {
    track('Organization', 'Click', {
      Intent: 'all-features-list',
      Flow: 'subscription',
      Cta: 'all-features-list',
    });
  };

  return (
    <Container as="ul">
      {featureCapabilities.map((item) => (
        <Container key={item} paddingBottom="xs" as="li">
          <Group alignItems="center" spacing="s" key={item}>
            <Icon type="checked" size="small" color="brand" aria-hidden="true" />
            <Text textStyle="body3" color="neutral.darkest">
              {item}
            </Text>
          </Group>
        </Container>
      ))}
      {isUpgrade && (
        <NakedButton
          label={formatMessage('activities.subscription.contextualPlans.card.seeAllFeatures')}
          variant="secondary"
          onClick={onSeeAllFeaturesClick}
        />
      )}
    </Container>
  );
};
