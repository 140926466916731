import { BaseModal, Container, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useLocation } from '@melio/platform-utils';

import { useIsSubscriptionsEnabled, useRedirects, useSubscribeToFreePlan, useSubscription } from '../../../hooks';
import { CHECKOUT_URL, PAYMENT_METHOD_BANK_SELECT_URL, PLANS_URL } from '../../utils';
import { SubscriptionPlansActivity } from '../SubscriptionPlans';

const ONBOARDING_PAYWALL_URL_BLACKLIST = [PAYMENT_METHOD_BANK_SELECT_URL, PLANS_URL, CHECKOUT_URL];

export const OnboardingPaywallModalActivity = () => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { isExtraSmallScreen } = useBreakpoint();
  const { pathname } = useLocation();
  const { subscribeToFreePlan, isSubscribingToFreePlan } = useSubscribeToFreePlan();
  const { goToReturnUrlOrDashboard } = useRedirects();
  const { track } = useAnalytics();

  const modalProps = { maxW: '1100px' };
  const modalPadding = isExtraSmallScreen ? 'm' : 'xxl';

  const handleOnClose = () => {
    track('Organization', 'Click', {
      PageName: 'plan-selection',
      Intent: !subscription ? 'free-tier' : 'exit',
      Flow: 'exit-subscription',
      Cta: 'exit',
      Plan: !subscription ? 'free' : undefined,
    });
    if (isSubscribingToFreePlan) {
      return;
    }

    if (!subscription) {
      void subscribeToFreePlan();
    } else {
      goToReturnUrlOrDashboard();
    }
  };

  if (!isSubscriptionsEnabled) {
    return null;
  }

  const isOpen = !subscription && !ONBOARDING_PAYWALL_URL_BLACKLIST.includes(pathname);

  return (
    <BaseModal
      {...modalProps}
      isOpen={isOpen}
      onClose={handleOnClose}
      data-testid="onboarding-paywall-activity"
      size="large"
    >
      <Container paddingX={modalPadding} paddingTop={modalPadding} paddingBottom="m" overflow="auto">
        <SubscriptionPlansActivity returnUrl={pathname} />
      </Container>
    </BaseModal>
  );
};
