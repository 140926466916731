import { Button, Divider, Grid, useTheme } from '@melio/penny';

import type { Plan, SubscriptionBillingCycleEnum } from '../../../../api';
import { usePlanInfo } from '../../../../hooks';
import { ContextualFeaturesList } from './ContextualFeaturesList';
import { ContextualPlanCardHeader } from './ContextualPlanCardHeader';
import { ContextualPlanPriceSection } from './ContextualPlanPriceSection';

type ContextualPlanCardProps = {
  upsellContext: {
    capabilities: string[];
    description: string;
    actionButtonLabel?: string;
  };
  plan: Plan;
  billingCycle: SubscriptionBillingCycleEnum;
  isCurrentPlan?: boolean;
  onClick: () => void;
};

export const ContextualPlanCard = ({
  upsellContext,
  plan,
  billingCycle,
  isCurrentPlan,
  onClick,
}: ContextualPlanCardProps) => {
  const { planName } = usePlanInfo(plan.id);
  const theme = useTheme();

  return (
    <Grid
      border={`1px solid ${theme.colors.neutral.light}`}
      borderRadius="l"
      gridTemplateRows="subgrid"
      gridRow="auto / span 5"
      p="m"
      gap="l"
    >
      <ContextualPlanCardHeader title={planName} description={upsellContext.description} isCurrent={!!isCurrentPlan} />
      <ContextualPlanPriceSection billingCycle={billingCycle} price={plan.cycles['monthly'].basePrice} />
      <Divider />
      <ContextualFeaturesList featureCapabilities={upsellContext.capabilities} isUpgrade={!isCurrentPlan} />
      {upsellContext.actionButtonLabel && (
        <Button
          data-testid={`contextual-paywall-card-${isCurrentPlan ? 'current' : 'upgrade'}-button`}
          isFullWidth
          label={upsellContext.actionButtonLabel}
          onClick={onClick}
          variant={isCurrentPlan ? 'tertiary' : 'primary'}
        />
      )}
    </Grid>
  );
};
