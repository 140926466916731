import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import { useAccountingPlatformEntities } from '@melio/ap-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount, useAccountsWithLogoUrl, useFeeCatalog } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { Logger } from '@melio/platform-logger';
import { SubscriptionProvider, useSubscriptionData } from '@melio/subscriptions';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { usePartnerConfig } from '@/hooks/partners';
import { useFundingSourcesObserver } from '@/hooks/paymentMethods.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useUserActivityInterval, useUserInactivityInterval } from '@/hooks/session.hooks';
import { useVendorsObserver } from '@/hooks/vendors.hooks';
import { getAccessTokenData } from '@/utils/getAccessTokenData.utils';

export const WithAnonymousInitialData = () => {
  const { identify } = useAnalytics();
  const { partnerConfig } = usePartnerConfig();
  useUserInactivityInterval();
  useEffect(() => {
    identify({
      siteConfig: partnerConfig.partnerNameAnalyticsEvents,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerConfig]);

  return <Outlet />;
};

const AbsoluteCenterLoader = () => <Loader isAbsoluteCenter />;

export const WithInitialData = () => {
  const { generateErrorLink } = useRouter();
  const { isLoading: accountIsLoading, error: accountError, data: account } = useAccount({ id: 'me' });
  const { isLoading: accountListIsLoading, isFetching: accountListIsFetching } = useAccountsWithLogoUrl();
  const { isLoading: feeIsLoading } = useFeeCatalog();
  const { disableSalesIntegrations, identify } = useAnalytics();
  const [optimizeAccountingSoftwareDataFetching] = useFeature(
    FeatureFlags.OptimizeAccountingSoftwareDataFetching,
    false,
    { shouldTrack: false },
  );
  useUserActivityInterval();
  useUserInactivityInterval();
  useVendorsObserver();
  useFundingSourcesObserver();
  useAccountingPlatformEntities(optimizeAccountingSoftwareDataFetching);

  const { partnerGroup } = usePartnerConfig();
  const { isFetching: isSubscriptionDataFetching, isError: isSubscriptionDataError } = useSubscriptionData();

  useEffect(() => {
    const accessTokenData = getAccessTokenData();
    if (account && accessTokenData) {
      const { organization, user, partnerName, partnerId } = accessTokenData;
      if (account.privacyPreferences?.optedOutOfSale) {
        disableSalesIntegrations();
      }
      identify({
        company: organization?.companyName,
        createOrigin: organization?.createOrigin,
        siteConfig: partnerName,
        userId: user.id,
        email: account.user.email || '',
        name: `${account.user.firstName} ${account.user.lastName}`,
        orgId: Number(organization?.id),
        registrationFlow: user.registrationFlow,
        organization: organization?.companyName,
        PartnerId: partnerId,
      });

      const datadogUser = {
        id: user.id,
        email: account.user.email || '',
        orgId: Number(organization?.id),
      };
      datadogRum.setUser(datadogUser);
      Logger.setUser(datadogUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const isLoading = accountIsLoading || accountListIsLoading || feeIsLoading || isSubscriptionDataFetching;

  if (isLoading) {
    return <AbsoluteCenterLoader />;
  }

  const ErrorComponent = () => <Navigate to={generateErrorLink(accountError)} />;

  if (accountError || isSubscriptionDataError) {
    return <ErrorComponent />;
  }

  const isFetching = accountListIsFetching;

  return (
    <SubscriptionProvider
      LoaderComponent={AbsoluteCenterLoader}
      ErrorComponent={ErrorComponent}
      partnerGroup={partnerGroup}
    >
      <WithLoading isLoading={isFetching} overContent isAbsoluteCenter>
        <Box hidden={isFetching} height="100%" width="100%">
          <Outlet />
        </Box>
      </WithLoading>
    </SubscriptionProvider>
  );
};

export const WithVendorActionsInitialData = () => {
  const { identify } = useAnalytics();
  useUserInactivityInterval();

  useEffect(() => {
    const accessTokenData = getAccessTokenData();
    if (accessTokenData) {
      const { organization, user, partnerName, claims } = accessTokenData;
      identify({
        company: organization?.companyName,
        createOrigin: organization?.createOrigin,
        siteConfig: partnerName,
        userId: claims?.vendorId,
        orgId: Number(organization?.id),
        registrationFlow: user.registrationFlow,
        organization: organization?.companyName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
};
