export enum SubscriptionTabsEnum {
  Plans = 'plans',
  Checkout = 'checkout',
  Success = 'success',
}

export enum SubscriptionCancelOrDowngradeOriginEnum {
  Settings = 'settings',
}

export const DEFAULT_DASHBOARD_ROUTE = '/pay-dashboard';
export const SUBSCRIPTION_SETTINGS_PLANS_ROUTE = '/settings/subscription-plans';
export const SUBSCRIPTION_SUCCESS_PAGE_ROUTE = '/subscription/success';
export const SUBSCRIPTION_PAYMENT_METHOD_SELECT_ROUTE = '/subscription/payment-method/select';
export const SETTINGS_COLLABORATORS = '/settings/collaborators';
