import { Container, Group, Link, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FormattedMessage, useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

export const PoliciesAndHelpWidget = forwardRef<unknown, 'div'>((props, ref) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { privacyPolicyUrl, termsOfServiceUrl, helpCenterUrl } = useConfig().settings;

  return (
    <Group
      data-component="PoliciesAndHelpWidget"
      variant="vertical"
      alignItems="center"
      spacing="s"
      ref={ref}
      {...props}
    >
      <Text textStyle="body4" color="neutral.darker">
        <FormattedMessage
          id="widgets.poweredByMelioFooter.privacyAndTermsLine"
          values={{
            privacyPolicy: (
              <Link
                variant="inline"
                href={privacyPolicyUrl}
                label={formatMessage('widgets.poweredByMelioFooter.privacyPolicyText')}
                onClick={() => track('PoliciesAndHelp', 'Click', { Cta: 'privacy-policy' })}
                newTab
              />
            ),
            termsOfService: (
              <Link
                variant="inline"
                href={termsOfServiceUrl}
                label={formatMessage('widgets.poweredByMelioFooter.termsOfServiceText')}
                onClick={() => track('PoliciesAndHelp', 'Click', { Cta: 'terms-of-service' })}
                newTab
              />
            ),
          }}
        />
      </Text>
      <Container textAlign="center">
        <Text textStyle="body4" color="neutral.darker">
          <FormattedMessage
            id="widgets.poweredByMelioFooter.lastLine"
            values={{
              helpCenter: (
                <Link
                  variant="inline"
                  href={helpCenterUrl}
                  label={formatMessage('widgets.poweredByMelioFooter.helpCenterText')}
                  onClick={() => track('PoliciesAndHelp', 'Click', { Cta: 'contact-us' })}
                  newTab
                />
              ),
            }}
          />
        </Text>
      </Container>
    </Group>
  );
});

PoliciesAndHelpWidget.displayName = 'PoliciesAndHelpWidget';
