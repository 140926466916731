import { Group, Text } from '@melio/penny';

export const DetailsRow = ({ label, value }: { label: string; value: string }) => (
  <Group variant="horizontal" spacing="s" justifyContent="space-between">
    <Text textStyle="body3" color="neutral.darker">
      {label}
    </Text>
    <Text textStyle="body3" color="neutral.black">
      {value}
    </Text>
  </Group>
);
