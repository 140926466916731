import { Divider, Grid, Group } from '@melio/penny';

import { type Plan, type SubscriptionBillingCycleEnum, useSubscriptionMe } from '../../../../api';
import {
  usePartnerGroups,
  usePendingSubscription,
  usePlansTiers,
  useSubscription,
  useSubscriptionPlan,
} from '../../../../hooks';
import { PlanCardButton } from './PlanCardButton';
import { PlanCardFeaturesList } from './PlanCardFeaturesList';
import { PlanCardFeeFooter } from './PlanCardFeeFooter';
import { PlanCardHeader } from './PlanCardHeader';
import { PlanCardPrice } from './PlanCardPrice';

type PlanCardProps = {
  plan: Plan;
  isSelected: boolean;
  selectedBillingCycle: SubscriptionBillingCycleEnum;
  isSwitchingBillingCycle: boolean;
  onSelectPlan: (planId: Plan['id']) => void;
  isEligibleForFreeTrial: boolean;
};

export const PlanCard = ({
  plan,
  selectedBillingCycle,
  onSelectPlan,
  isSelected,
  isSwitchingBillingCycle,
  isEligibleForFreeTrial,
}: PlanCardProps) => {
  const subscription = useSubscription();
  const { getIsCurrentPlan } = useSubscriptionPlan();
  const { getIsMostPopularPlan, getIsFreePlan } = usePlansTiers();
  const { isUpgradedPlan } = useSubscriptionPlan();
  const { isFiservPartner } = usePartnerGroups();
  const { isReactivationAllowed, nextSubscriptionPlanId: pendingPlanId } = usePendingSubscription();
  const { isMutating: isUpdatingSubscription } = useSubscriptionMe({
    enabled: false,
  });

  const billingCycle = plan.cycles[selectedBillingCycle];
  if (!billingCycle) {
    return null;
  }

  const isCurrentPlan = getIsCurrentPlan(plan.id);
  const isMostPopularPlan = getIsMostPopularPlan(plan.id);
  const shouldRenderMostPopularVariation = isMostPopularPlan && !isCurrentPlan;
  const shouldRenderCurrentPlanVariation = isCurrentPlan && !isMostPopularPlan;

  let variant: 'primary' | 'tertiary' = 'tertiary';

  if (
    (!subscription && !getIsFreePlan(plan.id)) ||
    (isCurrentPlan && (isSwitchingBillingCycle || isReactivationAllowed)) ||
    isUpgradedPlan(plan.id)
  ) {
    variant = 'primary';
  }

  const getIsDisabled = () => {
    if (isUpdatingSubscription && !isSelected) {
      return true;
    }

    if (isReactivationAllowed) {
      return pendingPlanId === plan.id;
    }

    if (isSwitchingBillingCycle) {
      return !isCurrentPlan || getIsFreePlan(plan.id);
    } else {
      return isCurrentPlan;
    }
  };

  return (
    <Grid
      borderRadius="l"
      backgroundColor={shouldRenderMostPopularVariation ? 'brand.lightest' : 'initial'}
      outline={shouldRenderCurrentPlanVariation ? '2px solid' : '1px solid'}
      outlineColor={
        shouldRenderCurrentPlanVariation
          ? 'brand.main'
          : shouldRenderMostPopularVariation
          ? 'brand.lighter'
          : 'neutral.light'
      }
      gridTemplateRows="subgrid"
      gridRow="auto / span 5"
      p="m"
      gap="l"
      data-testid={`subscription-plan-card-${plan.id}`}
    >
      <PlanCardHeader planId={plan.id} />

      <PlanCardPrice planId={plan.id} billingCycle={billingCycle} />

      <Group variant="vertical" spacing="s">
        <PlanCardButton
          planId={plan.id}
          variant={variant}
          onClick={() => onSelectPlan(plan.id)}
          isSaving={isSelected && isUpdatingSubscription}
          isDisabled={getIsDisabled()}
          isSwitchingBillingCycle={isSwitchingBillingCycle}
          isEligibleForFreeTrial={isEligibleForFreeTrial}
        />
        <Divider />
      </Group>

      <PlanCardFeaturesList planId={plan.id} />
      {isFiservPartner && <PlanCardFeeFooter plan={plan} selectedBillingCycle={selectedBillingCycle} />}
    </Grid>
  );
};
