import { useConfig } from '@melio/platform-provider';

import { usePlansMe, useSubscriptionMe } from '../api';
import { useIsSubscriptionsEnabled } from '../hooks/useIsSubscriptionsEnabled';

export type UseSubscriptionDataProps = {
  retryOnMount?: boolean;
};

export const useSubscriptionData = ({ retryOnMount }: UseSubscriptionDataProps = {}) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const {
    settings: { subscriptions: subscriptionsConfig },
  } = useConfig();

  const {
    data: subscription,
    isFetching: isFetchingMySubscription,
    error: subscriptionError,
  } = useSubscriptionMe({
    enabled: isSubscriptionsEnabled,
    retryOnMount,
  });

  const {
    data: plans,
    isFetching: isFetchingPlans,
    error: plansError,
  } = usePlansMe({
    enabled: isSubscriptionsEnabled,
  });

  const isFetching = isFetchingMySubscription || isFetchingPlans;

  const isError =
    !!plansError ||
    !!(subscriptionError && (subscriptionError.code !== '404' || subscriptionsConfig?.expectOrgsToBeSubscribed));

  return { isFetching, isError, subscription, plans };
};
