import { useModelApi, UseModelApiProps, useUpdateMutation } from '@melio/api-client';
import { extractData, VendorsApiClient } from '@melio/platform-api-axios-client';
import { useCallback } from 'react';

import { CreateFn, DeleteFn, GetFn, UpdateFn, Vendor, VendorExpandParam } from './types';

export type UseVendorProps = UseModelApiProps<GetFn, Vendor> & {
  params?: { expand?: VendorExpandParam };
  onUpdate?: (data: Vendor) => void;
};

export const useVendor = ({ params, ...props }: UseVendorProps = {}) => {
  const query = useModelApi<GetFn, UpdateFn, DeleteFn, CreateFn, Vendor>({
    ...props,
    queryKey: ['VendorsApi', params],
    queryFn: (id: string) => VendorsApiClient.getVendorsVendorId(id, params?.expand),
    deleteFn: VendorsApiClient.deleteVendorsVendorId,
    updateFn: VendorsApiClient.patchVendorsVendorId,
    createFn: VendorsApiClient.postVendors,
  });

  const linkVendorToDirectoryMutation = useUpdateMutation(VendorsApiClient.postVendorsLinkToDirectory, query.queryKey);

  return {
    ...query,
    linkVendorToDirectory: linkVendorToDirectoryMutation.createCallback(props.id as string),
    getVendorUnmaskedAccountNumber: useCallback(
      () => VendorsApiClient.getVendorUnmaskedAccountNumber(props.id || '').then(extractData),
      [props.id]
    ),
  };
};

export type VendorModel = ReturnType<typeof useVendor>;
