import { useLocation } from '@melio/platform-utils';
import { useSearchParams } from 'react-router-dom';

import { SubscriptionBillingCycleEnum } from '../api';
import { usePlansTiers } from './usePlansTiers';

export const useWizardParams = () => {
  const { firstPaidPlan } = usePlansTiers();
  const { state } = useLocation<{
    returnUrl?: string;
    utm_promo?: string;
    billingCycle?: SubscriptionBillingCycleEnum;
  }>();
  const [searchParams] = useSearchParams();

  const { returnUrl, utm_promo: promoCodeState, billingCycle } = state || {};
  const planId = searchParams.get('plan') || firstPaidPlan?.id;
  const promoCodeQueryString = searchParams.get('utm_promo') || promoCodeState;

  return { returnUrl, planId, promoCodeQueryString, billingCycle };
};
