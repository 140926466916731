// TODO: the text should be per plan and not just per feature. waiting for product answer https://www.figma.com/design/iFQTSLomTaSVgSdDxpiOej?node-id=951-86552#800202620
// for example: the "1teamMember" is not correct for all current plan. it may be the the current plan is the paid plan with 3 seats and it doesn't support the feature.
export const currentPlanContentPerFeatureFiserv = {
  collaborators: {
    capabilities: [
      `activities.subscription.contextualPlans.collaborators.current.capabilities.1teamMember`,
      `activities.subscription.contextualPlans.collaborators.current.capabilities.ownerRole`,
    ],
  },
  batchPayments: {
    capabilities: [
      `activities.subscription.contextualPlans.batchPayments.current.capabilities.paySingleBills`,
      `activities.subscription.contextualPlans.batchPayments.current.capabilities.noCombinedPayments`,
    ],
    actionButton: `activities.subscription.contextualPlans.batchPayments.current.actionButtonLabel`,
  },
  accountingPlatformQbo: {
    capabilities: [
      `activities.subscription.contextualPlans.accountingPlatformQbo.current.capabilities.1teamMember`,
      `activities.subscription.contextualPlans.accountingPlatformQbo.current.capabilities.basicCapabilities`,
    ],
  },
  accountingPlatformXero: {
    capabilities: [
      `activities.subscription.contextualPlans.accountingPlatformXero.current.capabilities.1teamMember`,
      `activities.subscription.contextualPlans.accountingPlatformXero.current.capabilities.basicCapabilities`,
    ],
  },
  ar: {
    capabilities: [],
  },
  requestCallbackSupport: {
    capabilities: [],
  },
} as const;

export const upgradedPlanContentPerFeatureFiserv = {
  collaborators: {
    capabilities: [
      'activities.subscription.contextualPlans.collaborators.upgrade.capabilities.unlimitedCollaborators',
      'activities.subscription.contextualPlans.collaborators.upgrade.capabilities.mutliplerRoles',
    ],
  },
  batchPayments: {
    capabilities: [
      `activities.subscription.contextualPlans.batchPayments.upgrade.capabilities.mutlipleBills`,
      `activities.subscription.contextualPlans.batchPayments.upgrade.capabilities.combinedBillPayments`,
    ],
  },
  accountingPlatformQbo: {
    capabilities: [
      `activities.subscription.contextualPlans.accountingPlatformQbo.upgrade.capabilities.advanceAccountingCapabilities`,
      `activities.subscription.contextualPlans.accountingPlatformQbo.upgrade.capabilities.directAccountingIntegrations`,
      `activities.subscription.contextualPlans.accountingPlatformQbo.upgrade.capabilities.multipleTeamMembers`,
      `activities.subscription.contextualPlans.accountingPlatformQbo.upgrade.capabilities.quickbooksAndXero`,
    ],
  },
  accountingPlatformXero: {
    capabilities: [
      `activities.subscription.contextualPlans.accountingPlatformXero.upgrade.capabilities.advanceAccountingCapabilities`,
      `activities.subscription.contextualPlans.accountingPlatformXero.upgrade.capabilities.directAccountingIntegrations`,
      `activities.subscription.contextualPlans.accountingPlatformXero.upgrade.capabilities.multipleTeamMembers`,
      `activities.subscription.contextualPlans.accountingPlatformXero.upgrade.capabilities.quickbooksAndXero`,
    ],
  },
  ar: {
    capabilities: [],
  },
  requestCallbackSupport: {
    capabilities: [],
  },
} as const;
