import { Container, Grid, Group, IconButton, Text, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePlansTiers, useSubscriptionPlan } from '@melio/subscriptions';

type PlansSelectionHeaderProps = {
  isEligibleForFreeTrial: boolean;
  onBack?: () => void;
  gridTemplateCol?: string;
};

export const PlansSelectionHeader = ({
  isEligibleForFreeTrial,
  gridTemplateCol,
  onBack,
}: PlansSelectionHeaderProps) => {
  const { formatMessage } = useMelioIntl();
  const { getIsFreePlan } = usePlansTiers();
  const { currentPlan } = useSubscriptionPlan();
  const { isExtraSmallScreen } = useBreakpoint();

  const title = formatMessage(
    isEligibleForFreeTrial && (!currentPlan || getIsFreePlan(currentPlan.id))
      ? 'activities.subscription.plans.titleWhenEligibleForFreeTrial'
      : 'activities.subscription.plans.title'
  );

  return (
    <Container justifyContent="center" alignItems="center" aria-live="assertive">
      {isExtraSmallScreen && (
        <Group
          variant="horizontal"
          alignItems="flex-start"
          width="full"
          justifyContent="space-between"
          textAlign="start"
        >
          <Text as="h1" textStyle="heading1Semi">
            {title}
          </Text>
          {onBack && (
            <IconButton
              variant="primary"
              aria-label={formatMessage('app.settings.backButton.ariaLabel')}
              data-testid="back-button"
              icon="close"
              onClick={onBack}
            />
          )}
        </Group>
      )}

      {!isExtraSmallScreen && (
        <Grid justifyContent="start" gap="m" pb="xxxs" gridTemplateColumns={gridTemplateCol}>
          <Group alignItems="flex-start" variant="vertical" spacing="xxl">
            <Text as="h1" textStyle="heading1Semi">
              {title}
            </Text>
          </Group>
          {onBack && (
            <Container justifyContent="flex-end" alignItems="flex-start" paddingY="xxs" paddingX="xxs">
              <IconButton
                variant="primary"
                size="large"
                aria-label={formatMessage('app.settings.backButton.ariaLabel')}
                data-testid="back-button"
                icon="close"
                onClick={onBack}
              />
            </Container>
          )}
        </Grid>
      )}
    </Container>
  );
};
