import { isNil } from 'lodash';

import { Feature, Plan } from '../api';

type FeatureEligibilityData = {
  isEligible: boolean;
};

export type QuantityBasedFeature = Extract<Feature, 'collaborators'>;
type QuantityBasedFeatureRequest = { featureName: QuantityBasedFeature; requirements: { totalUnits: number } };
type QuantityBasedFeatureEligibilityData = FeatureEligibilityData & {
  quota: {
    freeUnitsLimit: number;
    excessFee: {
      monthly: number | undefined;
      annual: number | undefined;
    };
  };
};

export type BooleanBasedFeature = Exclude<Feature, 'collaborators'>;
type BooleanBasedFeatureRequest = { featureName: BooleanBasedFeature };
type BooleanBasedFeatureEligibilityData = FeatureEligibilityData;

export type FeatureRequest = BooleanBasedFeatureRequest | QuantityBasedFeatureRequest;

export function getIsFeatureInPlan<T extends Feature>(
  plan: Plan,
  feature: T extends QuantityBasedFeature
    ? { featureName: T; requirements: { totalUnits: number } }
    : { featureName: T }
): T extends QuantityBasedFeature ? QuantityBasedFeatureEligibilityData : BooleanBasedFeatureEligibilityData;
export function getIsFeatureInPlan(
  plan: Plan,
  feature: FeatureRequest
): QuantityBasedFeatureEligibilityData | BooleanBasedFeatureEligibilityData {
  if (feature.featureName === 'collaborators') {
    return getQuantityBasedIsFeatureInPlan(plan, feature.featureName, feature.requirements.totalUnits);
  } else {
    return getBooleanBasedIsFeatureInPlan(plan, feature.featureName);
  }
}

export const getQuantityBasedIsFeatureInPlan = (
  plan: Plan,
  featureName: QuantityBasedFeature,
  totalRequestedUnits: number
) => {
  switch (featureName) {
    case 'collaborators': {
      const freeUnitsLimit = plan.numberOfFreeUsers;

      const excessFee = {
        monthly: plan.cycles.monthly.unitPrice,
        annual: plan.cycles.annual?.unitPrice,
      };

      const isBelowFreeUnitsLimit = freeUnitsLimit && totalRequestedUnits <= freeUnitsLimit;
      const canExceedFreeUnitsLimit = !isNil(excessFee?.monthly);

      const isEligible = isBelowFreeUnitsLimit || canExceedFreeUnitsLimit;

      return { isEligible, quota: { freeUnitsLimit, excessFee } };
    }
  }
};

const getBooleanBasedIsFeatureInPlan = (plan: Plan, featureName: BooleanBasedFeature) => ({
  isEligible: !!plan.features[featureName]?.enabled,
});
