import { Logger } from '@melio/platform-logger';

import { usePlanInfo, useSubscriptionPlan } from '../../../../hooks';
import { FeatureRequest } from '../../../../utils/planFeatures.utils';
import { useContextualMessages } from './useContextualMessages';
import { useFirstPlanWithFeature } from './useFirstPlanWithFeature';

export type UseContextualPlansProps = FeatureRequest;

export const useContextualPlans = (feature: FeatureRequest) => {
  const { currentPlan } = useSubscriptionPlan();
  const { getFirstPlanWithFeature } = useFirstPlanWithFeature(); // TODO:  should be fixed - need to validate that the "upgradePlan" contains all current plan features

  const upgradePlan = getFirstPlanWithFeature(feature);
  const { planName: upgradePlanName } = usePlanInfo(upgradePlan.id);

  const contextualContent = useContextualMessages({ featureName: feature.featureName, upgradePlanName });

  if (!currentPlan) {
    const error = 'currentPlan not found';
    Logger.log(error, 'error');
    throw new Error(error);
  }

  return {
    title: contextualContent.title,
    description: contextualContent.description,
    currentContextualPlan: contextualContent.plans.current,
    upgradeContextualPlan: contextualContent.plans.upgrade,
    currentPlan,
    upgradePlan,
  };
};
