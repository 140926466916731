import { ThemeOptions } from '@melio/penny';

import {
  AddCard,
  Bank,
  BankMissing,
  BankSuccess,
  Blocked,
  Celebration,
  CustomerAdd,
  Declined,
  Expired,
  Fast,
  MoneySuccess,
  NetworkDownload,
  NetworkError,
  NewEmail,
  NoItems,
  PageNotFound,
  PaymentRequestLink,
  PayZero,
  Pending,
  Processing,
  Sent,
  SmallBusiness,
  Success,
  SyncAccounts,
  SyncUser,
  TaxForm,
  Together,
  VendorAdd,
} from './illustrations';
import { MelioLogo, MelioLogoDark } from './logos';

export const melioTheme: ThemeOptions = {
  logos: {
    dark: MelioLogoDark,
    light: MelioLogo,
  },
  illustrations: {
    'add-card': AddCard,
    bank: Bank,
    'bank-success': BankSuccess,
    'bank-missing': BankMissing,
    blocked: Blocked,
    celebration: Celebration,
    'customer-add': CustomerAdd,
    declined: Declined,
    expired: Expired,
    'money-success': MoneySuccess,
    'network-download': NetworkDownload,
    'network-error': NetworkError,
    'new-email': NewEmail,
    'no-items': NoItems,
    'payment-request-link': PaymentRequestLink,
    'pay-zero': PayZero,
    'page-not-found': PageNotFound,
    pending: Pending,
    processing: Processing,
    sent: Sent,
    'small-business': SmallBusiness,
    success: Success,
    'sync-accounts': SyncAccounts,
    'sync-user': SyncUser,
    'vendor-add': VendorAdd,
    'tax-form': TaxForm,
    together: Together,
    fast: Fast,
  },
};
