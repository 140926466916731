import { Box } from '@chakra-ui/react';
import { PartnerGroupEnum } from '@melio/partner-tools';
import { Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import type { Plan, PlanCycle } from '../../../../api';
import { usePartnerGroup, usePlansTiers } from '../../../../hooks';

type PlanCardPriceProps = {
  planId: Plan['id'];
  billingCycle: PlanCycle;
};

export const PlanCardPrice = ({ planId, billingCycle }: PlanCardPriceProps) => {
  const { formatCurrency, formatMessage } = useMelioIntl();
  const { getIsFreePlan } = usePlansTiers();
  const partnerGroup = usePartnerGroup();

  const price = billingCycle.basePrice;

  const isFreePlan = getIsFreePlan(planId);

  return (
    <Container>
      <Group alignItems="center" spacing="xs">
        <Text textStyle="display2Semi">{formatCurrency(price, undefined, { notation: 'compact' })}</Text>
        <Box as="span" textStyle="body4Semi" hidden={isFreePlan}>
          {formatMessage(
            PartnerGroupEnum.FISERV === partnerGroup
              ? 'activities.subscription.plans.planCard.price.perMonth'
              : 'activities.subscription.plans.planCard.price.seatPerMonth'
          )}
        </Box>
      </Group>
      {partnerGroup !== PartnerGroupEnum.FISERV ? (
        <Container paddingTop="s">
          <Text textStyle="body4" color="neutral.darker">
            {formatMessage(
              `activities.subscription.plans.planCard.price.description.${isFreePlan ? 'free' : 'monthly'}`
            )}
          </Text>
        </Container>
      ) : null}
    </Container>
  );
};
