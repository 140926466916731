import { Button } from '@melio/penny';

import { usePlanCardButtonLabel } from './usePlanCardButtonLabel';

type PlanCardButtonProps = {
  isSaving?: boolean;
  isDisabled?: boolean;
  isSwitchingBillingCycle: boolean;
  planId: string;
  variant: 'primary' | 'tertiary';
  onClick: () => void;
  isEligibleForFreeTrial: boolean;
};

export const PlanCardButton = ({
  isSaving,
  isDisabled,
  isSwitchingBillingCycle,
  planId,
  onClick,
  variant,
  isEligibleForFreeTrial,
}: PlanCardButtonProps) => {
  const label = usePlanCardButtonLabel({ planId, isSwitchingBillingCycle, isEligibleForFreeTrial });

  return (
    <Button
      data-testid={`subscription-plan-card-button-${planId}`}
      label={label}
      isDisabled={isDisabled}
      isLoading={isSaving}
      variant={variant}
      onClick={onClick}
    />
  );
};
