import React, { cloneElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Card, Group, Icon, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useScrollIntoView } from '@/hooks/useScrollIntoView.hooks';
import { SettingsCardTheme } from './SettingsCard.component.theme';

export enum SettingsCardIdEnum {
  ProfileSettings = 'profile-settings',
  CompanySettings = 'company-settings',
  CollaboratorsSetting = 'collaborators-setting',
  WorkflowsSetting = 'workflows-setting',
  NotificationPreferences = 'notification-preferences',
  AccountingSoftwareSync = 'accounting-software-sync',
  PaymentMethodsSetting = 'payment-methods-setting',
  ReceivingMethodsSettings = 'receiving-methods-settings',
  BillingSetting = 'billing-setting',
  SupportSetting = 'support-setting',
  SubscriptionPlansSettings = 'subscription-plans-setting',
  InvoiceItems = 'invoice-items',
  InvoiceEmailNotifications = 'invoice-email-notifications',
}

type SettingsCardIdType = SettingsCardIdEnum;

export type SettingsCardProps = {
  id: SettingsCardIdType;
  icon: React.ReactElement;
  content: string;
  onClick: VoidFunction;
  action?: React.ReactNode;
  isSelected: boolean;
  cardName: string;
};

export const SettingsCard = React.memo((props: SettingsCardProps) => {
  const { track } = useAnalytics();
  const { icon, content, action = null, onClick, isSelected = false, cardName, id, ...rest } = props;
  const styles = useAppTheme(SettingsCardTheme, props);
  const cardRef = React.useRef<HTMLDivElement>(null);
  const { isExtraSmallScreen } = useBreakpoint();
  useScrollIntoView({ ref: cardRef, isSelected });
  const analyticsFields = { PageName: id, Flow: 'settings', EntryPoint: 'settings-page', Cta: id };

  const onClickCard = () => {
    track('Settings', 'Click', analyticsFields);
    onClick();
  };

  return (
    <Card
      data-component="SettingsCard"
      data-testid={`settings-card-${cardName}`}
      {...rest}
      ref={cardRef}
      onClick={onClickCard}
      isSelected={isSelected}
      variant="flat"
      paddingX="none"
      paddingY="none"
      width={{ xs: '100%', s: '378px' } as never}
      role="link"
      aria-current="page"
    >
      <Flex sx={styles['container']}>
        <Group alignItems="center">
          <Box sx={styles['icon']}>{cloneElement(icon, { size: 'medium' })}</Box>
          <Flex as="section" sx={styles['textContainer']}>
            <Box as="p" sx={styles['content']}>
              {content}
            </Box>
            {action}
          </Flex>
        </Group>
        {isExtraSmallScreen && <Icon type="chevron-right" size="small" />}
      </Flex>
    </Card>
  );
});

SettingsCard.displayName = 'SettingsCard';
