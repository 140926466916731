import { Group, NakedButton, SectionBanner, Text, VerificationCodeField } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

export type VerificationCodeWidgetProps = {
  onCodeComplete: (code: string, email: string) => Promise<boolean>;
  emailAddress: string;
  onResendCode: (emailAddress: string, alreadySent: boolean) => void;
  onCancel: () => void;
};

export const CodeVerificationFormCardWidget = forwardRef<VerificationCodeWidgetProps, 'div'>(
  ({ onCodeComplete, onCancel, onResendCode, emailAddress, ...props }, ref) => {
    const [resentCode, setResentCode] = useState<boolean>(false);
    const [reset, setReset] = useState(true);
    const { formatMessage } = useMelioIntl();

    const resendCode = () => {
      onResendCode(emailAddress, resentCode);
      setReset(false);
      setResentCode(true);
      setTimeout(() => setReset(true));
    };

    const onCompleteHandler = async (code: string) => {
      try {
        const results = await onCodeComplete(code, emailAddress);
        return Boolean(results);
      } catch (e) {
        return false;
      }
    };

    return (
      <Group
        data-component="CodeVerificationFormCardWidget"
        spacing="l"
        variant="vertical"
        width="full"
        ref={ref}
        {...props}
      >
        {resentCode && (
          <SectionBanner
            data-testid="CodeVerificationFormCardWidget.resent-code-success"
            description={formatMessage('vex.widget.vendorOnboarding.codeVerification.resendCode.success')}
            variant="success"
          />
        )}

        <Group variant="vertical" alignItems="center">
          {reset && (
            <VerificationCodeField
              onComplete={onCompleteHandler}
              data-testid="CodeVerificationFormCardWidget.input-verification-code"
            />
          )}
        </Group>
        <Group variant="vertical" spacing="xs">
          <Text textStyle="body2" color="neutral.darkest">
            {formatMessage('vex.widget.vendorOnboarding.codeVerification.helpText')}
          </Text>
          <Group spacing="xxs">
            <NakedButton
              size="medium"
              variant="secondary"
              data-testid="CodeVerificationFormCardWidget.resend-code-button"
              label={formatMessage('vex.widget.vendorOnboarding.codeVerification.resendCode')}
              onClick={resendCode}
            ></NakedButton>
            <Text textStyle="body2" color="neutral.darkest">
              {formatMessage('vex.widget.vendorOnboarding.codeVerification.or')}
            </Text>
            <NakedButton
              label={formatMessage('vex.widget.vendorOnboarding.codeVerification.editEmail')}
              size="medium"
              variant="secondary"
              data-testid="CodeVerificationFormCardWidget.edit-email-button"
              onClick={onCancel}
            ></NakedButton>
          </Group>
        </Group>
      </Group>
    );
  }
);

CodeVerificationFormCardWidget.displayName = 'CodeVerificationFormCardWidget';
