import { Container, Group, Icon, Text } from '@melio/penny';

import { usePlanInfo, usePlansTiers } from '../../../../hooks';

export const PlanCardFeaturesList = ({ planId }: { planId: string }) => {
  const { capabilities } = usePlanInfo(planId);
  const { getIsMostPopularPlan } = usePlansTiers();

  const isMostPopularPlan = getIsMostPopularPlan(planId);

  return (
    <Group spacing="xs" variant="vertical">
      <Container paddingBottom="xxs">
        <Text as="h3" textStyle="body3Semi">
          {capabilities.title}
        </Text>
      </Container>

      <Group as="ul" variant="vertical" spacing="xs">
        {capabilities.list.map((item) => (
          <Group alignItems="center" spacing="xs" key={item} as="li">
            <Icon type="checked" size="small" color={isMostPopularPlan ? 'default' : 'brand'} />
            <Text textStyle="body3" color="neutral.darkest">
              {item}
            </Text>
          </Group>
        ))}
      </Group>
    </Group>
  );
};
