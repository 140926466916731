import { BaseModal, Container, Grid, Group, Text } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { usePermissions } from '@melio/platform-permissions';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Plan } from '../../../api';
import { SubscriptionContext, SubscriptionContextualPaywallProps } from '../../../context';
import { usePartnerGroups, useSubscription } from '../../../hooks';
import { SubscriptionUpgradeModalActivity } from '../SubscriptionUpgradeModal';
import { ContextualPlanCard } from './components';
import { AccountUpgradeRequestModalScreen } from './components/AccountUpgradeRequest.modal-screen';
import { useContextualPlans } from './hooks/useContextualPlans';
import { useFirstPlanWithFeature } from './hooks/useFirstPlanWithFeature';

type ContextualPaywallModalProps = SubscriptionContextualPaywallProps & {
  handleUpgrade: (newPlanId: Plan['id']) => void;
  handleDismiss: VoidFunction;
};

const ContextualPaywallModalScreen = withAnalyticsContext<ContextualPaywallModalProps>(
  ({ handleUpgrade, handleDismiss, setAnalyticsProperties, ...feature }) => {
    setAnalyticsProperties({
      PageName: 'plan-selection',
    });

    const contextualPlans = useContextualPlans(feature);
    const subscription = useSubscription();
    const billingCycle = subscription ? subscription.planCyclePeriod : 'monthly';

    return (
      <BaseModal isOpen onClose={handleDismiss} size="medium" data-testid="contextual-paywall-modal-screen">
        <Container paddingX="m" paddingY="m" overflow="auto">
          <Group variant="vertical">
            <Text textStyle="heading2Semi" as="h1">
              {contextualPlans.title}
            </Text>
            <Text textStyle="body3" color="neutral.darkest" as="h2">
              {contextualPlans.description}
            </Text>
            <Grid gap="s" gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))">
              <ContextualPlanCard
                isCurrentPlan
                upsellContext={contextualPlans.currentContextualPlan}
                billingCycle={billingCycle}
                onClick={handleDismiss}
                plan={contextualPlans.currentPlan}
              />
              <ContextualPlanCard
                upsellContext={contextualPlans.upgradeContextualPlan}
                billingCycle={billingCycle}
                onClick={() => handleUpgrade(contextualPlans.upgradePlan.id)}
                plan={contextualPlans.upgradePlan}
              />
            </Grid>
          </Group>
        </Container>
      </BaseModal>
    );
  }
);

export const ContextualPaywallModalActivity = () => {
  const {
    contextualPaywall: { paywallProps, hidePaywall },
  } = useContext(SubscriptionContext);
  const { isFiservPartner } = usePartnerGroups();

  const [upgradeModalPlanId, setUpgradeModalPlanId] = useState<Plan['id']>();

  const { can } = usePermissions();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const subscription = useSubscription();
  const { getFirstPlanWithFeature } = useFirstPlanWithFeature();

  if (!paywallProps) {
    return null;
  }

  const handleDismiss = () => {
    hidePaywall();
    paywallProps.onDismiss?.();
  };

  const handleUpgrade = (newPlanId: Plan['id']) => {
    if (isFiservPartner) {
      return setUpgradeModalPlanId(newPlanId);
    }

    hidePaywall();
    navigate({ pathname: '/subscription/checkout', search: `plan=${newPlanId}` }, { state: { returnUrl: pathname } });
  };

  const canUpdateSubscription = can({
    subject: 'subscriptions:me',
    action: 'update',
  });

  if (!canUpdateSubscription) {
    return <AccountUpgradeRequestModalScreen handleDismiss={handleDismiss} />;
  }

  if (upgradeModalPlanId) {
    return (
      <SubscriptionUpgradeModalActivity
        isOpen
        planUpgradeProps={{ billingCycle: 'monthly', returnUrl: pathname, planId: upgradeModalPlanId }}
        onDismiss={paywallProps.onDismiss}
        onClose={() => {
          setUpgradeModalPlanId(undefined);
          hidePaywall();
        }}
      />
    );
  }

  if (subscription?.isFreeTrial) {
    const firstPlanWithFeature = getFirstPlanWithFeature(paywallProps);

    return (
      <SubscriptionUpgradeModalActivity
        isOpen
        planUpgradeProps={{ billingCycle: 'monthly', returnUrl: pathname, planId: firstPlanWithFeature.id }}
        onDismiss={paywallProps.onDismiss}
        onClose={hidePaywall}
      />
    );
  }

  return <ContextualPaywallModalScreen handleDismiss={handleDismiss} handleUpgrade={handleUpgrade} {...paywallProps} />;
};
