import { Card, Container, ExternalLayout, Group, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BankAccountDeliveryMethod, PaperCheckDeliveryMethod, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import {
  DeliveryDateListItemWidget,
  DeliveryMethodListItemWidget,
  FooterWidget,
  HeaderAuthSection,
  JoinMelioWidget,
  PayorPendingPaymentBanner,
  PoweredByMelioWidget,
} from '@melio/vex-widgets';

export type DeliveryMethodAddedSuccessfullyScreenProps = {
  accountName: string;
  paymentAmount: number;
  paymentInvoiceNumber?: string | null;
  deliveryMethod: BankAccountDeliveryMethod | PaperCheckDeliveryMethod;
  estimatedDelivery: Payment['estimatedDelivery'];
  maxEstimatedDelivery: Payment['maxEstimatedDelivery'];
  showJoinMelioButton?: boolean;
  isPaperCheck?: boolean;
  userName?: string;
  emailVerificationReturnUrl?: string;
  showLoginHeaderSection?: boolean;
  showPoweredByMelio?: boolean;
};

export const DeliveryMethodAddedSuccessfullyScreen = forwardRef<DeliveryMethodAddedSuccessfullyScreenProps, 'div'>(
  (
    {
      accountName,
      paymentAmount,
      paymentInvoiceNumber,
      deliveryMethod,
      estimatedDelivery,
      maxEstimatedDelivery,
      showJoinMelioButton,
      isPaperCheck,
      userName,
      emailVerificationReturnUrl,
      showLoginHeaderSection,
      showPoweredByMelio,
      children,
      ...props
    },
    ref
  ) => {
    const { formatMessage, formatCurrency } = useMelioIntl();

    const { track } = useAnalytics();

    track('UnilateralDeliveryMethodAddedSuccessfully', 'Viewed', {
      DeliveryMethodType: deliveryMethod.type,
    });

    const title = formatMessage(
      `activities.addDeliveryMethodByPayee.screens.deliveryMethodAddedSuccessfully.${
        isPaperCheck ? 'titleCheck' : 'titleBank'
      }`,
      {
        paymentAmount: formatCurrency(paymentAmount),
      }
    );

    const header = <HeaderAuthSection loggedInUserName={userName} />;

    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" hasDivider>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical" spacing="xl">
              <PayorPendingPaymentBanner
                variant="success"
                title={title}
                description={
                  paymentInvoiceNumber
                    ? formatMessage(
                        'activities.addDeliveryMethodByPayee.screens.deliveryMethodAddedSuccessfully.onItsWayText.withInvoiceNumber',
                        {
                          accountName,
                          paymentAmount: formatCurrency(paymentAmount),
                          paymentInvoiceNumber,
                        }
                      )
                    : formatMessage(
                        'activities.addDeliveryMethodByPayee.screens.deliveryMethodAddedSuccessfully.onItsWayText.withoutInvoiceNumber',
                        {
                          accountName,
                          paymentAmount: formatCurrency(paymentAmount),
                        }
                      )
                }
              />
              <Group variant="vertical" spacing="s">
                <Text textStyle="body4SemiUpper" color="neutral.darkest">
                  {formatMessage(
                    'activities.addDeliveryMethodByPayee.screens.deliveryMethodAddedSuccessfully.vendorReceivePaymentSection.title'
                  )}
                </Text>
                <Group variant="vertical" spacing="m" hasDivider>
                  <DeliveryMethodListItemWidget deliveryMethod={deliveryMethod} />
                  <DeliveryDateListItemWidget
                    deliveryMethodType={deliveryMethod.type}
                    estimatedDelivery={estimatedDelivery}
                    maxEstimatedDelivery={maxEstimatedDelivery}
                  />
                </Group>
              </Group>
            </Group>
          </Container>
          {showJoinMelioButton && (
            <Container paddingTop="s">
              <JoinMelioWidget />
            </Container>
          )}
        </Group>
      </Card>
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="AddDeliveryMethodByPayeeActivity.DeliveryMethodAddedSuccessfullyScreen"
        data-testid="add-delivery-method-by-payee-activity-delivery-method-added-successfully-screen"
        {...props}
        header={showLoginHeaderSection && !!userName ? header : undefined}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

DeliveryMethodAddedSuccessfullyScreen.displayName =
  'AddDeliveryMethodByPayeeActivity.DeliveryMethodAddedSuccessfullyScreen';
