import {
  CardChangeEvent,
  ChangeEvent,
  InputBlurEvent,
  InputFocusEvent,
} from '@basis-theory/basis-theory-js/types/elements/events';
import { ElementStyle } from '@basis-theory/basis-theory-js/types/elements/styles';
import {
  CardExpirationDateElement,
  CardNumberElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-react';
import type {
  CardExpirationDateElement as ICardExpirationDateElement,
  CardNumberElement as ICardNumberElement,
  CardVerificationCodeElement as ICardVerificationCodeElement,
} from '@basis-theory/basis-theory-react/types';
import { Box } from '@chakra-ui/react';
import {
  _createFormFieldInput as createFormFieldInput,
  _CustomInputContainer as CustomInputContainer,
  formHeight,
  FormTextFieldProps,
  formTextStyle,
  Theme,
  useBreakpointValue,
  useTheme,
} from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { useBoolean } from '@melio/platform-utils';
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef, useState } from 'react';

import { ElementTypeName } from './types';
import { useErrorMessages } from './useErrorMessages';

type ElementType = ICardNumberElement | ICardExpirationDateElement | ICardVerificationCodeElement;

type ElementProps = {
  elementType: ElementTypeName;
  id: string;
  onChange?: (event: CardChangeEvent | ChangeEvent) => void;
  onReady?: VoidFunction;
  style?: ElementStyle;
  placeholder?: string;
  disabled?: boolean;
  onFocus?: (event: InputFocusEvent) => void;
  onBlur?: (event: InputBlurEvent) => void;
};

export type TBTFormFieldProps = Omit<FormTextFieldProps, 'onChange'> & ElementProps;

const getElement = ({
  props,
  ref,
  colors,
  fonts,
  fontsUrl,
  textStyle,
  fontSize,
  lineHeight,
}: {
  props: ElementProps;
  ref: ForwardedRef<ElementType>;
  colors: Theme['colors'];
  fonts: Theme['fonts'];
  fontsUrl: string;
  textStyle?: Theme['textStyles'][string];
  fontSize: string;
  lineHeight: string;
}) => {
  const { elementType } = props;

  const style = {
    fonts: [fontsUrl],
    base: {
      padding: '0',
      fontFamily: fonts['primary'],
      fontSize,
      lineHeight,
      fontWeight: textStyle?.fontWeight,
      ':disabled': { backgroundColor: 'inherit' },
    },
    invalid: { color: colors.critical.main },
  } as ElementStyle;
  let el;
  switch (elementType) {
    case 'cardNumber':
      el = (
        <CardNumberElement {...props} iconPosition="none" style={style} ref={ref as ForwardedRef<ICardNumberElement>} />
      );
      break;
    case 'expirationDate':
      el = <CardExpirationDateElement {...props} style={style} ref={ref as ForwardedRef<ICardExpirationDateElement>} />;
      break;
    case 'verificationCode':
      el = (
        <CardVerificationCodeElement {...props} style={style} ref={ref as ForwardedRef<ICardVerificationCodeElement>} />
      );
      break;
  }
  return <Box height={textStyle?.lineHeight}>{el}</Box>;
};

const _TBTInput = forwardRef<ElementType, TBTFormFieldProps>(
  (
    {
      isInvalid,
      isDisabled,
      isReadOnly,
      isViewMode,
      elementType,
      onChange,
      id,
      onReady,
      style,
      placeholder,
      size = 'large',
      ...rest
    },
    ref
  ) => {
    const {
      services: {
        tbt: { fontsUrl },
      },
    } = useConfig();
    const { colors, textStyles, fonts } = useTheme();
    const [isFocused, focus] = useBoolean();

    const textStyle = textStyles[formTextStyle];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fontSize = useBreakpointValue(textStyle?.fontSize as never);

    const onFocusCallback = () => {
      focus.on();
    };

    const onBlurCallback = () => {
      focus.off();
    };

    return (
      <CustomInputContainer
        data-invalid={isInvalid || void 0}
        data-focus={isFocused || void 0}
        aria-disabled={isDisabled}
        data-readonly={isReadOnly || void 0}
        data-view-mode={isViewMode || void 0}
        size={size}
        {...rest}
      >
        {getElement({
          props: {
            elementType,
            id,
            onReady,
            style,
            placeholder: placeholder ?? '',
            onChange,
            disabled: !!isDisabled,
            onFocus: onFocusCallback,
            onBlur: onBlurCallback,
          },
          ref,
          colors,
          fonts,
          fontsUrl,
          textStyle,
          fontSize: fontSize as string,
          lineHeight: formHeight[size].height,
        })}
      </CustomInputContainer>
    );
  }
);

const _TBTFormField = createFormFieldInput(_TBTInput);

export type TBTFormFieldWidgetProps = ComponentPropsWithoutRef<typeof _TBTFormField>;

export const TBTFormFieldWidget = forwardRef<ElementType, TBTFormFieldWidgetProps>(
  ({ onChange, error, elementType, isViewMode, isReadOnly, ...props }, ref) => {
    const [elementError, setElementError] = useState<{ message?: string } | undefined>();
    const { errorMessage } = useErrorMessages();

    const change = (event: CardChangeEvent | ChangeEvent) => {
      setElementError(errorMessage(elementType, event));
      onChange?.(event);
    };

    return (
      <_TBTFormField
        {...props}
        isViewMode={isReadOnly || isViewMode}
        elementType={elementType}
        onChange={change}
        error={error || elementError}
        ref={ref}
      />
    );
  }
);
