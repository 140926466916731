import { Divider, Group, Modal, SectionBanner, Text, useMelioForm } from '@melio/penny';
import { useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';

import { SubscriptionBillingCycleEnum } from '../../../../api';
import { usePartnerGroups, usePlanInfo, usePlansTiers, useSubscription } from '../../../../hooks';
import { PaymentMethodsDropdown } from '../components/PaymentMethodsDropdown';
import { BillingAccountFormValues, PlanUpgradeProps } from '../types';
import { DetailsRow } from '../UpgradeModalDetailsRow';
import { useUpgradeSubscription } from '../useUpgradeSubscription';
import { getSelectedFundingSourceId } from '../utils';

type SubscriptionUpgradeWithoutTrialModalScreenProps = {
  isOpen: boolean;
  onDismiss?: () => void;
  onClose: () => void;
  planUpgradeProps: PlanUpgradeProps;
};

export const SubscriptionUpgradeWithoutTrialModalScreen = ({
  isOpen,
  onClose,
  onDismiss,
  planUpgradeProps,
}: SubscriptionUpgradeWithoutTrialModalScreenProps) => {
  const { formatMessage, formatCurrency, formatDate } = useMelioIntl();
  const subscription = useSubscription();

  const { isFiservPartner } = usePartnerGroups();
  const { data: fundingSources = [] } = useFundingSources();

  const { billingCycle, planId } = planUpgradeProps;

  const { upgradeError, isUpgradingSubscription, upgradeSubscription, dismiss } = useUpgradeSubscription({
    ...planUpgradeProps,
    onClose,
    onDismiss,
  });

  const selectedFundingSource = getSelectedFundingSourceId({
    subscriptionFundingSourceId: subscription?.fundingSourceId,
    fundingSources,
  });

  const { registerField, watch } = useMelioForm<BillingAccountFormValues>({
    defaultValues: {
      fundingSourceId: selectedFundingSource?.id,
    },
    onSubmit: () => null,
  });

  const fundingSourceField = watch('fundingSourceId');

  const { planName } = usePlanInfo(planId);
  const { getPlan } = usePlansTiers();

  const upgradePlan = planId ? getPlan(planId) : undefined;
  const currentPlan = subscription?.planId ? getPlan(subscription.planId) : undefined;

  const { createDate } = useDateUtils();
  const currencyFormatter = (value: number) =>
    formatCurrency(value, undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });
  const planUpgradePrice =
    billingCycle === SubscriptionBillingCycleEnum.Monthly
      ? upgradePlan?.cycles?.monthly?.basePrice || 0
      : upgradePlan?.cycles?.annual?.basePrice || 0;

  const currentPlanPrice =
    subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
      ? currentPlan?.cycles?.monthly?.basePrice || 0
      : currentPlan?.cycles?.annual?.basePrice || 0;

  const billingCycleLabel =
    billingCycle === SubscriptionBillingCycleEnum.Monthly
      ? formatMessage('activities.subscription.upgradeModal.cycle.monthly')
      : formatMessage('activities.subscription.upgradeModal.cycle.yearly');

  const dateText = formatMessage('activities.subscription.upgradeModal.dateRange', {
    startDate: formatDate(createDate('2025-01-01'), { dateStyle: 'medium' }),
    endDate: formatDate(createDate('2026-01-01'), { dateStyle: 'medium' }),
  });

  const totalSubText = isFiservPartner
    ? formatMessage('activities.subscription.upgradeModal.addToMonthlyBilling')
    : dateText;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dismiss('exit')}
      header={formatMessage('activities.subscription.upgradeModal.title', { planName })}
      primaryButton={{
        isLoading: isUpgradingSubscription,
        label: formatMessage('activities.subscription.upgradeModal.primaryButton'),
        onClick: () => upgradeSubscription(fundingSourceField),
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.subscription.upgradeModal.secondaryButton'),
        onClick: () => dismiss('cancel'),
        variant: 'tertiary',
      }}
      data-testid="subscription-upgrade-modal-activity"
    >
      <Group variant="vertical" spacing="m">
        {upgradeError && (
          <SectionBanner
            description={formatMessage('activities.subscription.upgradeModal.error')}
            variant="critical"
            data-testid="subscription-upgrade-modal-error"
            data-role="alert"
          />
        )}

        <Group variant="vertical" spacing="m" as="ul">
          <Group variant="horizontal" spacing="s" justifyContent="space-between" as="li">
            <Group variant="vertical" spacing="none" textAlign="start">
              <Text textStyle="body2Semi">{planName}</Text>
              {!isFiservPartner && (
                <Text textStyle="body4" color="neutral.darker">
                  {billingCycleLabel}
                </Text>
              )}
            </Group>
            <Group variant="vertical" spacing="none" textAlign="end">
              <Text textStyle="body2Semi">{currencyFormatter(planUpgradePrice)}</Text>
              {!isFiservPartner && (
                <Text textStyle="body4" color="neutral.darker">
                  {formatMessage('activities.subscription.upgradeModal.priceDetails', {
                    usersNumber: subscription?.measurementInfo?.quantity,
                    monthlyPricePerUser: currencyFormatter(33),
                  })}
                </Text>
              )}
            </Group>
          </Group>

          <Group as="li" aria-hidden="true">
            <Divider />
          </Group>

          {!isFiservPartner && (
            <Group variant="vertical" spacing="m" as="li">
              <DetailsRow label="Subtotal" value={currencyFormatter(100)} />
              <DetailsRow label="Taxes" value={currencyFormatter(233)} />
              <Divider />
            </Group>
          )}

          <Group variant="horizontal" spacing="s" justifyContent="space-between" as="li">
            <Group variant="vertical" spacing="none" textAlign="start">
              <Text textStyle="body2Semi">{formatMessage('activities.subscription.upgradeModal.total')}</Text>
              <Text textStyle="body4" color="neutral.darker">
                {totalSubText}
              </Text>
            </Group>
            <Group variant="vertical" spacing="none" textAlign="end">
              <Text textStyle="body1Semi">{currencyFormatter(planUpgradePrice - currentPlanPrice)}</Text>
            </Group>
          </Group>
        </Group>

        {isFiservPartner && <PaymentMethodsDropdown fundingSources={fundingSources} registerField={registerField} />}
      </Group>
    </Modal>
  );
};
