import { getPartnerLocalStorage, LocalStorageKeys } from '@melio/local-storage';

import { getMelioAccount } from '@/utils/getAccessTokenData.utils';
import { getPartnerNameFromUrl } from '@/utils/partner.utils';

export const getApiHeaders = () => ({
  headers: {
    'Melio-Account': getMelioAccount(),
  },
});

export const getPartnerTokens = () => {
  const partnerName = getPartnerNameFromUrl();
  const localStorage = getPartnerLocalStorage({ partnerName });
  return {
    accessToken: localStorage.getItem(LocalStorageKeys.accessToken) || '',
    refreshToken: localStorage.getItem(LocalStorageKeys.refreshToken) || '',
  };
};

export const removePartnerTokens = () => {
  const partnerName = getPartnerNameFromUrl();
  const localStorage = getPartnerLocalStorage({ partnerName });
  localStorage.removeItem(LocalStorageKeys.accessToken);
  localStorage.removeItem(LocalStorageKeys.refreshToken);
};
