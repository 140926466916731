import { IconProps, SelectionCardProps } from '@melio/penny';
import { DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type UseDeliveryMethodToSelectionCardPropsMapParams = {
  openSUVCModal: () => void;
  onDMselection: (dmTypeOption: DeliveryMethodType) => void;
  formatMessage: ReturnType<typeof useMelioIntl>['formatMessage'];
};

type CommonDMSelectionCardProps = Pick<SelectionCardProps, 'mainLabelProps' | 'descriptionProps' | 'onClick'> & {
  icon: IconProps['type'];
  // TODO: ME-58102 remove once fixed
  variant: 'flat' | 'default';
};

export const getDeliveryMethodToSelectionCardPropsMap = ({
  openSUVCModal,
  onDMselection,
  formatMessage,
}: UseDeliveryMethodToSelectionCardPropsMapParams): Partial<
  Record<DeliveryMethodType, CommonDMSelectionCardProps>
> => ({
  [DeliveryMethodType.BankAccount]: {
    icon: 'bank',
    mainLabelProps: { label: formatMessage('vex.screen.deliveryMethodSelection.bankTransferLabel') },
    descriptionProps: {
      label: formatMessage('vex.screen.deliveryMethodSelection.bankTransferDescription'),
    },
    variant: 'flat',
    onClick: () => onDMselection(DeliveryMethodType.BankAccount),
  },
  [DeliveryMethodType.VirtualCard]: {
    icon: 'credit-card',
    mainLabelProps: {
      label: formatMessage('vex.screen.deliveryMethodSelection.virtualCardLabel'),
      badgeProps: {
        label: formatMessage('vex.screen.deliveryMethodSelection.virtualCard.badgeLabel'),
        status: 'brand',
        type: 'secondary',
      },
    },
    descriptionProps: {
      label: formatMessage('vex.screen.deliveryMethodSelection.virtualCardDescription'),
    },
    variant: 'flat',
    onClick: () => {
      openSUVCModal();
    },
  },
  [DeliveryMethodType.PaperCheck]: {
    icon: 'paper-check',
    mainLabelProps: { label: formatMessage('vex.screen.deliveryMethodSelection.paperCheckLabel') },
    descriptionProps: {
      label: formatMessage('vex.screen.deliveryMethodSelection.paperCheckDescription'),
    },
    variant: 'flat',
    onClick: () => onDMselection(DeliveryMethodType.PaperCheck),
  },
});
