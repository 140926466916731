import { useBreakpoint } from '@melio/penny';

import { ComponentThemeType } from '@/cl/theme/theme.hooks';

export const SettingsCardTheme: ComponentThemeType = (currentTheme) => {
  const { isExtraSmallScreen } = useBreakpoint();
  return {
    baseStyle: {
      container: {
        display: 'flex',
        gap: '16px',
        height: '54px',
        padding: 's',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'full',
      },
      icon: {
        color: currentTheme.colors.neutral.black,
      },
      textContainer: {
        flexDirection: 'column',
        flex: 1,
        padding: 0,
        textAlign: 'left',
      },
      content: {
        ...(isExtraSmallScreen ? currentTheme.textStyles.body3Semi : currentTheme.textStyles.body2Semi),
        color: currentTheme.colors.neutral.black,
      } as never,
      helperText: {
        ...currentTheme.textStyles.body4,
        color: currentTheme.colors.neutral.darkest,
      } as never,
    },
  };
};
