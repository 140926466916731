import { Box, Flex } from '@chakra-ui/react';
import { Button, Group, Icon, Image, Link, Text, useBreakpoint } from '@melio/penny';

export type BannerProps = {
  mode: 'light' | 'dark';
  title: string;
  description: string;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  linkText?: string;
  linkHref?: string;
  linkOnClick?: () => void;
  imageUrl?: string;
  imageAsIcon?: boolean;
  onClose: () => void;
};

export const Banner = ({
  mode,
  imageUrl,
  imageAsIcon,
  onClose,
  title,
  description,
  linkText,
  secondaryButtonText,
  primaryButtonText,
  linkOnClick,
  primaryButtonOnClick,
  secondaryButtonOnClick,
  linkHref,
}: BannerProps) => {
  const { isExtraSmallScreen } = useBreakpoint();

  const backgroundColor = mode === 'dark' ? 'neutral.black' : 'neutral.white';
  const titleTextColor = mode === 'dark' ? 'neutral.white' : 'neutral.black';
  const titleSize = isExtraSmallScreen ? 'body4Semi' : 'body3Semi';
  const descriptionSize = isExtraSmallScreen ? 'caption1' : 'body4';
  const descriptionTextColor = mode === 'dark' ? 'neutral.lighter' : 'neutral.darker';
  const iconColor = mode === 'dark' ? 'inverted' : 'default';
  const linkColor = mode === 'dark' ? 'inverted' : 'default';

  const imageSizeProps = imageAsIcon ? { width: '24px', height: '24px' } : { width: '64px', height: '64px' };

  return (
    <Flex
      bg={backgroundColor}
      padding="s"
      maxWidth={imageAsIcon ? '340px' : '356px'}
      width="auto"
      height="auto"
      boxShadow="600"
      borderRadius="l"
      align="center"
      justify="space-between"
    >
      <Group variant="vertical">
        <Group spacing="xs">
          {imageUrl && <Image {...imageSizeProps} src={imageUrl} alt="" />}
          <Group spacing="xs" variant="vertical" textAlign="start">
            <Text textStyle={titleSize} color={titleTextColor}>
              {title}
            </Text>
            <Text textStyle={descriptionSize} color={descriptionTextColor}>
              {`${description} `}
              {linkText && linkOnClick && linkHref && (
                <b>
                  <Link
                    variant="inline"
                    href={linkHref}
                    label={linkText}
                    color={linkColor}
                    onClick={(e) => {
                      e.preventDefault();
                      linkOnClick();
                    }}
                  />
                </b>
              )}
            </Text>
          </Group>
          <Box
            width="s"
            height="s"
            onClick={onClose}
            cursor="pointer"
            display="flex"
            justifyContent="right"
            justifySelf="stretch"
            alignSelf="self-start"
            margin={0}
          >
            <Icon type="close" size="small" color={iconColor} />
          </Box>
        </Group>
        {!linkText && (
          <Group spacing="xs" justifyContent="flex-end">
            {secondaryButtonText && primaryButtonText && (
              <Button
                size="small"
                label={secondaryButtonText}
                variant={mode === 'dark' ? 'secondary-inverse' : 'secondary'}
                onClick={secondaryButtonOnClick}
              />
            )}
            {primaryButtonText && (
              <Button size="small" label={primaryButtonText} variant="primary" onClick={primaryButtonOnClick} />
            )}
          </Group>
        )}
      </Group>
    </Flex>
  );
};
