import { useSetAppToast } from '@/store/app/app.model';

// Set the app toast - use this hook instead of useToast
export const useAppToast = () => {
  const setAppToast = useSetAppToast();

  const infoToast = (title: string) => setAppToast({ type: 'informative', title });
  const errorToast = (title: string) => setAppToast({ type: 'error', title });
  const successToast = (title: string) => setAppToast({ type: 'success', title });

  return { infoToast, errorToast, successToast };
};
