import {
  ApiCreateRequest,
  ApiDeleteRequest,
  ApiGetRequest,
  ApiUpdateRequest,
  extractData,
} from '@melio/platform-api-axios-client';
import { useUpdateEffect } from '@melio/platform-utils';
import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useQuery, useQueryClient } from 'react-query';

import {
  AnyType,
  ApiError,
  ApiQueryKeyV2,
  ModelPropsV2,
  PromiseFunctionReturnData,
  UseModelApiResultV2,
} from './types';
import { useCreateMutationV2 } from './useCreateMutationV2';
import { useDeleteMutationV2 } from './useDeleteMutationV2';
import { useUpdateMutationV2 } from './useUpdateMutationV2';
import { createQueryKeyV2 } from './utilV2';

type RequiredParameters<T extends (...args: AnyType[]) => AnyType> = Required<Parameters<T>>;

export const useModelApiV2 = <
  TQueryFn extends ApiGetRequest,
  TUpdateQueryFn extends ApiUpdateRequest<PromiseFunctionReturnData<TQueryFn>> = never,
  TDeleteQueryFn extends ApiDeleteRequest = never,
  TCreateQueryFn extends ApiCreateRequest = never,
  TData = PromiseFunctionReturnData<TQueryFn>,
  TUpdateVariables = RequiredParameters<TUpdateQueryFn>[1],
  TCreateVariables = Required<Parameters<TCreateQueryFn>>[0],
  TError = ApiError
>({
  queryKey: _queryKey,
  id: _id = '',
  enabled = true,
  scope = 'default',
  queryFn: _queryFn,
  updateFn,
  onUpdate,
  onUpdateError,
  prepareUpdateParams: prepareUpdateData,
  deleteFn,
  onDelete,
  onDeleteError,
  createFn,
  onCreate,
  onCreateError,
  prepareCreateParams: prepareCreateData,
  ...options
}: ModelPropsV2<
  TQueryFn,
  TUpdateQueryFn,
  TDeleteQueryFn,
  TCreateQueryFn,
  TData,
  TUpdateVariables,
  TCreateVariables,
  TError
>): UseModelApiResultV2<TDeleteQueryFn, TData, TUpdateVariables, TCreateVariables, TError> => {
  type TQueryFnData = PromiseFunctionReturnData<TQueryFn>;

  const [id, setId] = useState<string>(_id);
  useUpdateEffect(() => void (id != _id && setId(_id)), [_id]);

  const queryClient = useQueryClient();
  const queryKey = createQueryKeyV2({ queryKey: _queryKey, role: 'model', scope, id });

  const queryFn = () => _queryFn(id).then(extractData<TQueryFnData, TData>);

  const query = useQuery<TQueryFnData, TError, TData, ApiQueryKeyV2>(queryKey, {
    ...options,
    queryFn,
    enabled: enabled && !!id,
  });

  const updateMutation = useUpdateMutationV2<TUpdateQueryFn, TData, TUpdateVariables, TError>(updateFn, queryKey, {
    onSuccess: onUpdate,
    onError: onUpdateError,
    prepareParams: prepareUpdateData,
    select: options.select,
  });

  const deleteMutation = useDeleteMutationV2<TDeleteQueryFn, TError>(deleteFn, queryKey, {
    onSuccess: onDelete,
    onError: onDeleteError,
  });

  const createMutation = useCreateMutationV2<TCreateQueryFn, TCreateVariables, TData, TError>(createFn, queryKey, {
    onSuccessInternal: (data) => {
      const id = data.id as string;
      const targetQueryKey = [...queryKey];
      targetQueryKey[2] = id;

      queryClient.setQueryData<TData>(targetQueryKey, data);
      queryClient.setQueryData<TData>(queryKey, data);
      setId(id);
    },
    onSuccess: onCreate,
    onError: onCreateError,
    prepareData: prepareCreateData,
    select: options.select,
  });

  return {
    ...query,
    id,
    queryKey,
    isMutating: queryClient.isMutating({ mutationKey: queryKey }) > 0,
    update: updateMutation.createCallback(id),
    delete: deleteMutation.createCallback(id),
    create: createMutation.mutateAsync,
    _mutations: {
      update: updateMutation,
      delete: deleteMutation,
      create: createMutation,
    },
  };
};
