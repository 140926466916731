import { Box } from '@chakra-ui/react';
import { Button, Container, Group, Icon, Link, Text } from '@melio/penny';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { CollapsibleCardFormWidget } from '../CollapsibleCardForm';

type VendorOnboardingInfoWidgetProps = {
  onSubmit: () => void;
  onOpen: () => void;
  onPrivacyPolicyClick: () => void;
  onTermsOfServiceClick: () => void;
  isOpened?: boolean;
  isFilled: boolean;
  feeCap: number;
  feePercentage: number;
};

export const VendorOnboardingInfoWidget = forwardRef<VendorOnboardingInfoWidgetProps, 'div'>(
  ({ onSubmit, feeCap = 10, feePercentage = 1, onPrivacyPolicyClick, onTermsOfServiceClick, ...cardProps }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { termsOfServiceUrl, privacyPolicyUrl } = useConfig().settings;

    return (
      <CollapsibleCardFormWidget
        title={formatMessage('vex.widgets.vendorOnboarding.introduction.title')}
        {...cardProps}
        ref={ref}
      >
        <Group variant="vertical" spacing="l">
          <Group variant="vertical" hasDivider spacing="l">
            <Group variant="vertical" spacing="s">
              <Group alignItems="center">
                <Box color="success.main" display="flex" alignItems="center">
                  <Icon type="checked-circle" color="inherit" />
                </Box>
                <Text textStyle="body2" color="neutral.darkest">
                  {formatMessage('vex.widgets.vendorOnboarding.introduction.firstCheck')}
                </Text>
              </Group>
              <Group alignItems="center">
                <Box color="success.main" display="flex" alignItems="center">
                  <Icon type="checked-circle" color="inherit" />
                </Box>
                <Text textStyle="body2" color="neutral.darkest">
                  {formatMessage('vex.widgets.vendorOnboarding.introduction.secondCheck')}
                </Text>
              </Group>
              <Group alignItems="center">
                <Box color="success.main" display="flex" alignItems="center">
                  <Icon type="checked-circle" color="inherit" />
                </Box>
                <Text textStyle="body2" color="neutral.darkest">
                  {formatMessage('vex.widgets.vendorOnboarding.introduction.thirdCheck')}
                </Text>
              </Group>
            </Group>
            <Group spacing="s">
              <Group variant="vertical">
                <Text textStyle="body2Semi">
                  {formatMessage('vex.widgets.vendorOnboarding.introduction.stepsHeader')}
                </Text>
                <Group
                  alignItems="stretch"
                  hasDivider
                  height="auto"
                  justifyContent="stretch"
                  spacing="s"
                  textAlign="start"
                  variant="horizontal"
                  width="auto"
                >
                  <Group variant="vertical" spacing="xs">
                    <Text color="neutral.darker" textStyle="body4Semi">
                      {formatMessage('vex.widgets.vendorOnboarding.introduction.firstStep.header')}
                    </Text>
                    <Text color="neutral.darker" textStyle="body4">
                      {formatMessage('vex.widgets.vendorOnboarding.introduction.firstStep.content')}
                    </Text>
                  </Group>
                  <Group variant="vertical" spacing="xs">
                    <Text color="neutral.darker" textStyle="body4Semi">
                      {formatMessage('vex.widgets.vendorOnboarding.introduction.secondStep.header')}
                    </Text>
                    <Text color="neutral.darker" textStyle="body4">
                      {formatMessage('vex.widgets.vendorOnboarding.introduction.secondStep.content')}
                    </Text>
                  </Group>
                  <Group variant="vertical" spacing="xs">
                    <Text color="neutral.darker" textStyle="body4Semi">
                      {formatMessage('vex.widgets.vendorOnboarding.introduction.thirdStep.header')}
                    </Text>
                    <Text color="neutral.darker" textStyle="body4">
                      {formatMessage('vex.widgets.vendorOnboarding.introduction.thirdStep.content')}
                    </Text>
                  </Group>
                </Group>
              </Group>
            </Group>
            <Group variant="vertical">
              <Text textStyle="body2Semi">
                {formatMessage('vex.widgets.vendorOnboarding.introduction.termsAndFees.header')}
              </Text>
              <Text textStyle="body2" color="neutral.darkest">
                {formatMessage('vex.widgets.vendorOnboarding.introduction.termsAndFees.content', {
                  feeCap: feeCap.toFixed(2),
                  feePercentage,
                })}
              </Text>

              <Text textStyle="body4" color="neutral.darker">
                <FormattedMessage
                  id="vex.widgets.vendorOnboarding.introduction.termsAndFees.footer"
                  values={{
                    termsOfService: (
                      <Text textStyle="body4" color="brand.main">
                        <Link
                          onClick={onTermsOfServiceClick}
                          color="inherit"
                          href={termsOfServiceUrl}
                          label={formatMessage(
                            'vex.widgets.vendorOnboarding.introduction.termsAndFees.termsAndCondsText'
                          )}
                          newTab
                        />
                      </Text>
                    ),
                    privacyPolicy: (
                      <Text textStyle="body4" color="brand.main">
                        <Link
                          color="inherit"
                          onClick={onPrivacyPolicyClick}
                          href={privacyPolicyUrl}
                          variant="inline"
                          label={formatMessage(
                            'vex.widgets.vendorOnboarding.introduction.termsAndFees.privacyPolicyText'
                          )}
                          newTab
                        />
                      </Text>
                    ),
                  }}
                />
              </Text>
            </Group>
          </Group>
          <Container justifyContent="flex-end">
            <Button
              variant="primary"
              data-testid="vendor-onboarding-info-submit"
              onClick={onSubmit}
              label={formatMessage('vex.widgets.vendorOnboarding.introduction.submit')}
            />
          </Container>
        </Group>
      </CollapsibleCardFormWidget>
    );
  }
);

VendorOnboardingInfoWidget.displayName = 'VendorOnboardingInfoWidget';
