import { PartnerName } from '@melio/platform-api';

import { partnerHostToPartnerName } from '@/partnersConfig.types';

export const getPartnerNameFromPath = () =>
  Object.values(PartnerName).find((partner) => partner === (location.pathname.split('/')[1] as unknown));

export const getPartnerNameFromHost = () =>
  Object.keys(partnerHostToPartnerName).includes(location.hostname)
    ? partnerHostToPartnerName[location.hostname]
    : null;

export const getPartnerNameFromUrl = () => {
  return (getPartnerNameFromPath() || getPartnerNameFromHost()) as PartnerName;
};

export const getPartnerUrlPrefixFromUrl = () => {
  const shouldIncludePartnerNameInPath = !!getPartnerNameFromPath();

  return shouldIncludePartnerNameInPath ? `/${getPartnerNameFromUrl()}` : '';
};
