import { useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';

import { useIsSubscriptionsEnabled, usePlanInfo, useSubscription } from '../../../hooks';
import { SubscriptionUpgradeWithoutTrialModalScreen } from './screens/SubscriptionUpgradeWithoutTrial.modal-screen';
import { SubscriptionUpgradeWithTrialModalScreen } from './screens/SubscriptionUpgradeWithTrial.modal-screen';
import { PlanUpgradeProps } from './types';

type SubscriptionUpgradeModalActivityProps = {
  isOpen: boolean;
  onDismiss?: () => void;
  onClose: () => void;
  planUpgradeProps: PlanUpgradeProps;
};

export const SubscriptionUpgradeModalActivity = withAnalyticsContext<SubscriptionUpgradeModalActivityProps>(
  ({ isOpen, onClose, onDismiss, planUpgradeProps, setAnalyticsProperties }) => {
    const subscription = useSubscription();

    useAnalyticsView('Organization');

    const { planId } = planUpgradeProps;
    const { planName } = usePlanInfo(planId);

    setAnalyticsProperties({
      PageName: `upgrade-to-${planName}`,
      Flow: 'settings',
      Intent: 'upgrade-plan',
      CurrentPlan: subscription?.planId,
    });

    const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

    if (!isSubscriptionsEnabled || !subscription) {
      return null;
    }

    if (subscription.isFreeTrial) {
      return (
        <SubscriptionUpgradeWithTrialModalScreen
          isOpen={isOpen}
          onClose={onClose}
          onDismiss={onDismiss}
          planUpgradeProps={planUpgradeProps}
        />
      );
    }

    return (
      <SubscriptionUpgradeWithoutTrialModalScreen
        isOpen={isOpen}
        onClose={onClose}
        onDismiss={onDismiss}
        planUpgradeProps={planUpgradeProps}
      />
    );
  }
);
