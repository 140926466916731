// eslint-disable-next-line no-restricted-imports
import { PartnerName } from '@melio/platform-api';

export const partnerHostToPartnerName: Record<string, PartnerName> = {
  'vendorpayments-qa.capitalone.com': PartnerName.CapitalOne,
  'vendorpayments.capitalone.com': PartnerName.CapitalOne,
  'billpay-clover.by.melio.com': PartnerName.Clover,
  'melio.by.partnerships.eilat.melioservices.com': PartnerName.Melio,
  'billpay-clover.by.partnerships.eilat.melioservices.com': PartnerName.Clover,
  'app.melio.com': PartnerName.Melio,
} as const;

const getPartnerNameFromPath = () =>
  Object.values(PartnerName).find((partner) => location.pathname.includes(`/${partner}/`));

const getPartnerNameFromHost = () => partnerHostToPartnerName[location.host];

export const getPartnerName = (): PartnerName => {
  const partnerNameFromHost = getPartnerNameFromPath();
  const partnerNameFromPath = getPartnerNameFromHost();
  //** When this package is rendered inside platform-app, we can be certain that we'll have a partner name */
  return (partnerNameFromHost || partnerNameFromPath) as PartnerName;
};

export const isPartnerMelio = (): boolean => {
  const partnerName = getPartnerName();
  return partnerName === PartnerName.Melio;
};
