import { useToast } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionMe } from '../api';
import { usePlansTiers } from './usePlansTiers';
import { useRedirects } from './useRedirects';

export const useSubscribeToFreePlan = () => {
  const { formatMessage } = useMelioIntl();
  const { freePlan } = usePlansTiers();
  const { toast } = useToast();
  const { goToReturnUrlOrDashboard } = useRedirects();
  const { create: createSubscription, isMutating: isSubscribingToFreePlan } = useSubscriptionMe({
    enabled: false,
  });

  const showErrorToast = () =>
    toast({
      type: 'error',
      title: formatMessage('activities.subscription.checkout.summary.toast.error'),
      id: 'subscribe-free-plan-fail',
    });

  const subscribeToFreePlan = () => {
    const freePlanId = freePlan?.id;

    if (!freePlanId) {
      return showErrorToast();
    }

    return createSubscription({ planId: freePlanId, planCyclePeriod: 'monthly' })
      .then(goToReturnUrlOrDashboard)
      .catch(showErrorToast);
  };

  return { isSubscribingToFreePlan, subscribeToFreePlan };
};
