import jwtDecode from 'jwt-decode';

import { getPartnerTokens } from '@/api/utilities';
import { AccessTokenPayloadType } from '@/types/payload.types';

export const decodeAccessToken = (accessToken: string) => jwtDecode<AccessTokenPayloadType>(accessToken);

export const getAccessTokenData = (): AccessTokenPayloadType | null => {
  const { accessToken } = getPartnerTokens();

  if (!accessToken) {
    return null;
  }

  return decodeAccessToken(accessToken);
};

export const getAccessTokenOrganizationId = () => {
  const accessTokenData = getAccessTokenData();

  return accessTokenData?.organization?.id;
};

export const getAccessTokenUserId = () => {
  const accessTokenData = getAccessTokenData();

  return accessTokenData?.user.id;
};

export const getMelioAccount = () => {
  const accessTokenData = getAccessTokenData();
  return accessTokenData?.accountId || '';
};
