import { useMelioIntl } from '@melio/platform-i18n';
import { filter } from 'lodash';

import { Plan, Tier } from '../api';
import { getIsFeatureInPlan } from '../utils/planFeatures.utils';
import { usePlansTiers } from './usePlansTiers';

const RAW_CAPABILITIES_PER_TIER = {
  [Tier.MelioBasic]: [
    'numberOfFreeMembers',
    'quickbooksAndXero',
    'vendorManagement',
    'basicWorkflows',
    'melioBillInbox',
  ],
  [Tier.MelioPlus]: [
    'unlimitedMembers',
    'approvalFlows',
    'vendorStorage',
    'advanceAccountingSync',
    'batchPayments',
    'advancedUserRoles',
    'overallLowerFees',
  ],
  [Tier.MelioPro]: ['multipleWorkspaces', 'customFeesRates', 'prioritySupport'],
  [Tier.FiservApBasic]: [
    'payAnyBillerOrVendor',
    'payByBankTransferOrCard',
    'deliverPaymentsElectronicallyOrByCheck',
    'batchPayments',
    'numberOfFreeMembers',
  ],
  [Tier.FiservApPro]: ['allAPBasicCapabilities', 'syncWithAccountingSoftware', 'numberOfFreeMembers'],
  [Tier.FiservApArBasic]: [], // TODO
  [Tier.FiservApArPro]: [], // TODO
} as const;

const usePlanCapabilities = (plan?: Plan) => {
  const { formatMessage } = useMelioIntl();

  if (!plan) {
    return [];
  }

  const hasQuickbooks = getIsFeatureInPlan(plan, { featureName: 'accountingPlatformQbo' }).isEligible;
  const hasXero = getIsFeatureInPlan(plan, { featureName: 'accountingPlatformXero' }).isEligible;
  const hasBatchPayments = getIsFeatureInPlan(plan, { featureName: 'batchPayments' }).isEligible;

  const getHasCapability = (capability: string) => {
    switch (capability) {
      case 'quickbooksAndXero':
      case 'advanceAccountingSync':
      case 'syncWithAccountingSoftware': {
        return hasQuickbooks && hasXero;
      }
      case 'unlimitedMembers': {
        return !!plan.cycles.monthly.unitPrice; // when the unit price is exist, the plan allow to add unlimited number of members
      }
      case 'batchPayments': {
        return hasBatchPayments;
      }
    }

    return true;
  };

  const getMessageParams = (capability: string) => {
    switch (capability) {
      case 'numberOfFreeMembers': {
        return { userSeats: plan.numberOfFreeUsers };
      }
    }

    return;
  };

  return filter(RAW_CAPABILITIES_PER_TIER[plan.tier], getHasCapability).map((item) =>
    formatMessage(`activities.subscription.plans.capabilities.${item}`, getMessageParams(item))
  );
};

export const usePlanInfo = (planId?: string) => {
  const { formatMessage } = useMelioIntl();
  const { getPlan } = usePlansTiers();

  const plan = planId ? getPlan(planId) : undefined;
  const capabilities = usePlanCapabilities(plan);

  if (!plan) {
    return {
      planName: '',
      planDescription: '',
      capabilities: {
        title: '',
        list: [],
      },
    };
  }

  return {
    planName: formatMessage(`activities.subscription.plans.${plan.tier}.title`),
    planDescription: formatMessage(`activities.subscription.plans.${plan.tier}.description`),
    capabilities: {
      title: formatMessage(`activities.subscription.plans.${plan.tier}.capabilities.title`),
      list: capabilities,
    },
  };
};
