import { Box, Collapse } from '@chakra-ui/react';
import { Badge, Card, Container, Group, Icon, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { ReactNode } from 'react';

export type CollapsibleCardFormWidgetProps = {
  title: ReactNode;
  onOpen: () => void;
  subtitle?: string;
  children: ReactNode;
  isOpened?: boolean;
  isFilled?: boolean;
  isDisabled?: boolean;
  isOptional?: boolean;
};

export const CollapsibleCardFormWidget = forwardRef<CollapsibleCardFormWidgetProps, 'div'>(
  (
    { title, subtitle, children, onOpen, isFilled = false, isOpened = false, isDisabled = false, isOptional, ...props },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const blockCardOpen = isDisabled && !isFilled;
    const openCard = () => {
      if (!blockCardOpen) {
        onOpen();
      }
    };

    return (
      <Card
        variant="flat"
        isSelected={isOpened}
        paddingX="m"
        paddingY="l"
        data-component="CollapsibleCardFormWidget"
        data-testid="CollapsibleCardFormWidget"
        ref={ref}
        {...props}
      >
        <Container>
          <Group variant="vertical" spacing="xs">
            <Box
              data-testid="CollapsibleCardFormWidget.header"
              display="flex"
              paddingX="xs"
              justifyContent="space-between"
              onClick={openCard}
              _hover={{ cursor: blockCardOpen ? 'not-allowed' : 'pointer' }}
            >
              <Group justifyContent="flex-start" spacing="xxs" alignItems="center">
                <Text textStyle="heading3Semi" color={blockCardOpen ? 'neutral.light' : 'neutral.black'}>
                  {title}
                </Text>
                {isOptional && (
                  <Badge
                    type="secondary"
                    status="neutral"
                    isDisabled={blockCardOpen}
                    label={formatMessage('vex.widgets.collapsibleCard.optional')}
                    aria-label="optional"
                  />
                )}
              </Group>
              {isFilled && (
                <Box color="success.main">
                  <Icon type="checked-circle-fill" color="inherit" />
                </Box>
              )}
            </Box>
            {subtitle && isOpened && (
              <Container paddingX="xs">
                <Text textStyle="body2" color="neutral.darkest">
                  {subtitle}
                </Text>
              </Container>
            )}
          </Group>
          <Collapse in={isOpened}>
            <Container paddingTop="l" paddingX="xs">
              {children}
            </Container>
          </Collapse>
        </Container>
      </Card>
    );
  }
);

CollapsibleCardFormWidget.displayName = 'CollapsibleCardFormWidget';
