import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  SUBSCRIPTION_PAYMENT_METHOD_SELECT_ROUTE,
  SUBSCRIPTION_SETTINGS_PLANS_ROUTE,
  SubscriptionTabsEnum,
} from '../constants';

export const TAB_PREFIX = 'subscription';
export const PLANS_URL = `/${TAB_PREFIX}/${SubscriptionTabsEnum.Plans}`;
export const CHECKOUT_URL = `/${TAB_PREFIX}/${SubscriptionTabsEnum.Checkout}`;
export const PAYMENT_METHOD_BANK_SELECT_URL = '/payment-methods/bank/select';

export const useSubscriptionRouter = () => {
  const navigate = useNavigate();

  return React.useMemo(
    () => ({
      goToAddBankAccount: ({ returnUrl }: { returnUrl: string }) =>
        navigate(`${PAYMENT_METHOD_BANK_SELECT_URL}?integrationType=plaid&returnUrl=${returnUrl}`),
      goToAddCard: ({ returnUrl }: { returnUrl: string }) =>
        navigate('/payment-methods/card', { state: { returnUrl } }),
      goToSubscriptionPlans: ({ returnUrl, promoCode }: { returnUrl?: string; promoCode?: string }) =>
        navigate(PLANS_URL, { state: { returnUrl, utm_promo: promoCode } }),
      goToPaymentMethodSelect: () => navigate(SUBSCRIPTION_PAYMENT_METHOD_SELECT_ROUTE),
      goToSubscriptionPlansSettings: () => navigate(SUBSCRIPTION_SETTINGS_PLANS_ROUTE),
    }),
    [navigate]
  );
};
