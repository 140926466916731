import { Badge, Container, Text } from '@melio/penny';

import type { Plan } from '../../../../../api';
import { usePlanInfo } from '../../../../../hooks';
import { usePlanBadge } from './PlanCardHeader.utils';

type PlanCardHeaderProps = {
  planId: Plan['id'];
};

export const PlanCardHeader = ({ planId }: PlanCardHeaderProps) => {
  const { planName, planDescription } = usePlanInfo(planId);

  const planBadge = usePlanBadge(planId);

  return (
    <Container data-testid="subscription-plan-card-header">
      <Container justifyContent="space-between" alignItems="center" paddingBottom="s">
        <Text as="h2" textStyle="heading3Semi">
          {planName}
        </Text>

        {planBadge && <Badge status="brand" {...planBadge} />}
      </Container>

      <Text textStyle="body3">{planDescription}</Text>
    </Container>
  );
};
