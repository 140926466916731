import { Flex } from '@chakra-ui/react';
import { Link, PromotionalModal, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import virtualCardPromotionalImage from './assets/virtualcardandposterminal.svg';

export type SUVCInformationModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onBack?: VoidFunction;
  onConfirm?: VoidFunction;
  learnMoreLink: string;
};

export const SUVCInformationModal = ({
  learnMoreLink,
  onBack,
  onConfirm,
  ...modalProps
}: SUVCInformationModalProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <PromotionalModal
      header={formatMessage('vex.widgets.SUVCInformationModal.title')}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage('vex.widgets.SUVCInformationModal.secondary'),
        onClick: onBack,
      }}
      primaryButton={{
        variant: 'primary',
        label: formatMessage('vex.widgets.SUVCInformationModal.primary'),
        onClick: onConfirm,
      }}
      description={formatMessage('vex.widgets.SUVCInformationModal.description', {
        learnMoreSection: (
          <Link
            href={learnMoreLink}
            label={formatMessage('vex.widgets.SUVCInformationModal.descriptionLearnMore')}
            newTab
          />
        ),
      })}
      asset={
        <Flex w="full" bgColor="#FBF1E2" padding="m" alignItems="center" justifyContent="center">
          <img src={virtualCardPromotionalImage} alt={formatMessage('vex.widgets.SUVCInformationModal.imageAlt')} />
        </Flex>
      }
      {...modalProps}
    >
      <SectionBanner
        variant="informative"
        description={formatMessage('vex.widgets.SUVCInformationModal.posbanner.description')}
        title={formatMessage('vex.widgets.SUVCInformationModal.posbanner.title')}
      />
    </PromotionalModal>
  );
};
