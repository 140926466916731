import { Button, Form, Group, Text, useMelioForm } from '@melio/penny';
import { MeOrganization } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

import { FormWidgetProps, SelectOrganizationModel } from '../../types';

export type CompanySelectionProps = FormWidgetProps<SelectOrganizationModel> & {
  organizations: MeOrganization[];
  titleMessagePath?: MessageKey;
  inputLabelMessagePath?: MessageKey;
  buttonMessagePath?: MessageKey;
  isRequired?: boolean;
};
export const CompanySelection = forwardRef<CompanySelectionProps, 'div'>(
  (
    {
      onSubmit,
      isSaving,
      organizations,
      onSubmissionStateChange,
      titleMessagePath = 'vex.widgets.companySelection.title',
      inputLabelMessagePath = 'vex.widgets.companySelection.input.label',
      buttonMessagePath = 'vex.widgets.companySelection.button',
      isRequired = false,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const options = useMemo(
      () =>
        organizations
          .filter(({ companyName }) => companyName)
          .map(({ companyName, id }) => ({
            label: companyName || '',
            value: id,
          })),
      [organizations]
    );
    const defaultValues = { organizationId: options.at(0)?.value };

    const { registerField, formProps, submitButtonProps } = useMelioForm<SelectOrganizationModel>({
      onSubmit,
      defaultValues,
      onSubmissionStateChange,
      isSaving,
    });

    return (
      <Group
        data-component="CompanySelection"
        data-testid="CompanySelection"
        variant="vertical"
        spacing="l"
        ref={ref}
        {...props}
      >
        <Text as="h1" textStyle="heading3Semi">
          {formatMessage(titleMessagePath)}
        </Text>
        <Form size="small" {...formProps}>
          <Form.DeprecatedSelect
            options={options}
            labelProps={{
              label: formatMessage(inputLabelMessagePath),
            }}
            {...registerField('organizationId')}
            isRequired={isRequired}
            columns={2}
          />
        </Form>
        <Group variant="vertical" spacing="s">
          <Button
            data-testid="layout-next-button"
            {...submitButtonProps}
            label={formatMessage(buttonMessagePath)}
            size="large"
            isFullWidth
          />
        </Group>
      </Group>
    );
  }
);

CompanySelection.displayName = 'CompanySelection';
