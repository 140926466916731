import { PaymentUpgradeOfferOption, PaymentUpgradeOfferOptionEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useIsMobile } from '@melio/vex-widgets';
import { intlFormat, isToday, isTomorrow } from 'date-fns';

type UseDeliveryDateLabelParams = {
  offerType: PaymentUpgradeOfferOptionEnum;
  expeditedDeliveryEta: Date;
};

export const useSuccessScreenDeliveryDateLabel = ({
  offerType,
  expeditedDeliveryEta,
}: UseDeliveryDateLabelParams): string => {
  const { formatMessage } = useMelioIntl();
  const isMobile = useIsMobile();

  if (offerType !== PaymentUpgradeOfferOptionEnum.Fach) {
    return formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.deliveryEtaLabel.instant');
  }

  if (isToday(expeditedDeliveryEta)) {
    return formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.deliveryEtaLabel.today');
  }

  if (isTomorrow(expeditedDeliveryEta) && !isMobile) {
    return formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.deliveryEtaLabel.tomorrow');
  }

  return formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeSuccess.deliveryEtaLabel.weekday', {
    weekday: intlFormat(expeditedDeliveryEta, { weekday: isMobile ? 'short' : 'long' }),
  });
};

export const useConfirmScreenDeliveryDateLabel = (upgradeOffer: PaymentUpgradeOfferOption | undefined): string => {
  const isMobile = useIsMobile();
  const { formatMessage } = useMelioIntl();

  if (!upgradeOffer) {
    return '';
  }

  const { deliveryETA: expeditedDeliveryEta, offerType } = upgradeOffer;

  if (offerType !== PaymentUpgradeOfferOptionEnum.Fach) {
    return formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeConfirm.deliveryEtaLabel.instant');
  }

  if (isToday(expeditedDeliveryEta)) {
    return formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeConfirm.deliveryEtaLabel.today');
  }

  if (isTomorrow(expeditedDeliveryEta) && !isMobile) {
    return formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeConfirm.deliveryEtaLabel.tomorrow');
  }

  return formatMessage('vex.activities.paymentUpgrade.screens.paymentUpgradeConfirm.deliveryEtaLabel.weekday', {
    weekday: intlFormat(expeditedDeliveryEta, { weekday: isMobile ? 'short' : 'long' }),
  });
};
