import { isAfter } from 'date-fns';
import { isArray, isDate, isObject, isString } from 'lodash';

import { Data, updateProperties } from '../object-utils';

const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

function replaceDate(src: string) {
  // eslint-disable-next-line no-restricted-syntax
  return DATE_PATTERN.test(src) ? new Date(src) : src;
}

export function convertDates<T>(entity: T): T {
  if (isDate(entity)) {
    return entity;
  } else if (isString(entity)) {
    return replaceDate(entity) as unknown as T;
  } else if (isArray(entity)) {
    return entity.map(convertDates) as unknown as T;
  } else if (isObject(entity)) {
    return Object.entries(entity).reduce(
      (target, [key, value]) => ({
        ...target,
        [key]: convertDates(value as T),
      }),
      {} as T
    );
  } else {
    return entity;
  }
}

// XXX hack to get around payment intent expects the dates to be YYYY-MM-DD and not a standard ISO date string
// https://meliorisk.atlassian.net/browse/ME-19758
export const converDateToStringRepresentation = (date?: Date) => date?.toISOString().substring(0, 10) as never;
export const convertDateToStringRepresentation = (date: Date) => date.toISOString().slice(0, 10);

export const calculateMonthlyCountFromDateRange = (startDate: Date, endDate: Date): number => {
  if (isAfter(startDate, endDate)) {
    throw new Error('Start date must be sooner than end date');
  }

  const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
  const monthCorrection = endDate.getDate() < startDate.getDate() ? 0 : 1;
  const monthsDifference = endDate.getMonth() - startDate.getMonth() + monthCorrection;
  return yearsDifference * 12 + monthsDifference;
};

const trimDate = (date?: string | Date) => (isDate(date) ? date.toISOString() : date)?.slice(0, 10);

export const updateDateTimeToDateString = <T extends Data, F extends keyof T = keyof T>(object: T, properties: F[]) =>
  updateProperties(object, properties as string[], trimDate) as unknown as ReplaceDates<T, F>;

type ReplaceDates<T extends Data, F extends keyof T> = Override<
  T,
  {
    [P in F]: [T[P]] extends [Date, undefined] ? string | undefined : [T[P]] extends [Date] ? string : T[P];
  }
>;

// eslint-disable-next-line no-restricted-syntax
export const isDateExpired = (dateToCheck: Date) => isAfter(new Date(), dateToCheck);
