import { Modal, useToast } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import noop from 'lodash/noop';

import { useSubscriptionMe } from '../../../api';
import { useIsSubscriptionsEnabled, useSubscription } from '../../../hooks';

export type ReactivatePlanModalProps = {
  isOpen: boolean;
  onClose: () => void;
  variant: 'before-scheduling' | 'cancel-pending-change';
};

export const ReactivatePlanModal = ({ isOpen, onClose, variant }: ReactivatePlanModalProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { isMutating: isUpdatingSubscription, update: updateSubscription } = useSubscriptionMe({
    enabled: false,
  });
  if (!isSubscriptionsEnabled) {
    return null;
  }

  const onReactivatePlan = () => {
    if (!subscription) {
      return;
    }
    void updateSubscription({ planId: subscription.planId, planCyclePeriod: subscription.planCyclePeriod })
      .then(() => {
        toast({
          type: 'success',
          title: formatMessage('activities.subscription.reactivatePlan.successMessage'),
        });
        onClose();
      })
      .catch(() => {
        toast({
          type: 'error',
          title: formatMessage('activities.subscription.reactivatePlan.errorMessage'),
        });
      });
  };

  const getDescription = () => {
    switch (variant) {
      case 'before-scheduling':
        return formatMessage('activities.subscription.reactivationPlanModal.description.beforeScheduling');
      case 'cancel-pending-change':
        return formatMessage('activities.subscription.reactivationPlanModal.description.cancelPendingChange');
    }
  };

  return (
    <Modal
      data-testid="reactivate-plan-modal"
      isOpen={isOpen}
      onClose={isUpdatingSubscription ? noop : onClose}
      header={formatMessage('activities.subscription.reactivationPlanModal.title')}
      description={getDescription()}
      primaryButton={{
        isLoading: isUpdatingSubscription,
        label: formatMessage('activities.subscription.reactivationPlanModal.reactivate'),
        onClick: onReactivatePlan,
        variant: 'primary',
      }}
      secondaryButton={{
        isDisabled: isUpdatingSubscription,
        label: formatMessage('activities.subscription.reactivationPlanModal.cancel'),
        onClick: onClose,
        variant: 'tertiary',
      }}
    />
  );
};
