import { useToast } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useIsSubscriptionsEnabled, usePartnerGroups, usePlanInfo, useRedirects } from '../../../hooks';
import { SubscriptionCancelOrDowngradeOriginEnum } from '../../constants';
import {
  SubscriptionCancelApprovalModalScreen,
  SubscriptionCancelFeedbackModalScreen,
  SubscriptionCancelSuccessModalScreen,
} from './screens';

type CancelOrDowngradeFlowProps = {
  isOpen: boolean;
  newPlanId: string;
  origin?: SubscriptionCancelOrDowngradeOriginEnum;
  onClose: () => void;
};

export const SubscriptionCancelModalActivity = ({ isOpen, newPlanId, origin, onClose }: CancelOrDowngradeFlowProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();

  const { planName: newPlanName } = usePlanInfo(newPlanId);
  const { goToDashboard, goToSettingsPage } = useRedirects();
  const { isFiservPartner } = usePartnerGroups();

  const redirectWithToast = () => {
    const redirectAfterCancel = isFiservPartner ? goToSettingsPage : goToDashboard;

    redirectAfterCancel();
    toast({
      id: 'subscription-downgrade-success',
      type: 'success',
      title: formatMessage('activities.subscription.cancelOrDowngradeFlow.toast.success', { planName: newPlanName }),
    });
  };

  const cancelApprovalModal = {
    isOpen,
    onSuccess: () => {
      if (isFiservPartner) {
        redirectWithToast();
      } else {
        setIsFeedbackModalOpen(true);
      }
      onClose();
    },
    onClose,
  };

  const feedbackModal = {
    isOpen: isFeedbackModalOpen,
    onSuccess: () => {
      setIsSuccessModalOpen(true);
      setIsFeedbackModalOpen(false);
    },
    onClose: () => {
      setIsSuccessModalOpen(true);
      setIsFeedbackModalOpen(false);
    },
  };

  const successModal = {
    isOpen: isSuccessModalOpen,
    onClose: () => {
      setIsSuccessModalOpen(false);

      if (origin !== SubscriptionCancelOrDowngradeOriginEnum.Settings) {
        goToSettingsPage();
      }
    },
  };

  if (!isSubscriptionsEnabled) {
    return null;
  }

  return (
    <>
      <SubscriptionCancelApprovalModalScreen
        isOpen={cancelApprovalModal.isOpen}
        onClose={cancelApprovalModal.onClose}
        onSuccess={cancelApprovalModal.onSuccess}
        newPlanId={newPlanId}
      />
      <SubscriptionCancelFeedbackModalScreen
        isOpen={feedbackModal.isOpen}
        onClose={feedbackModal.onClose}
        onSuccess={feedbackModal.onSuccess}
      />
      <SubscriptionCancelSuccessModalScreen isOpen={successModal.isOpen} onClose={successModal.onClose} />
    </>
  );
};
