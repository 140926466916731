import { isCollaboratorBlocked } from '@melio/ap-domain';
import { Group, Icon, Modal, SectionBanner, SimpleGrid, StatusModal, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useCollaborators } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useSubscriptionMe } from '../../../../../api';
import {
  usePartnerGroups,
  usePlanInfo,
  usePlansFeaturesDifference,
  usePlansTiers,
  useRedirects,
  useSubscription,
} from '../../../../../hooks';
import { getQuantityBasedIsFeatureInPlan } from '../../../../../utils';

type SubscriptionCancelApprovalModalScreenProps = {
  isOpen: boolean;
  newPlanId: string;
  onClose: () => void;
  onSuccess?: () => void;
};

export const SubscriptionCancelApprovalModalScreen = withAnalyticsContext<SubscriptionCancelApprovalModalScreenProps>(
  ({ isOpen, newPlanId, onClose, onSuccess, setAnalyticsProperties }) => {
    const [accountManagerAccessCollaboratorsEnabled] = useFeature<boolean>(
      FeatureFlags.AccountManagerAccessCollaborators,
      false
    );
    const [downgradeError, setDowngradeError] = useState<boolean>(false);
    const { formatMessage, formatDate } = useMelioIntl();
    const subscription = useSubscription();
    const { getPlan } = usePlansTiers();
    const { getDowngradedPlanFeaturesLost } = usePlansFeaturesDifference();
    const planId = subscription?.planId;
    const { isFiservPartner } = usePartnerGroups();
    const { goToSettingsCollaboratorsPage } = useRedirects();
    const { planName: currentPlanName } = usePlanInfo(planId);
    const { planName: newPlanName } = usePlanInfo(newPlanId);
    const {
      data: collaborators,
      isFetching: isCollaboratorsFetching,
      isLoading: isCollaboratorsLoading,
    } = useCollaborators({
      enabled: accountManagerAccessCollaboratorsEnabled,
    });

    const isFetching = isCollaboratorsFetching || isCollaboratorsLoading;

    const { update: updateSubscription, isMutating: isUpdatingSubscription } = useSubscriptionMe({ enabled: false });
    const featuresLostList = getDowngradedPlanFeaturesLost(newPlanId);

    const ModalComponent = isFiservPartner ? StatusModal : Modal;
    const hasPartnerColors = !!isFiservPartner;

    setAnalyticsProperties({
      PageName: 'downgrade-plan',
      Flow: 'settings',
      Intent: 'cancel-my-plan',
      CurrentPlan: planId,
    });

    const { track } = useAnalytics();

    useAnalyticsView('Organization');

    const formattedNextBillingDate = formatDate(subscription?.nextBillingDate, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    const activeCollaborators = collaborators?.filter((c) => !isCollaboratorBlocked(c)) ?? [];

    const newPlan = getPlan(newPlanId);
    const hasTooManySeats =
      newPlan && !getQuantityBasedIsFeatureInPlan(newPlan, 'collaborators', activeCollaborators.length).isEligible;

    const switchSubscription = () => {
      setDowngradeError(false);

      void updateSubscription({ planId: newPlanId })
        .then(() => {
          track('Organization', 'Status', {
            StatusType: 'success',
          });
          onSuccess?.();
        })
        .catch(() => {
          setDowngradeError(true);
          track('Organization', 'Status', {
            StatusType: 'failure',
            ErrorType: 'submit-error',
          });
        });
    };

    const onCloseHandler = (closeType: string) => {
      track('Organization', 'Click', {
        Intent: closeType,
        Cta: closeType,
      });
      onClose();
    };

    const content = hasTooManySeats
      ? {
          primaryButton: {
            textKey: 'activities.subscription.cancelModal.cancel.tooManyUsers.primaryButton',
            variant: hasPartnerColors ? 'primary' : 'critical',
            onClick: onClose,
          } as const,
          secondaryButton: {
            textKey: 'activities.subscription.cancelModal.cancel.tooManyUsers.secondaryButton',
            onClick: () => {
              goToSettingsCollaboratorsPage();
              track('Organization', 'Click', {
                Intent: 'users-and-role-settings',
                Cta: 'users-and-role-settings',
              });
            },
            variant: 'tertiary',
          } as const,
          descriptionKey: 'activities.subscription.cancelModal.cancel.tooManyUsers.description' as const,
        }
      : {
          primaryButton: {
            textKey: 'activities.subscription.cancelModal.cancel.primaryButton',
            variant: hasPartnerColors ? 'primary' : 'critical',
            onClick: switchSubscription,
          } as const,
          secondaryButton: {
            textKey: 'activities.subscription.cancelModal.secondaryButton',
            onClick: () => {
              onCloseHandler('keep-my-plan');
            },
            variant: hasPartnerColors ? 'secondary' : 'tertiary',
          } as const,
          descriptionKey: 'activities.subscription.cancelModal.description' as const,
        };

    const { primaryButton, secondaryButton, descriptionKey } = content;

    return (
      <ModalComponent
        isOpen={isOpen}
        onClose={() => onCloseHandler('exit')}
        header={formatMessage(`activities.subscription.cancelModal.cancel.title`, {
          currentPlanName,
        })}
        primaryButton={{
          isLoading: isUpdatingSubscription,
          label: formatMessage(primaryButton.textKey),
          onClick: primaryButton.onClick,
          variant: primaryButton.variant,
        }}
        secondaryButton={{
          label: formatMessage(secondaryButton.textKey),
          onClick: secondaryButton.onClick,
          variant: secondaryButton.variant,
        }}
        data-testid="subscription-cancel-approval-modal-screen"
        isLoading={isFetching}
        variant="warning"
      >
        <Group variant="vertical" spacing="m">
          {downgradeError && (
            <SectionBanner
              description={formatMessage(`activities.subscription.cancelModal.cancel.toast.error`)}
              variant="critical"
              data-testid="subscription-downgrade-failed"
              data-role="alert"
            />
          )}

          <Text>
            {formatMessage(descriptionKey, {
              date: formattedNextBillingDate,
              newPlanName,
            })}
          </Text>

          {!hasTooManySeats && (
            <Group variant="vertical" spacing="xxxs">
              {featuresLostList.map((feature) => (
                <SimpleGrid key={feature} alignItems="flex-start" display="flex" gap="xs" paddingBottom="xxs">
                  <Text color={hasPartnerColors ? 'neutral.700' : 'critical.main'}>
                    <Icon type="cancel-circle" color="inherit" size="small" aria-hidden="true" />
                  </Text>
                  <Text color={hasPartnerColors ? 'neutral.700' : undefined} textStyle="body3">
                    {feature}
                  </Text>
                </SimpleGrid>
              ))}
            </Group>
          )}

          <SectionBanner
            description={formatMessage(`activities.subscription.cancelModal.cancel.info`)}
            variant="neutral"
            icon="info-fill"
            data-testid="subscription-downgrade-failed"
            data-role="alert"
          />
        </Group>
      </ModalComponent>
    );
  }
);
