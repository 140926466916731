import { PartnerName } from '@melio/platform-api';
import { useInitWizard } from '@melio/platform-utils';

import { useInitPartner } from '@/hooks/partners';
import { getPartnerNameFromHost, getPartnerNameFromPath } from '@/utils/partner.utils';

export const usePartnerAppInit = (): PartnerName => {
  const partnerNameFromHost = getPartnerNameFromHost();
  const partnerNameFromPath = getPartnerNameFromPath();
  const partnerName = partnerNameFromPath || partnerNameFromHost;
  useInitWizard();
  useInitPartner(partnerName!, !!partnerNameFromPath);

  return partnerName as PartnerName;
};
