/* eslint-disable */
import { memoize } from 'lodash';

export const loadSegment = memoize((key: string) => {
  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || []) as any;
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }
  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'screen',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
    'register',
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (e: any) {
    return function () {
      if (window.analytics.initialized) {
        // Sometimes users assigned analytics to a variable before analytics is done loading, resulting in a stale reference.
        // If so, proxy any calls to the 'real' analytics instance.
        return (window.analytics as any)[e].apply(window.analytics, arguments);
      }
      var args = Array.prototype.slice.call(arguments);

      // Add buffered page context object so page information is always up-to-date
      if (['track', 'screen', 'alias', 'group', 'page', 'identify'].indexOf(e) > -1) {
        var c = document.querySelector("link[rel='canonical']");
        args.push({
          __t: 'bpc',
          c: (c && c.getAttribute('href')) || undefined,
          p: location.pathname,
          u: location.href,
          s: location.search,
          t: document.title,
          r: document.referrer,
        });
      }

      args.unshift(e);
      analytics.push(args);
      return analytics;
    };
  };
  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }
  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key: any, options: any) {
    // Create an async script element based on your key.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute('data-global-segment-analytics-key', 'analytics');
    script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first?.parentNode?.insertBefore(script, first);
    analytics._loadOptions = options;
  };
  analytics._writeKey = key;
  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '5.2.1';
  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(key, getSegmentOptions());
  // Make the first page call to load the integrations. If
  // you'd like to manually name or tag the page, edit or
  // move this call however you'd like.
  analytics.page();
});

function getSegmentOptions() {
  return {
    integrations: {
      'Segment.io': {
        deliveryStrategy: {
          strategy: 'batching',
          config: {
            size: 20,
            timeout: 1000,
          },
        },
      },
    },
  };
}
