import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { useNavigate } from '@melio/platform-utils';
import { useState } from 'react';

import { SubscriptionBillingCycleEnum, useSubscriptionMe } from '../../../api';
import {
  usePartnerGroups,
  usePendingSubscription,
  usePlansTiers,
  useRedirects,
  useSubscription,
  useSubscriptionPlan,
  useWizardParams,
} from '../../../hooks';
import { ReactivatePlanModal } from '../../components';
import { DEFAULT_DASHBOARD_ROUTE } from '../../constants';
import { CHECKOUT_URL } from '../../utils';
import { CancelOrDowngradeFlowActivity } from '../CancelOrDowngradeFlow';
import { SubscriptionUpgradeModalActivity } from '../SubscriptionUpgradeModal';
import { PlanUpgradeProps } from '../SubscriptionUpgradeModal/types';
import { SwitchSubscriptionBillingCycleModalScreen } from '../SwitchSubscriptionCycle';
import { SubscriptionPlansScreen } from './screens/SubscriptionPlans.screen';
import { SubscribeToPlanType } from './types';

type SubscriptionPlansActivityProps = {
  returnUrl?: string;
  onBack?: () => void;
};

export const SubscriptionPlansActivity = ({ returnUrl, onBack }: SubscriptionPlansActivityProps) => {
  const { promoCodeQueryString } = useWizardParams();
  const {
    create: createSubscription,
    isMutating: isUpdatingSubscription,
    update: updateSubscription,
  } = useSubscriptionMe({
    enabled: false,
  });
  const subscription = useSubscription();
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { track } = useAnalytics();
  const { goToSettingsPage } = useRedirects();
  const navigate = useNavigate();
  const [planUpgradeProps, setPlanUpgradeProps] = useState<PlanUpgradeProps | null>(null);
  const [isCancelOrDowngradeModalOpen, setIsCancelOrDowngradeModalOpen] = useState<boolean>(false);
  const [isSwitchBillingCycleModalOpen, setIsSwitchBillingCycleModalOpen] = useState<boolean>(false);
  const [downgradeToPlanId, setDowngradeToPlanId] = useState<string>();
  const { isDowngradedPlan, isUpgradedPlan, currentPlan } = useSubscriptionPlan();
  const { isReactivationAllowed } = usePendingSubscription();
  const { isFiservPartner } = usePartnerGroups();
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);

  const [showReactivateModal, setShowReactivateModal] = useState<boolean>(false);
  useSetDocumentTitle(formatMessage('activities.subscription.plans.document.title'));
  const { getIsFreePlan } = usePlansTiers();
  const onNewUserFreePlanSubscribe = (freePlanId: string) => {
    void createSubscription({
      planId: freePlanId,
      numberOfSeats: 1,
      planCyclePeriod: 'monthly',
    })
      .then(() => {
        navigate(returnUrl || DEFAULT_DASHBOARD_ROUTE);
      })
      .catch(() => {
        toast({ type: 'error', title: formatMessage('activities.subscription.checkout.summary.toast.error') });
      });
  };

  const onSubscribe = ({ planId, billingCycle }: SubscribeToPlanType) => {
    if (getIsFreePlan(planId)) {
      onNewUserFreePlanSubscribe(planId);
    } else {
      navigate(
        {
          pathname: CHECKOUT_URL,
          search: `plan=${planId}`,
        },
        { state: { returnUrl, utm_promo: promoCodeQueryString, billingCycle } }
      );
    }
  };

  const onCancelOrDowngradePaidPlan = (planId: string) => {
    setDowngradeToPlanId(planId);
    setIsCancelOrDowngradeModalOpen(true);
  };

  const onUpgradeTier = ({ planId, billingCycle }: SubscribeToPlanType) => {
    setPlanUpgradeProps({
      planId,
      billingCycle,
    });
  };

  const openSwitchBillingCycleModal = () => {
    setIsSwitchBillingCycleModalOpen(true);
  };

  const onShowReactivationModal = () => {
    setShowReactivateModal(true);
  };

  const onReactivatePlan = () => {
    if (!subscription) {
      return;
    }
    void updateSubscription({ planId: subscription?.planId })
      .then(() => {
        toast({
          type: 'success',
          title: formatMessage('activities.subscription.reactivatePlan.successMessage'),
        });
      })
      .catch(() => {
        toast({
          type: 'error',
          title: formatMessage('activities.subscription.reactivatePlan.errorMessage'),
        });
      });
  };

  const onSelectPlan = ({ planId, billingCycle }: SubscribeToPlanType) => {
    setSelectedPlanId(planId);

    if (!isFiservPartner && (!subscription || getIsFreePlan(subscription.planId))) {
      return onSubscribe({ planId, billingCycle });
    }
    if (isDowngradedPlan(planId)) {
      if (isReactivationAllowed) {
        return onShowReactivationModal();
      }
      return onCancelOrDowngradePaidPlan(planId);
    }
    if (isReactivationAllowed && currentPlan?.id === planId) {
      return onReactivatePlan();
    }
    if (isUpgradedPlan(planId)) {
      if (isReactivationAllowed) {
        return onShowReactivationModal();
      }
      return onUpgradeTier({ planId, billingCycle });
    }
    openSwitchBillingCycleModal();

    setSelectedPlanId(null);
  };

  const onCloseCancelModal = () => setIsCancelOrDowngradeModalOpen(false);
  const onCloseSwitchModal = () => setIsSwitchBillingCycleModalOpen(false);

  const onSwitchBillingCycle = () => {
    const newCycle =
      subscription?.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
        ? SubscriptionBillingCycleEnum.Annual
        : SubscriptionBillingCycleEnum.Monthly;
    void updateSubscription({ planId: subscription?.planId, planCyclePeriod: newCycle })
      .then(() => {
        track('Organization', 'Status', {
          Intent: 'upgrade-plan',
        });
        goToSettingsPage();
        onCloseSwitchModal();
      })
      .catch(() => {
        toast({
          id: 'subscription-checkout-error',
          type: 'error',
          title: formatMessage('activities.subscription.checkout.summary.toast.error'),
        });
      });
  };
  return (
    <>
      <ReactivatePlanModal
        variant="before-scheduling"
        isOpen={showReactivateModal}
        onClose={() => setShowReactivateModal(false)}
      />
      <SubscriptionPlansScreen
        onSelectPlan={onSelectPlan}
        selectedPlanId={selectedPlanId}
        isUpdatingSubscription={isUpdatingSubscription}
        onShowReactivatePlanModal={() => setShowReactivateModal(true)}
        onBack={onBack}
      />

      {downgradeToPlanId && (
        <CancelOrDowngradeFlowActivity
          isOpen={isCancelOrDowngradeModalOpen}
          newPlanId={downgradeToPlanId}
          onClose={onCloseCancelModal}
        />
      )}

      {planUpgradeProps && (
        <SubscriptionUpgradeModalActivity
          isOpen={!!planUpgradeProps}
          onClose={() => setPlanUpgradeProps(null)}
          planUpgradeProps={planUpgradeProps}
        />
      )}
      {subscription && (
        <SwitchSubscriptionBillingCycleModalScreen
          isOpen={isSwitchBillingCycleModalOpen}
          onClose={onCloseSwitchModal}
          onChange={onSwitchBillingCycle}
          subscription={subscription}
          isSubscribing={isUpdatingSubscription}
        />
      )}
    </>
  );
};
