import { Logger } from '@melio/platform-logger';
import { findIndex } from 'lodash';
import { useCallback, useContext } from 'react';

import { Plan, Tier } from '../api';
import { SubscriptionContext } from '../context';
import { useIsSubscriptionsEnabled } from './useIsSubscriptionsEnabled';

const isMostPopularPlan = (plan: Plan) => plan.tier === Tier.MelioPlus;
const isFreePlan = (plan: Plan) => plan.cycles.monthly.basePrice === 0;
const isPaidPlan = (plan: Plan) => !isFreePlan(plan);

export const usePlansTiers = () => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { plans } = useContext(SubscriptionContext);

  if (!isSubscriptionsEnabled) {
    const error = 'platform-subscriptions feature flag must be on';
    Logger.log(error, 'error');
    throw new Error(error);
  }

  if (!plans) {
    const error = 'plans not found. useSubscriptionPlan must be used within a SubscriptionProvider';
    Logger.log(error, 'error');
    throw new Error(error);
  }

  const getPlan = useCallback((planId: string) => plans.find((plan) => plan.id === planId), [plans]);
  const getPlanTier = useCallback((planId: string) => findIndex(plans, (plan) => plan.id === planId) + 1, [plans]);

  const getIsFreePlan = useCallback(
    (planId: string) => {
      const plan = getPlan(planId);
      if (!plan) {
        Logger.log('plan not found', 'error');
        return false;
      }
      return isFreePlan(plan);
    },
    [getPlan]
  );

  const getIsMostPopularPlan = useCallback(
    (planId: string) => {
      const plan = getPlan(planId);
      if (!plan) {
        Logger.log('plan not found', 'error');
        return false;
      }
      return isMostPopularPlan(plan);
    },
    [getPlan]
  );

  const getNextPlanWithUnlimitedSeats = useCallback(
    (planId: string) => {
      const currentPlanTier = getPlanTier(planId);
      return plans.find((plan) => getPlanTier(plan.id) > currentPlanTier && plan.cycles.monthly.unitPrice);
    },
    [plans, getPlanTier]
  );

  const firstPaidPlan = plans.find(isPaidPlan);
  const freePlan = plans.find(isFreePlan);

  return {
    plans,
    freePlan,
    firstPaidPlan,
    getIsFreePlan,
    getPlan,
    getPlanTier,
    getIsMostPopularPlan,
    getNextPlanWithUnlimitedSeats,
  };
};
