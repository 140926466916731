import { usePlanInfo } from './usePlanInfo';
import { useSubscription } from './useSubscription';

export const usePendingSubscription = () => {
  const subscription = useSubscription();
  const nextSubscription = subscription?.nextSubscription;
  const isFreeTrial = subscription?.isFreeTrial;

  //TODO: Make sure the API always returns a nextSubscription object
  const nextSubscriptionPlanId = nextSubscription?.planId;
  const pendingPlanInfo = usePlanInfo(nextSubscriptionPlanId);

  const allowReactivation =
    (!isFreeTrial && nextSubscriptionPlanId) ||
    (isFreeTrial && !nextSubscriptionPlanId) ||
    (isFreeTrial && nextSubscriptionPlanId !== subscription.planId); // TODO: or different in cycle

  return {
    pendingPlanInfo,
    nextSubscriptionPlanId,
    isReactivationAllowed: allowReactivation,
  };
};
