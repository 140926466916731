import { PartnerGroupEnum } from '@melio/partner-tools';
import { useMelioIntl } from '@melio/platform-i18n';

import { Feature } from '../../../../api';
import { usePartnerGroup } from '../../../../hooks';
import {
  currentPlanContentPerFeature,
  currentPlanContentPerFeatureFiserv,
  upgradedPlanContentPerFeature,
  upgradedPlanContentPerFeatureFiserv,
} from './content';

type UseContextualMessagesProps = {
  featureName: Feature;
  upgradePlanName: string;
};

type FeatureUpsellContext = {
  title: string;
  description: string;
  plans: {
    current: {
      capabilities: string[];
      description: string;
      actionButtonLabel?: string;
    };
    upgrade: {
      capabilities: string[];
      description: string;
      actionButtonLabel: string;
    };
  };
};

// TODO: part of the texts here should be dynamic - the eligibility for trial and the next plan (plus or pro). for now, all of them are static https://meliorisk.atlassian.net/browse/ME-54165
export const useContextualMessages = ({
  featureName,
  upgradePlanName,
}: UseContextualMessagesProps): FeatureUpsellContext => {
  const { formatMessage } = useMelioIntl();

  const partnerGroup = usePartnerGroup();

  const current =
    partnerGroup === PartnerGroupEnum.FISERV ? currentPlanContentPerFeatureFiserv : currentPlanContentPerFeature;
  const upgraded =
    partnerGroup === PartnerGroupEnum.FISERV ? upgradedPlanContentPerFeatureFiserv : upgradedPlanContentPerFeature;

  const featureCurrentPlanContent = current[featureName];
  const currentPlanTexts = {
    description: formatMessage(`activities.subscription.contextualPlans.${featureName}.current.description`),
    actionButtonLabel:
      'actionButton' in featureCurrentPlanContent ? formatMessage(featureCurrentPlanContent.actionButton) : undefined,
    capabilities: featureCurrentPlanContent.capabilities.map((x) => formatMessage(x)),
  };

  const featureUpgradedPlanContent = upgraded[featureName];
  const upgradePlanTexts = {
    description: formatMessage(`activities.subscription.contextualPlans.${featureName}.upgrade.description`),
    // TODO: https://meliorisk.atlassian.net/browse/ME-54886 text should be different if eligible for trial
    actionButtonLabel: formatMessage('activities.subscription.contextualPlans.action.upgradeTo', {
      planName: upgradePlanName,
    }),
    capabilities: featureUpgradedPlanContent.capabilities.map((x) => formatMessage(x)),
  };

  return {
    title: formatMessage(`activities.subscription.contextualPlans.${featureName}.title`, { upgradePlanName }),
    description: formatMessage(`activities.subscription.contextualPlans.${featureName}.description`),
    plans: {
      current: currentPlanTexts,
      upgrade: upgradePlanTexts,
    },
  };
};
