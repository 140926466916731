import { Badge, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type PlanCardHeaderProps = {
  title: string;
  description: string;
  isCurrent: boolean;
};

export const ContextualPlanCardHeader = ({ title, description, isCurrent }: PlanCardHeaderProps) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Container>
      <Group alignItems="center" spacing="s">
        <Text textStyle="heading2Semi">{title}</Text>
        {isCurrent && (
          <Badge
            label={formatMessage('activities.subscription.contextualPlans.card.isCurrent')}
            status="neutral"
            type="secondary"
          />
        )}
      </Group>
      <Text textStyle="body2" color="neutral.darker">
        {description}
      </Text>
    </Container>
  );
};
