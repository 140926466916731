/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { merge } from 'lodash';
import { useRecoilValue } from 'recoil';
import { PartnerGroupEnum, partnerGroupParser } from '@melio/partner-tools';
import { PartnerName } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { usBankTheme } from '@/cl/theme/partners';
import { useAnonymousAppFeature } from '@/hooks/featureFlags';
import { PartnersConfiguration } from '@/partnersConfig';
import { PartnerConfig, TokenProvider } from '@/partnersConfig.types';
import { selectedPartner } from '@/store/app/app.model';
import { getOverrideParams } from '@/utils/getOverrideParams.utils';

export const usePartnerConfig = (partner?: PartnerName) => {
  const [isUsHolidaysChecksOn] = useFeature(FeatureFlags.USHolidaysChecks, false);
  const [vaultingTokenProvider] = useAnonymousAppFeature<TokenProvider>(FeatureFlags.VaultingTokenProvider, 'tbt', {
    shouldTrack: false,
  });

  const selectedPartnerRecoilValue = useRecoilValue(selectedPartner);

  const currentPartner = partner || selectedPartnerRecoilValue;
  if (!currentPartner) {
    throw Error('Current partner could not be detected');
  }

  const urlParams = new URLSearchParams(window.location.search);

  const { partnerGroup } = partnerGroupParser(currentPartner);
  const demoOverride = getOverrideParams(currentPartner);

  const partnerConfig = React.useMemo<PartnerConfig>(() => {
    const overrideTheme = urlParams.get('theme');
    const currentPartnerConfig = PartnersConfiguration[currentPartner!];
    let overridePartnerConfig = null;
    if (partnerGroup === PartnerGroupEnum.FISERV && overrideTheme == 'us-bank') {
      overridePartnerConfig = usBankTheme;
    }

    const overrides: RecursivePartial<PartnerConfig> = {
      ...demoOverride,
      config: {
        settings: {
          ...(vaultingTokenProvider != null ? { tokenProvider: vaultingTokenProvider } : {}),
          eoyCheck: {
            enabled: isUsHolidaysChecksOn,
          },
          partnerDisplayName: currentPartnerConfig.displayName,
        },
      },
      ...(overridePartnerConfig ? { theme: overridePartnerConfig } : {}),
    };
    return { ...merge(currentPartnerConfig, overrides) };
  }, [currentPartner, vaultingTokenProvider, isUsHolidaysChecksOn]);

  return { partnerName: currentPartner!, partnerGroup, partnerConfig };
};
