import { Card, ExternalLayout, Group, Text } from '@melio/penny';
import { BankAccountDeliveryMethod, PaperCheckDeliveryMethod, Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import {
  DeliveryDateListItemWidget,
  DeliveryMethodListItemWidget,
  PayorPendingPaymentBanner,
  PoweredByMelioFooterWidget,
} from '@melio/vex-widgets';

export type ActionBlockedPaymentDeliveredScreenProps = {
  accountName: string;
  deliveryMethod: BankAccountDeliveryMethod | PaperCheckDeliveryMethod;
  estimatedDelivery: Payment['estimatedDelivery'];
  maxEstimatedDelivery: Payment['maxEstimatedDelivery'];
  paymentAmount: number;
};

export const ActionBlockedPaymentDeliveredScreen = forwardRef<ActionBlockedPaymentDeliveredScreenProps, 'div'>(
  (
    { accountName, deliveryMethod, estimatedDelivery, maxEstimatedDelivery, paymentAmount, children, ...props },
    ref
  ) => {
    const { formatMessage, formatCurrency } = useMelioIntl();
    const content = (
      <Card paddingX="xl" paddingY="l">
        <Group variant="vertical">
          <PayorPendingPaymentBanner
            variant="success"
            title={formatMessage(
              `activities.pushToDebit.screens.actionBlockedPaymentDelivered.${
                deliveryMethod.type === 'bank-account' ? 'bank' : 'check'
              }.title`
            )}
            description={formatMessage(
              `activities.pushToDebit.screens.actionBlockedPaymentDelivered.${
                deliveryMethod.type === 'bank-account' ? 'bank' : 'check'
              }.description`,
              {
                accountName,
                amount: formatCurrency(paymentAmount),
              }
            )}
          />
          <Group variant="vertical" spacing="s">
            <Text textStyle="body4SemiUpper" color="neutral.darkest">
              {formatMessage(
                'activities.pushToDebit.screens.actionBlockedPaymentDelivered.vendorReceivePaymentSection.title'
              )}
            </Text>
            <Group variant="vertical" spacing="m" hasDivider>
              <DeliveryMethodListItemWidget deliveryMethod={deliveryMethod} />
              <DeliveryDateListItemWidget
                deliveryMethodType={deliveryMethod.type}
                estimatedDelivery={estimatedDelivery}
                maxEstimatedDelivery={maxEstimatedDelivery}
              />
            </Group>
          </Group>
        </Group>
      </Card>
    );

    const footer = <PoweredByMelioFooterWidget />;
    return (
      <ExternalLayout
        data-component="PushToDebitActivity.ActionBlockedPaymentDeliveredScreen"
        data-testid="push-to-debit-activity-action-blocked-payment-delivered-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

ActionBlockedPaymentDeliveredScreen.displayName = 'PushToDebitActivity.ActionBlockedPaymentDeliveredScreen';
