import { Card, Group, IconButton, Text } from '@melio/penny';

type ReviewDetailsSectionCardProps = {
  content: { title: string; description: string }[];
  onEdit: () => void;
};

export const FormDetailsReviewCard: React.FC<ReviewDetailsSectionCardProps> = ({ content, onEdit, ...props }) => (
  <Card variant="flat" {...props}>
    <Group justifyContent="space-between" width="full">
      <Group variant="vertical" spacing="l">
        {content.map(({ title, description }) => (
          <Group key={title} variant="vertical" spacing="xxs">
            <Text textStyle="body4Semi" color="neutral.darker">
              {title}
            </Text>
            <Text textStyle="body2" color="neutral.darker">
              {description}
            </Text>
          </Group>
        ))}
      </Group>
      <IconButton onClick={onEdit} icon="edit" data-testid="edit-button" />
    </Group>
  </Card>
);

FormDetailsReviewCard.displayName = 'FormDetailsReviewCard';
