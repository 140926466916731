import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useCallback, useContext } from 'react';

import { SubscriptionContext } from '../context';
import { useIsSubscriptionsEnabled } from './useIsSubscriptionsEnabled';
import { usePlansTiers } from './usePlansTiers';
import { useSubscription } from './useSubscription';

export const useSubscriptionPlan = () => {
  const { getPlanTier } = usePlansTiers();
  const { formatDate } = useMelioIntl();

  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  const { plans } = useContext(SubscriptionContext);

  if (!isSubscriptionsEnabled) {
    const error = 'platform-subscriptions feature flag must be on';
    Logger.log(error, 'error');
    throw new Error(error);
  }

  if (!plans) {
    const error = 'plans not found. useSubscriptionPlan must be used within a SubscriptionProvider';
    Logger.log(error, 'error');
    throw new Error(error);
  }

  const subscription = useSubscription();

  const isUpgradedPlan = useCallback(
    (planId: string) => subscription && getPlanTier(planId) > getPlanTier(subscription.planId),
    [subscription, getPlanTier]
  );
  const isDowngradedPlan = useCallback(
    (planId: string) => subscription && getPlanTier(planId) < getPlanTier(subscription.planId),
    [subscription, getPlanTier]
  );

  const currentPlan = subscription ? plans.find((plan) => plan.id === subscription.planId) : null;

  const getIsCurrentPlan = useCallback(
    (planId: string) => !!subscription && planId === subscription.planId,
    [subscription]
  );

  // TODO: change to subscription object once api is ready
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reactivatePlan = {
    enabled: false,
    expiredDate: formatDate(subscription?.endDate, { dateStyle: 'medium' }),
    originalName: 'Core',
    originalPlanId: plans?.[1]?.id,
  };

  const isReactivatedPlan = useCallback(
    (planId?: string) =>
      reactivatePlan.enabled && currentPlan?.id !== planId && reactivatePlan.originalPlanId === planId,
    [currentPlan, reactivatePlan]
  );

  if (subscription && !currentPlan) {
    const error = 'currentPlan not found';
    Logger.log(error, 'error');
    throw new Error(error);
  }

  return {
    getIsCurrentPlan,
    currentPlan,
    reactivatePlan,
    isUpgradedPlan,
    isDowngradedPlan,
    isReactivatedPlan,
  };
};
