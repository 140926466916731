import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { AccountingPlatformsApiClient } from '@melio/platform-api-axios-client';

export type UseAccountingPlatformsProps = UseCollectionApiProps<
  typeof AccountingPlatformsApiClient.getAccountingPlatforms
>;

export const useAccountingPlatforms = (props?: UseAccountingPlatformsProps) => {
  const query = useCollectionApi<typeof AccountingPlatformsApiClient.getAccountingPlatforms>({
    ...props,
    queryKey: ['AccountingPlatformsApi', 'getAccountingPlatforms'],
    queryFn: () => AccountingPlatformsApiClient.getAccountingPlatforms(),
  });

  const activeAccountingPlatform = query.data?.find((platform) => platform.connectionStatus === 'connected');

  return {
    ...query,
    activeAccountingPlatform,
    hasAccountingPlatform: !!activeAccountingPlatform,
  };
};
