import { FailureStepItem } from '../types';

const getFailureGeustVendorSteps = ({
  paymentCreatedAt,
  payorEmail,
}: {
  paymentCreatedAt: Date;
  payorEmail: string;
}): FailureStepItem => [
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
      date: paymentCreatedAt,
    },
  },
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed.description',
      payorEmail,
    },
  },
];

const getFailureGetPaidVendorSteps = ({
  paymentCreatedAt,
  howContactToSupportArticleUrl,
}: {
  paymentCreatedAt: Date;
  howContactToSupportArticleUrl: string;
}): FailureStepItem => [
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.scheduled' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.description.date',
      date: paymentCreatedAt,
    },
  },
  {
    title: { messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed' },
    description: {
      messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed.getPaid.description',
      link: {
        messageKey: 'vex.activities.vendorPaymentTracking.tracker.failed.getPaid.contactSupportGuide.link',
        href: howContactToSupportArticleUrl,
      },
    },
  },
];
export const getFailureSteps = ({
  paymentCreatedAt,
  payorEmail,
  isGetPaidVendor,
  howContactToSupportArticleUrl,
}: {
  paymentCreatedAt: Date;
  payorEmail: string;
  isGetPaidVendor: boolean;
  howContactToSupportArticleUrl: string;
}): FailureStepItem => {
  if (isGetPaidVendor) {
    return getFailureGetPaidVendorSteps({ paymentCreatedAt, howContactToSupportArticleUrl });
  } else {
    return getFailureGeustVendorSteps({ paymentCreatedAt, payorEmail });
  }
};
