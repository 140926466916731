import { datadogRum } from '@datadog/browser-rum';
import React, { ComponentProps } from 'react';
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary';

export function ErrorBoundary({ onError: _onError, ...props }: ComponentProps<typeof _ErrorBoundary>) {
  const onError: ComponentProps<typeof _ErrorBoundary>['onError'] = (error, info) => {
    const componentError = new Error(error.message);
    componentError.name = `React ErrorBoundary ${componentError.name}`;
    componentError.stack = info.componentStack;

    error.cause = componentError;

    datadogRum.addError(error, {
      is_crash: true,
    });

    _onError?.(error, info);
  };

  return <_ErrorBoundary {...props} onError={onError} />;
}
