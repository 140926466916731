import {
  DeliveryMethod,
  PaymentDeliveryPreferenceEnum,
  TrackerTimelineStatusEnum,
  VendorManagedBy,
} from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';

import { TrackerStepData } from '../types';
import { getPaymentTrackerStepsTexts } from '../utils';

export const usePaymentTrackerSteps = ({
  deliveryMethod,
  deliveryPreference,
  paymentCreatedAt,
  paymentScheduledDate,
  paymentDeliveryEta,
  trackerTimelineStatus,
  vendorManagedBy,
  payorEmail,
}: {
  deliveryMethod: DeliveryMethod | undefined;
  deliveryPreference: PaymentDeliveryPreferenceEnum | null | undefined;
  paymentCreatedAt: Date | undefined;
  paymentScheduledDate: Date | undefined;
  paymentDeliveryEta: Date | null | undefined;
  trackerTimelineStatus: TrackerTimelineStatusEnum | undefined;
  vendorManagedBy: VendorManagedBy | null | undefined;
  payorEmail: string | undefined;
}): TrackerStepData[] | undefined => {
  const { howContactToSupportArticleUrl } = useConfig().settings;

  const isMissingRequiredData =
    !deliveryMethod ||
    !paymentCreatedAt ||
    !paymentScheduledDate ||
    !paymentDeliveryEta ||
    !trackerTimelineStatus ||
    !payorEmail ||
    deliveryPreference === undefined ||
    vendorManagedBy === undefined;

  if (isMissingRequiredData) {
    return undefined;
  }

  const trackerStepsTexts = getPaymentTrackerStepsTexts({
    deliveryMethod,
    deliveryPreference,
    paymentCreatedAt,
    paymentScheduledDate,
    paymentDeliveryEta,
    trackerTimelineStatus,
    payorEmail,
    vendorManagedBy,
    howContactToSupportArticleUrl,
  });

  if (!trackerStepsTexts) {
    return undefined;
  }

  const trackerSteps: TrackerStepData[] = trackerStepsTexts.map(({ title, description }) => {
    const { messageKey: titleMessageKey } = title;
    const { messageKey: descriptionMessageKey, date: descriptionDate, link: descriptionLink, payorEmail } = description;

    return {
      title: { messageKey: titleMessageKey },
      description: {
        messageKey: descriptionMessageKey,
        date: descriptionDate,
        payorEmail,
        link: descriptionLink,
      },
    };
  });

  return trackerSteps;
};
