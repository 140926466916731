import { Button, Form, Group, SectionBanner, SectionBannerProps, Text, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { AddBankAccountCardFormModel, FormWidgetProps } from '../../types';
import {
  ACCOUNT_NUMBER_MAX_LENGTH,
  ACCOUNT_NUMBER_MIN_LENGTH,
  ROUTING_NUMBER_LENGTH,
} from '../../utils/bank-account-validations/bank-account-validations.utils';
import { ExtendedStatusIndicatorWidget } from '../ExtendedStatusIndicator';
import { useAddBankAccountValidationSchema } from './hooks/use-add-bank-account-validation-schema';

export type AddBankAccountFormCardWidgetProps = FormWidgetProps<AddBankAccountCardFormModel> & {
  isDisabled: boolean;
  setDirtyStatus: (isDirty: boolean, cb: () => void | Promise<void>) => void;
  onCancel?: () => void;
  existingBankAccountDetails?: {
    isPlaidAccount: boolean;
    accountNumber: string;
    routingNumber?: string;
  };
  prePopulatedBankDetails?: {
    accountNumber: string;
    routingNumber?: string;
  };
  onValidationError?: (isRequiredError: boolean) => void;
};

export const AddBankAccountFormCardWidget = forwardRef<AddBankAccountFormCardWidgetProps, 'form'>(
  (
    {
      onSubmit,
      onSubmissionStateChange,
      isSaving,
      isDisabled,
      prePopulatedBankDetails,
      setDirtyStatus,
      onValidationError,
      onCancel,
      existingBankAccountDetails,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const [isCreateBankAccountError, setIsCreateBankAccountError] = useState(false);
    const defaultValues = prePopulatedBankDetails
      ? { accountNumber: prePopulatedBankDetails.accountNumber, routingNumber: prePopulatedBankDetails.routingNumber }
      : undefined;

    const onAddBankAccountFormSubmit = async (data: AddBankAccountCardFormModel) => {
      try {
        setIsCreateBankAccountError(false);
        await onSubmit(data);
      } catch (error) {
        setIsCreateBankAccountError(true);
      }
    };

    const {
      formProps,
      registerField,
      trigger,
      submitButtonProps,
      formState: { isDirty, isValid, errors },
      watch: watchBankAccountFormDetails,
    } = useMelioForm<AddBankAccountCardFormModel>({
      onSubmit: onAddBankAccountFormSubmit,
      defaultValues,
      isSaving,
      schema: useAddBankAccountValidationSchema(),
      onSubmissionStateChange,
    });

    useEffect(() => {
      const { onClick } = submitButtonProps;
      if (setDirtyStatus) {
        setDirtyStatus(isDirty && !isValid, onClick);
      }
    }, [isDirty, isValid, submitButtonProps, setDirtyStatus]);

    useEffect(() => {
      if (onValidationError && !isEmpty(errors)) {
        const isRequiredError = !!Object.values(errors).some((error) => error?.type === 'required');
        onValidationError(isRequiredError);
      }
    }, [errors, onValidationError]);

    const { accountNumber, routingNumber, accountNumberVerification, routingNumberVerification } =
      watchBankAccountFormDetails();

    const isAccountNumberVerifiedSuccessfully =
      accountNumberVerification && accountNumberVerification === accountNumber;

    const isRoutingNumberVerifiedSuccessfully =
      routingNumberVerification && routingNumberVerification === routingNumber;

    const bannerProps: SectionBannerProps | undefined = isCreateBankAccountError
      ? {
          variant: 'critical',
          description: formatMessage('vex.widgets.addBankAccountFormCard.errorBanner.description'),
        }
      : existingBankAccountDetails
      ? {
          variant: 'informative',
          title: formatMessage('vex.widgets.addBankAccountFormCard.infoBanner.title'),
          description: (
            <Text textStyle="body4">
              {existingBankAccountDetails.isPlaidAccount
                ? formatMessage('vex.widgets.addBankAccountFormCard.infoBanner.plaidDescription')
                : formatMessage('vex.widgets.addBankAccountFormCard.infoBanner.bankDetailsDescription', {
                    bankAccountLast4Digits: existingBankAccountDetails.accountNumber.slice(-4),
                  })}
            </Text>
          ),
        }
      : undefined;

    useEffect(() => {
      if (trigger && routingNumber && routingNumberVerification) {
        void trigger('routingNumberVerification');
      }
    }, [trigger, routingNumber, routingNumberVerification]);

    return (
      <Group spacing="l" variant="vertical" width="full">
        {bannerProps && <SectionBanner data-testid="add-bank-account-form-card-section-banner" {...bannerProps} />}
        <Form data-component="AddBankAccountFormCardWidget" {...props} {...formProps} ref={ref} columns={2}>
          <Form.TextField
            value={routingNumber}
            labelProps={{ label: formatMessage('vex.widgets.addBankAccountFormCard.fields.routingNumber.label') }}
            placeholder={formatMessage('vex.widgets.addBankAccountFormCard.fields.routingNumber.placeholder', {
              length: ROUTING_NUMBER_LENGTH,
            })}
            {...registerField('routingNumber')}
          />
          <Form.SecuredTextField
            labelProps={{ label: formatMessage('vex.widgets.addBankAccountFormCard.fields.accountNumber.label') }}
            placeholder={formatMessage('vex.widgets.addBankAccountFormCard.fields.accountNumber.placeholder', {
              minLength: ACCOUNT_NUMBER_MIN_LENGTH,
              maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
            })}
            {...registerField('accountNumber')}
          />
          <Group variant="vertical" spacing="xxs">
            <Form.TextField
              type="number"
              labelProps={{
                label: formatMessage('vex.widgets.addBankAccountFormCard.fields.routingNumberVerification.label'),
              }}
              placeholder={formatMessage('vex.widgets.addBankAccountFormCard.fields.routingNumber.placeholder', {
                length: ROUTING_NUMBER_LENGTH,
              })}
              {...registerField('routingNumberVerification')}
            />
            {isRoutingNumberVerifiedSuccessfully && (
              <ExtendedStatusIndicatorWidget
                label={formatMessage('vex.widgets.addBankAccountFormCard.successBandDetailsVerificationLabel')}
                status="success"
              />
            )}
          </Group>
          <Group variant="vertical" spacing="xxs">
            <Form.SecuredTextField
              type="number"
              labelProps={{
                label: formatMessage('vex.widgets.addBankAccountFormCard.fields.accountNumberVerification.label'),
              }}
              placeholder={formatMessage(
                'vex.widgets.addBankAccountFormCard.fields.accountNumberVerification.placeholder',
                {
                  minLength: ACCOUNT_NUMBER_MIN_LENGTH,
                  maxLength: ACCOUNT_NUMBER_MAX_LENGTH,
                }
              )}
              {...registerField('accountNumberVerification')}
            />
            {isAccountNumberVerifiedSuccessfully && (
              <ExtendedStatusIndicatorWidget
                label={formatMessage('vex.widgets.addBankAccountFormCard.successBandDetailsVerificationLabel')}
                status="success"
              />
            )}
          </Group>
        </Form>
        <Group justifyContent="flex-end">
          {onCancel && (
            <Button
              data-testid="cancel-button"
              label={formatMessage('vex.widgets.addBankAccountFormCard.cancelButtonLabel')}
              variant="tertiary"
              onClick={onCancel}
            />
          )}
          <Button
            data-testid="bank-account-submit-button"
            label={formatMessage('vex.widgets.addBankAccountFormCard.submitButtonLabel')}
            {...submitButtonProps}
          />
        </Group>
      </Group>
    );
  }
);

AddBankAccountFormCardWidget.displayName = 'AddBankAccountFormCardWidget';
