import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Plan, useSubscriptionMe } from '../../../api';
import { usePartnerGroups, usePlanInfo, useRedirects, useSubscription } from '../../../hooks';

type UseUpgradeSubscriptionProps = {
  planId: Plan['id'];
  returnUrl?: string;
  onClose: () => void;
  onDismiss?: () => void;
};

export const useUpgradeSubscription = ({ planId, returnUrl, onClose, onDismiss }: UseUpgradeSubscriptionProps) => {
  const { track } = useAnalytics();
  const subscription = useSubscription();
  const { isFiservPartner } = usePartnerGroups();
  const navigate = useNavigate();
  const { goToSuccessPage, goToSettingsPage } = useRedirects();
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();
  const { planName } = usePlanInfo(planId);
  const [upgradeError, setUpgradeError] = useState(false);

  const { update: updateSubscription, isMutating: isUpgradingSubscription } = useSubscriptionMe({ enabled: false });

  const navigateOnSuccess = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }

    isFiservPartner ? goToSettingsPage() : goToSuccessPage();
  };

  const successHandler = () => {
    navigateOnSuccess();

    if (isFiservPartner) {
      toast({
        type: 'success',
        title: formatMessage('activities.subscription.upgradeModal.toast.success', {
          planName,
        }),
        id: 'subscription-upgrade-success',
      });
    }

    onClose();
  };

  const errorHandler = () => {
    setUpgradeError(true);
  };

  const upgradeSubscription = (fundingSourceId?: FundingSource['id']) => {
    track('Organization', 'Click', {
      Intent: 'confirm-upgrade',
      Cta: 'confirm-upgrade',
      fundingSourceField: fundingSourceId,
    });

    void updateSubscription({
      planId,
      ...(fundingSourceId !== subscription?.fundingSourceId && { fundingSourceId }),
    })
      .then(() => {
        track('Organization', 'Status', {
          Intent: 'upgrade-plan',
        });
        successHandler();
      })
      .catch(() => {
        errorHandler();
      });
  };

  const dismiss = (closeType: string) => {
    onDismiss?.();
    onClose();
    track('Organization', 'Click', {
      Intent: closeType,
      Cta: closeType,
    });
  };

  return { upgradeError, upgradeSubscription, isUpgradingSubscription, dismiss };
};
