import { useDimensions } from '@chakra-ui/react';
import { Badge, Container, Grid, GridItem, Group, LoadingContainer, SectionBanner, Switch, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useRef, useState } from 'react';

import { Plan, SubscriptionBillingCycleEnum } from '../../../../api';
import {
  useCyclesPricing,
  useFreeTrialEligibility,
  usePendingSubscription,
  usePlanInfo,
  usePlansTiers,
  useSubscription,
} from '../../../../hooks';
import { PlanCard } from '../components/PlanCard';
import { PlansSelectionHeader } from '../components/PlansSelectionHeader';
import { SubscribeToPlanType } from '../types';

export type SubscriptionPlansScreenProps = {
  isUpdatingSubscription?: boolean;
  selectedPlanId: string | null;
  onShowReactivatePlanModal: () => void;
  onSelectPlan: ({ planId, billingCycle }: SubscribeToPlanType) => void;
  onBack?: () => void;
};
export const SubscriptionPlansScreen = withAnalyticsContext<SubscriptionPlansScreenProps>(
  ({
    isUpdatingSubscription,
    selectedPlanId,
    onSelectPlan,
    setAnalyticsProperties,
    onBack,
    onShowReactivatePlanModal: onShowReactivateModal,
    ...rest
  }) => {
    const subscription = useSubscription();
    const { plans } = usePlansTiers();
    const containerRef = useRef<HTMLInputElement>(null);
    const dimensions = useDimensions(containerRef, true);
    const { formatPercents, formatMessage, formatDate } = useMelioIntl();
    const { getAnnualPlanSavingPercents } = useCyclesPricing();
    const { isReactivationAllowed, pendingPlanInfo } = usePendingSubscription();
    const currentPlanInfo = usePlanInfo(subscription?.planId);
    setAnalyticsProperties({
      PageName: 'plan-selection',
      Flow: 'subscription',
      Intent: 'choose-plan',
      PlanId: subscription?.planId,
      CurrentPlan: currentPlanInfo.planName,
      // TODO: add currently unavailable data
      /**
       * Discount
       * DiscountType
       * DaysSinceRegistration
       * CampaignId
       * Variant ?
       * CompanyType
       * IsMultiOrg
       */
    });
    useAnalyticsView('Organization');
    const { track } = useAnalytics();

    const [selectedBillingCycle, setSelectedBillingCycle] = useState(
      subscription?.planCyclePeriod ?? SubscriptionBillingCycleEnum.Monthly
    );
    const [isReactivateBannerClosed, setIsReactivateBannerClosed] = useState(false);

    const handleSelectPlan = (planId: Plan['id']) => {
      track('Organization', 'Click', {
        Intent: 'subscribe',
        Cta: 'subscribe',
        PlanChosenId: planId,
      });
      onSelectPlan({ planId, billingCycle: selectedBillingCycle });
    };

    const toggleBillingCycle = () => {
      if (isReactivationAllowed) {
        onShowReactivateModal();
        return;
      }
      setSelectedBillingCycle(
        selectedBillingCycle === SubscriptionBillingCycleEnum.Monthly
          ? SubscriptionBillingCycleEnum.Annual
          : SubscriptionBillingCycleEnum.Monthly
      );
    };

    const { firstPaidPlan } = usePlansTiers();
    const hasBothBillingCycles = firstPaidPlan?.cycles.annual && firstPaidPlan?.cycles.monthly;
    const isCycleEditable =
      hasBothBillingCycles && (!subscription || subscription.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly);

    const annualPlanSavingPercents = getAnnualPlanSavingPercents();

    const { isEligibleForFreeTrial, isFetching: isFetchingFreeTrialEligibilityData } = useFreeTrialEligibility();

    const baseWidth = 1200;
    const minSizeColumn = 225;
    const plansCount = plans.length;
    const maxColumnSize = baseWidth / plansCount;
    const minContainerSize = plansCount * minSizeColumn;
    const containerWidth = dimensions?.borderBox?.width;
    const shouldWrap = containerWidth && containerWidth < minContainerSize;
    const gridTemplateCol = shouldWrap ? '1fr' : `repeat(${plansCount}, minmax(0, ${maxColumnSize}px))`;
    return (
      <LoadingContainer isLoading={isFetchingFreeTrialEligibilityData} data-testid="subscription-plans-screen">
        <Container paddingX="xxs" paddingY="xxs">
          <Group spacing="none" justifyContent="center" variant="vertical" {...rest} ref={containerRef}>
            <PlansSelectionHeader
              onBack={onBack}
              isEligibleForFreeTrial={isEligibleForFreeTrial}
              gridTemplateCol={shouldWrap ? '1fr' : `repeat(2, minmax(0, ${(baseWidth + 8 * (plansCount - 1)) / 2}px))`}
            />

            <Grid
              justifyContent="center"
              gap="m"
              pt={isReactivationAllowed ? 's' : 'xl'}
              pb="xxxs"
              gridTemplateColumns={gridTemplateCol}
            >
              {!isReactivateBannerClosed && isReactivationAllowed && (
                <GridItem colSpan={shouldWrap ? 1 : plansCount}>
                  <Container paddingBottom="s" width="full">
                    <SectionBanner
                      showCloseIcon
                      onClose={() => setIsReactivateBannerClosed(true)}
                      title={formatMessage('activities.subscription.plans.selection.reactivateBanner.title')}
                      description={formatMessage(
                        'activities.subscription.plans.selection.reactivateBanner.description',
                        {
                          date: formatDate(subscription?.endDate, {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                          }),
                          nextPlanName: pendingPlanInfo.planName,
                          currentPlanName: currentPlanInfo.planName,
                        }
                      )}
                      data-role="alert"
                    />
                  </Container>
                </GridItem>
              )}

              {isCycleEditable && (
                <GridItem colSpan={plansCount}>
                  <Group spacing="xs" alignItems="center" width="full">
                    <Text>{formatMessage('activities.subscription.plans.selection.monthly')}</Text>
                    <Switch
                      data-testid="subscription-plans-cycle-switch"
                      size="small"
                      value={selectedBillingCycle === SubscriptionBillingCycleEnum.Annual}
                      onChange={toggleBillingCycle}
                    />
                    <Text>{formatMessage('activities.subscription.plans.selection.yearly')}</Text>
                    {annualPlanSavingPercents && (
                      <Badge
                        type="secondary"
                        label={formatMessage('activities.subscription.checkout.billingCycle.annualSavingBadge', {
                          savingPercent: formatPercents(annualPlanSavingPercents, { divide: true }),
                        })}
                        status="success"
                      />
                    )}
                  </Group>
                </GridItem>
              )}
              {plans.map((plan) => {
                const currentBillingCycle = subscription?.planCyclePeriod;
                const isSwitchToYearly =
                  selectedBillingCycle === SubscriptionBillingCycleEnum.Annual &&
                  currentBillingCycle === SubscriptionBillingCycleEnum.Monthly;

                return (
                  <PlanCard
                    key={plan.id}
                    plan={plan}
                    isSelected={selectedPlanId === plan.id}
                    onSelectPlan={handleSelectPlan}
                    selectedBillingCycle={selectedBillingCycle}
                    isSwitchingBillingCycle={isSwitchToYearly}
                    isEligibleForFreeTrial={isEligibleForFreeTrial}
                  />
                );
              })}
            </Grid>
          </Group>
        </Container>
      </LoadingContainer>
    );
  }
);
