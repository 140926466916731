import { Container, Group, Modal, Text, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { Subscription, SubscriptionBillingCycleEnum, useSubscriptionsPreview } from '../../../../api';
import { usePlanInfo, useSubscriptionPlan } from '../../../../hooks';

export type SwitchSubscriptionBillingCycleModalScreenProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onChange: VoidFunction;
  subscription: Subscription;
  isSubscribing: boolean;
};

export const SwitchSubscriptionBillingCycleModalScreen = ({
  isOpen,
  onClose,
  onChange,
  subscription,
  isSubscribing,
}: SwitchSubscriptionBillingCycleModalScreenProps) => {
  const { formatCurrency, formatMessage, formatDate } = useMelioIntl();

  const newCycle =
    subscription.planCyclePeriod === SubscriptionBillingCycleEnum.Monthly
      ? SubscriptionBillingCycleEnum.Annual
      : SubscriptionBillingCycleEnum.Monthly;

  const { data: preview, isFetching: isPreviewFetching } = useSubscriptionsPreview({
    enabled: isOpen,
    params: {
      planCycle: newCycle,
      planId: subscription.planId,
      unitQuantity: subscription.measurementInfo?.quantity || 0,
      fundingSourceId: subscription.fundingSourceId,
    },
  });

  const { planName } = usePlanInfo(subscription.planId);
  const { currentPlan } = useSubscriptionPlan();

  if ((!isPreviewFetching && !preview) || !currentPlan) {
    return null;
  }

  const formatDateRange = (date: string) => formatDate(date, { month: 'long', day: 'numeric', year: 'numeric' });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isPreviewFetching || isSubscribing}
      header={formatMessage('activities.settings.subscriptionPlans.switchCycle.changeBillingCycle')}
      primaryButton={{
        label: formatMessage('activities.settings.subscriptionPlans.switchCycle.confirmButton'),
        onClick: onChange,
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.settings.subscriptionPlans.switchCycle.cancelButton'),
        onClick: onClose,
        variant: 'secondary',
      }}
      data-testid="switch-subscription-to-annual-modal"
    >
      {preview && (
        <Container alignItems="center" width="full" justifyContent="space-between">
          <Group spacing="m" variant="vertical" width="full">
            <Group spacing="xxxs" variant="vertical">
              <Group width="full" justifyContent="space-between">
                <Text data-testid="switch-billing-cycle-description">
                  {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.description`)}
                </Text>
              </Group>
            </Group>

            <Group spacing="s" variant="vertical" hasDivider width="full">
              <Group spacing="xxs" variant="vertical" width="full">
                <Text data-testid="switch-billing-from-plan">
                  {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.fromPlan`, {
                    planName,
                  })}
                </Text>
                <Group width="full" justifyContent="space-between">
                  <Text color="neutral.darker" textStyle="body3">
                    {formatMessage('activities.settings.subscriptionPlans.switchCycle.basePrice')}
                  </Text>
                  <Text color="neutral.darker" textStyle="body3">
                    {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.basePriceFromPeriod`, {
                      price: formatCurrency(currentPlan.cycles[subscription.planCyclePeriod]?.basePrice || 0),
                    })}
                  </Text>
                </Group>
              </Group>

              <Group spacing="xxs" variant="vertical" width="full">
                <Text data-testid="switch-billing-to-plan">
                  {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.toPlan`, {
                    planName,
                  })}
                </Text>
                <Group width="full" justifyContent="space-between">
                  <Text color="neutral.darker" textStyle="body3">
                    {formatMessage('activities.settings.subscriptionPlans.switchCycle.basePrice')}
                  </Text>
                  <Text color="neutral.darker" textStyle="body3">
                    {formatMessage(`activities.settings.subscriptionPlans.switchTo.${newCycle}.basePriceToPeriod`, {
                      price: formatCurrency(preview.amountWithoutTax),
                    })}
                  </Text>
                </Group>
              </Group>

              <Group justifyContent="space-between" alignItems="flex-start">
                <Group spacing="s" variant="vertical">
                  <Text textStyle="body4" color="neutral.darker">
                    <Typography.ParagraphList
                      size="small"
                      list={[
                        {
                          content: formatMessage(
                            'activities.settings.subscriptionPlans.switchCycle.subscriptionEndInformation',
                            {
                              endDate: formatDateRange(preview.billingItems[0]?.serviceEndDate || ''),
                            }
                          ),
                        },
                        {
                          content: formatMessage(
                            'activities.settings.subscriptionPlans.switchCycle.noPromotionIncluded'
                          ),
                        },
                      ]}
                      type="unordered"
                    />
                  </Text>
                </Group>
              </Group>
            </Group>
          </Group>
        </Container>
      )}
    </Modal>
  );
};
