import { useMelioIntl } from '@melio/platform-i18n';

import {
  usePendingSubscription,
  usePlanInfo,
  usePlansTiers,
  useSubscription,
  useSubscriptionPlan,
} from '../../../../../hooks';

type UsePlanCardButtonLabelProps = {
  isSwitchingBillingCycle: boolean;
  planId: string;
  isEligibleForFreeTrial: boolean;
};

export const usePlanCardButtonLabel = ({
  planId,
  isSwitchingBillingCycle,
  isEligibleForFreeTrial,
}: UsePlanCardButtonLabelProps) => {
  const { formatMessage } = useMelioIntl();
  const { isDowngradedPlan, isUpgradedPlan } = useSubscriptionPlan();
  const subscription = useSubscription();
  const { isReactivationAllowed, nextSubscriptionPlanId: pendingPlanId } = usePendingSubscription();

  const { getIsCurrentPlan } = useSubscriptionPlan();
  const { getIsFreePlan } = usePlansTiers();

  const isCurrentPlan = getIsCurrentPlan(planId);
  const isFreePlan = getIsFreePlan(planId);

  const { planName } = usePlanInfo(planId);

  if (pendingPlanId === planId && !isCurrentPlan) {
    return formatMessage('activities.subscription.plans.card.upcomingPlan');
  }

  if (isEligibleForFreeTrial && !isFreePlan && !isCurrentPlan) {
    return formatMessage('activities.subscription.plans.card.choosePlanWithFreeTrial', { planName });
  }

  if (!subscription) {
    return isFreePlan
      ? formatMessage('activities.subscription.plans.card.startForFree')
      : formatMessage('activities.subscription.plans.card.choosePlan', { planName });
  }

  if (isDowngradedPlan(planId)) {
    return formatMessage('activities.subscription.plans.card.downgrade', { planName });
  }

  if (isUpgradedPlan(planId)) {
    return formatMessage('activities.subscription.plans.card.upgrade', { planName });
  }

  if (isCurrentPlan) {
    if (isReactivationAllowed) {
      return formatMessage('activities.subscription.plans.card.reactivatePlan');
    }
    if (isFreePlan || !isSwitchingBillingCycle) {
      return formatMessage('activities.subscription.plans.card.current');
    }
    return formatMessage('activities.subscription.plans.card.switchToYearly');
  }

  return formatMessage('activities.subscription.plans.card.choosePlan', { planName });
};
