import { Group, Icon, Modal, SectionBanner, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePlanInfo, usePlansFeaturesDifference } from '../../../../hooks';
import { PlanUpgradeProps } from '../types';
import { useUpgradeSubscription } from '../useUpgradeSubscription';

type SubscriptionUpgradeWithTrialModalScreenProps = {
  isOpen: boolean;
  onDismiss?: () => void;
  onClose: () => void;
  planUpgradeProps: PlanUpgradeProps;
};

export const SubscriptionUpgradeWithTrialModalScreen = ({
  isOpen,
  onClose,
  onDismiss,
  planUpgradeProps,
}: SubscriptionUpgradeWithTrialModalScreenProps) => {
  const { dismiss, upgradeError, isUpgradingSubscription, upgradeSubscription } = useUpgradeSubscription({
    ...planUpgradeProps,
    onClose,
    onDismiss,
  });
  const { formatMessage } = useMelioIntl();
  const { getUpgradedPlanFeaturesUnlocked } = usePlansFeaturesDifference();
  const { planId } = planUpgradeProps;

  const featuresUnlockedList = getUpgradedPlanFeaturesUnlocked(planId);
  const { planName } = usePlanInfo(planId);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dismiss('exit')}
      header={formatMessage('activities.subscription.upgradeModal.title', { planName })}
      primaryButton={{
        isLoading: isUpgradingSubscription,
        label: formatMessage('activities.subscription.upgradeModal.primaryButton'),
        onClick: () => upgradeSubscription(),
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('activities.subscription.upgradeModal.secondaryButton'),
        onClick: () => dismiss('cancel'),
        variant: 'tertiary',
      }}
      data-testid="subscription-upgrade-modal-activity"
    >
      <Group variant="vertical" spacing="m">
        {upgradeError && (
          <SectionBanner
            description={formatMessage('activities.subscription.upgradeModal.error')}
            variant="critical"
            data-testid="subscription-upgrade-modal-error"
            data-role="alert"
          />
        )}

        <Group variant="vertical" spacing="m">
          {formatMessage('activities.subscription.upgradeModal.continueYourFreeTrial', { planName })}
          <Group variant="vertical" spacing="xxs" as="ul">
            {featuresUnlockedList.map((feature) => (
              <Group key={feature} spacing="xs">
                <Text color="neutral.darkest">
                  <Icon type="checked-circle" color="inherit" size="small" aria-hidden="true" />
                </Text>

                <Text textStyle="body3" color="neutral.darkest">
                  {feature}
                </Text>
              </Group>
            ))}
          </Group>
        </Group>
      </Group>
    </Modal>
  );
};
