import { withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryMethod } from '@melio/platform-api';
import { useNavigate } from '@melio/platform-utils';
import { GuestLayout } from '@melio/vex-widgets';
import { Navigate } from 'react-router-dom';

import { ServerErrorScreen } from '../../shared/screens/server-error';
import { usePaymentUpgrade } from '../payment-upgrade/usePaymentUpgrade';
import { usePaymentTrackerSteps, useTrackPaymentAnalyticsData } from './hooks';
import { PaymentTrackingScreen } from './screens';

export const VendorPaymentTrackingActivity = withAnalyticsContext<{
  paymentId: string;
  isVendorPaymentTrackingFeatureOpen: boolean;
  isLoading: boolean;
  isUpsellOfferBannerEnabled: boolean;
}>(
  ({
    setAnalyticsProperties,
    paymentId,
    isVendorPaymentTrackingFeatureOpen,
    isUpsellOfferBannerEnabled,
    isLoading,
  }) => {
    const {
      getGuestPaymentError,
      payment: guestPaymentData,
      isPaymentUpgradeLoading: isLoadingPaymentRequests,
      paymentUpgradeOffers,
    } = usePaymentUpgrade({
      paymentId,
    });
    const trackerSteps = usePaymentTrackerSteps({
      deliveryMethod: guestPaymentData?.deliveryMethod as DeliveryMethod,
      deliveryPreference: guestPaymentData?.deliveryPreference,
      paymentCreatedAt: guestPaymentData?.createdAt,
      paymentDeliveryEta: guestPaymentData?.deliveryEta,
      paymentScheduledDate: guestPaymentData?.scheduledDate,
      trackerTimelineStatus: guestPaymentData?.trackerTimelineStatus,
      payorEmail: guestPaymentData?.creatorAccountEmail,
      vendorManagedBy: guestPaymentData?.vendor.managedBy,
    });
    const navigate = useNavigate();
    const trackerAnalyticsData = useTrackPaymentAnalyticsData({ guestPaymentData, paymentId, paymentUpgradeOffers });

    const handleOnClickUpsellOffer = () => {
      navigate(`/vex/payment-upgrade/${paymentId}/index`);
    };

    setAnalyticsProperties(trackerAnalyticsData);
    if (!isVendorPaymentTrackingFeatureOpen && !isLoading) {
      return <Navigate replace to="../../404" relative="path" />;
    }
    if (isLoading || isLoadingPaymentRequests) {
      return <GuestLayout isLoading />;
    }

    if (
      getGuestPaymentError ||
      !guestPaymentData ||
      !guestPaymentData.trackerTimelineStatus ||
      !guestPaymentData.deliveryEta ||
      !trackerSteps
    ) {
      return <ServerErrorScreen />;
    }

    return (
      <PaymentTrackingScreen
        payorOrganizationName={guestPaymentData.orgName}
        amount={guestPaymentData.amount}
        trackerStatus={guestPaymentData.trackerTimelineStatus}
        deliveryEta={guestPaymentData.deliveryEta}
        deliveryNachaNumber={guestPaymentData.deliveryNachaNumber}
        deliveryPreference={guestPaymentData.deliveryPreference}
        trackerSteps={trackerSteps}
        onClickUpsellOfferButton={handleOnClickUpsellOffer}
        paymentUpgradeOffers={paymentUpgradeOffers}
        isUpsellOfferBannerEnabled={isUpsellOfferBannerEnabled}
        deliveryMethodType={(guestPaymentData.deliveryMethod as DeliveryMethod).type}
      />
    );
  }
);
