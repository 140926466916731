import {
  AssociatedType,
  FileInfo,
  FilesApiClient,
  PostScannedInvoicesBulkResponse,
  UploadRequestObject,
  UploadRequestObjectCompleted,
} from '@melio/platform-api-axios-client';
import axios, { AxiosResponse } from 'axios';

const uploadTos3 = async (url: string, file: File) => {
  const response = await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
      'Content-Length': file.size,
    },
  });

  return response;
};

const waitFor = (associatedType: AssociatedType) => {
  if (associatedType === 'bill') {
    return 'ocr-invoice';
  }

  return undefined;
};

const isCompleted = (status: UploadRequestObject): status is UploadRequestObjectCompleted =>
  status.uploadStatus === 'COMPLETED';

type ResponseMapper<T> = (response: UploadRequestObjectCompleted[]) => T;

const mapResponseStatus = (response: UploadRequestObjectCompleted[]): FileInfo => {
  const [firstStatus] = response;
  if (!firstStatus) {
    throw new Error('File upload failed');
  }

  return firstStatus.file;
};
const mapRawResponseStatus = (response: UploadRequestObjectCompleted[]): { data: FileInfo } => {
  const [firstStatus] = response;
  if (!firstStatus) {
    throw new Error('File upload failed');
  }

  return { data: firstStatus.file };
};

const mapResponseStatuses = (response: UploadRequestObjectCompleted[]) => response.map((r) => r.file);

const uploadAndPoll = async <T>(
  files: File[],
  mapResponse: ResponseMapper<T>,
  associatedType: AssociatedType = 'bill',
  maxRetries = 10 // default value of 10 retries
): Promise<AxiosResponse<T>> => {
  const {
    data: { data: uploadRequests },
  } = await FilesApiClient.createBulkUploadRequest({ filenames: files.map((f) => f.name), associatedType });

  await Promise.all(uploadRequests.map(({ uploadUrl }, idx) => uploadTos3(uploadUrl, files[idx] as File)));

  const uploadRequestIds = uploadRequests.map(({ id }) => id);

  let statuses = await FilesApiClient.getBulkUploadRequest(uploadRequestIds, {
    params: { includes: waitFor(associatedType) },
  });

  let retryCount = 0;

  while (statuses.data.data.some((d) => d.uploadRequest.uploadStatus === 'PENDING') && retryCount < maxRetries) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    statuses = await FilesApiClient.getBulkUploadRequest(uploadRequestIds);
    retryCount++;
  }

  if (retryCount >= maxRetries) {
    throw new Error('Error fetching upload status');
  }

  const requests = statuses.data.data.map((d) => d.uploadRequest);
  const completedRequests = requests.filter(isCompleted);

  const response: AxiosResponse<T> = {
    ...statuses,
    data: mapResponse(completedRequests),
  };

  return response;
};

export const createScannedInvoices = async (files: File[]): Promise<PostScannedInvoicesBulkResponse> => {
  const { data: uploadedFiles } = await uploadAndPoll(files, mapResponseStatuses);
  const ids = uploadedFiles.map((d) => d.id);
  const { data: scannedInvoices } = await FilesApiClient.convertToScannedInvoicesBulk({ ids });
  return scannedInvoices;
};

export const createUploadRequest = async (file: File, type: AssociatedType = 'bill'): Promise<FileInfo> => {
  const { data } = await uploadAndPoll([file], mapResponseStatus, type);
  return data;
};

export const createRawUploadRequest = async (
  file: File,
  type: AssociatedType = 'user'
): Promise<AxiosResponse<{ data: FileInfo }>> => {
  const res = uploadAndPoll([file], mapRawResponseStatus, type);
  return res;
};
