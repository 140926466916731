import { useAccount } from '../account';
import { useOrganizations } from './useOrganizations';
import { isAccountingFirmOrganization } from './utils';

export const useIsAccountingFirm = ({ enabled }: { enabled: boolean } = { enabled: true }) => {
  const { data: organizations = [] } = useOrganizations({ enabled });
  const { data: account } = useAccount({ id: 'me', enabled });

  return organizations.some(
    (organization) =>
      organization.id === account?.organizationId && organization && isAccountingFirmOrganization(organization)
  );
};
