import { Form, useMelioForm } from '@melio/penny';
import { Address, CardHolderDetails, US_STATES } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';
import { object, SchemaOf, string } from 'yup';

import { FormWidgetProps } from '../../types';
import { AddressSearchWidget, AddressSearchWidgetProps } from '../AddressSearch';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return useMemo<SchemaOf<CardHolderDetails>>(
    () =>
      object().shape({
        city: string().required(formatMessage('vex.widgets.cardHolderDetailsForm.city.required')),
        firstName: string()
          .required(formatMessage('vex.widgets.cardHolderDetailsForm.firstName.required'))
          .min(2, formatMessage('vex.widgets.cardHolderDetailsForm.firstName.length')),
        lastName: string()
          .required(formatMessage('vex.widgets.cardHolderDetailsForm.lastName.required'))
          .min(2, formatMessage('vex.widgets.cardHolderDetailsForm.lastName.length')),
        line1: string().required(formatMessage('vex.widgets.cardHolderDetailsForm.address.required')).nullable(),
        state: string().required(formatMessage('vex.widgets.cardHolderDetailsForm.state.required')),
        postalCode: string().required(formatMessage('widgets.cardHolderDetailsForm.zipcode.required')),
      }),
    [formatMessage]
  );
};

export type CardHolderDetailsFormProps = FormWidgetProps<CardHolderDetails>;

export const CardHolderDetailsForm = forwardRef<CardHolderDetailsFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, defaultValues, isSaving, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const schema = useSchema();
    const { formProps, registerField, setValue } = useMelioForm<CardHolderDetails>({
      onSubmit,
      schema,
      defaultValues,
      isSaving,
      onSubmissionStateChange,
    });

    const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
      const address = event.target.value as unknown as Address;
      const setFormFieldValue = (field: keyof Omit<Address, 'line2' | 'countryCode'>) => {
        setValue(field, address ? address[field] : '', {
          shouldValidate: true,
        });
      };
      setFormFieldValue('line1');
      setFormFieldValue('state');
      setFormFieldValue('city');
      setFormFieldValue('postalCode');
    };

    return (
      <Form data-component="CardHolderDetailsForm" {...props} {...formProps} columns={2} ref={ref}>
        <Form.TextField
          labelProps={{ label: formatMessage('vex.widgets.cardHolderDetailsForm.firstName.label') }}
          placeholder={formatMessage('vex.widgets.cardHolderDetailsForm.firstName.placeholder')}
          {...registerField('firstName')}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('vex.widgets.cardHolderDetailsForm.lastName.label') }}
          placeholder={formatMessage('vex.widgets.cardHolderDetailsForm.lastName.placeholder')}
          {...registerField('lastName')}
        />
        <AddressSearchWidget
          {...registerField('line1')}
          labelProps={{ label: formatMessage('vex.widgets.cardHolderDetailsForm.address.label') }}
          placeholder={formatMessage('vex.widgets.cardHolderDetailsForm.address.placeholder')}
          onChange={handleAddressChange}
          // The 'OR' is a workaround for formatting the defaultValue we're getting for this field.
          formatSelectedValue={(option) =>
            (option.value as unknown as Address).line1 || (option.value as unknown as string)
          }
          colSpan={2}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('vex.widgets.cardHolderDetailsForm.city.label') }}
          placeholder={formatMessage('vex.widgets.cardHolderDetailsForm.city.placeholder')}
          {...registerField('city')}
        />
        <Form.DeprecatedSelect
          labelProps={{ label: formatMessage('vex.widgets.cardHolderDetailsForm.state.label') }}
          placeholder={formatMessage('vex.widgets.cardHolderDetailsForm.state.placeholder')}
          {...registerField('state')}
          options={US_STATES.map((state) => ({ value: state, label: formatMessage(`local.USA.states.${state}`) }))}
        />
        <Form.TextField
          labelProps={{ label: formatMessage('vex.widgets.cardHolderDetailsForm.zipcode.label') }}
          placeholder={formatMessage('vex.widgets.cardHolderDetailsForm.zipcode.placeholder')}
          {...registerField('postalCode')}
          colSpan={2}
        />
      </Form>
    );
  }
);
CardHolderDetailsForm.displayName = 'CardHolderDetailsForm';
