import {
  Button,
  Card,
  Container,
  Divider,
  ExternalLayout,
  Group,
  ListItem,
  Text,
  Typography,
  useToast,
  VirtualCard,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { GetVirtualCardAcceptanceResponse } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { usePageView } from '@melio/vex-domain';
import { FooterWidget, PayorPendingPaymentDetailsWidget, PoweredByMelioWidget } from '@melio/vex-widgets';
import { PropsWithChildren, useMemo } from 'react';

import { getMonth, getYear, shortDataFormatter } from './SuvcAcceptance.utils';

export type SuvcAcceptanceScreenProps = PropsWithChildren<{
  showPoweredByMelio?: boolean;
  suvcData: GetVirtualCardAcceptanceResponse;
}>;

export const SuvcAcceptanceScreen = forwardRef<SuvcAcceptanceScreenProps, 'div'>(
  ({ showPoweredByMelio, suvcData, children, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const { toast } = useToast();

    usePageView('accept-virtual-card', { Intent: 'accept-virtual-card' });

    const copyCardNumber = () => {
      navigator.clipboard
        .writeText(virtualCardData.cardNumber)
        .then(() => {
          toast({ type: 'success', title: formatMessage('widgets.suvcAcceptanceSettings.copySuccess') });
          track('suvc-acceptance', 'Click', { intent: 'copy-virtual-card-number' });
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
    };

    const virtualCardData = suvcData.data;
    const expiryMonth = useMemo(
      () => getMonth(virtualCardData.expirationDate as Date),
      [virtualCardData.expirationDate]
    );
    const expiryYear = useMemo(() => getYear(virtualCardData.expirationDate as Date), [virtualCardData.expirationDate]);
    const formattedEndDate = useMemo(
      () => shortDataFormatter.format(virtualCardData.endDate),
      [virtualCardData.endDate]
    );

    const content = (
      <Card paddingX="none" paddingY="none">
        <Group variant="vertical" spacing="none" width="full">
          <Container paddingX="xl" paddingY="l" backgroundColor="light">
            <PayorPendingPaymentDetailsWidget
              description={formatMessage('widgets.suvcAcceptanceSettings.description', {
                accountName: virtualCardData.organization.companyName,
              })}
              accountName={virtualCardData.organization.companyName}
              paymentInvoiceNumber={virtualCardData.payment.invoiceNumber}
              paymentNote={virtualCardData.payment.note}
              paymentInvoiceNumberLabel={formatMessage('widgets.suvcAcceptanceSettings.paymentInvoiceNumberLabel')}
              paymentNoteLabel={formatMessage('widgets.suvcAcceptanceSettings.paymentNoteLabel')}
              showAvatar
            />
          </Container>
          <Container paddingX="xl" paddingY="l">
            <Group variant="vertical">
              <Group variant="vertical" alignItems="center" spacing="xxl">
                <VirtualCard
                  amount={virtualCardData.amount as number}
                  brandSymbolProps={{
                    type: 'mastercard',
                    variant: 'default',
                  }}
                  brandType="jp-morgan"
                  cardNumberProps={{
                    cardNumber: virtualCardData.cardNumber,
                    label: 'Card Number',
                  }}
                  cvvNumberProps={{
                    cvv: virtualCardData.cvv,
                    label: 'CVV',
                  }}
                  expirationDateProps={{
                    label: 'Expiry date',
                    month: expiryMonth,
                    year: expiryYear,
                  }}
                  showDetails
                  variant="brand"
                />
                <Button
                  label="Copy card number"
                  onClick={copyCardNumber}
                  size="medium"
                  variant="tertiary"
                  isFullWidth
                  data-testid="suvc-acceptance-copy-card-number-button"
                />
              </Group>
              <Group variant="vertical" spacing="m" data-testid="suvc-acceptance-issuer-address">
                <ListItem
                  labelProps={{
                    label: formatMessage('widgets.suvcAcceptanceSettings.issuerAddressLabel'),
                  }}
                  mainLabelProps={{
                    label: virtualCardData.issuerAddress,
                  }}
                />
                <ListItem
                  labelProps={{
                    label: formatMessage('widgets.suvcAcceptanceSettings.processBeforeLabel'),
                  }}
                  mainLabelProps={{
                    label: formattedEndDate,
                  }}
                />
              </Group>
              <Divider />
              <Group variant="vertical">
                <Text as="h2" textStyle="body3Semi">
                  {formatMessage('widgets.suvcAcceptanceSettings.proceesCardLabel')}
                </Text>
                <Typography.ParagraphList
                  list={[
                    {
                      content: formatMessage('widgets.suvcAcceptanceSettings.proceesCardFirstLine'),
                    },
                    {
                      content: formatMessage('widgets.suvcAcceptanceSettings.proceesCardSecondLine'),
                    },
                    {
                      content: formatMessage('widgets.suvcAcceptanceSettings.proceesCardThirdLine'),
                    },
                  ]}
                  size="small"
                  type="ordered"
                />
              </Group>
            </Group>
          </Container>
        </Group>
      </Card>
    );

    const footer = <FooterWidget>{showPoweredByMelio && <PoweredByMelioWidget />}</FooterWidget>;

    return (
      <ExternalLayout
        data-component="SuvcAcceptanceActivity.SuvcAcceptanceScreen"
        data-testid="suvc-acceptance-activity-suvc-acceptance-screen"
        {...props}
        content={content}
        footer={footer}
        ref={ref}
      />
    );
  }
);

SuvcAcceptanceScreen.displayName = 'SuvcAcceptanceActivity.SuvcAcceptanceScreen';
