import { Box } from '@chakra-ui/react';
import { Group, Icon, Link, Text, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type P2DOfferBannerProps = {
  onNavigateToP2D: (e: React.MouseEvent<HTMLLinkElement>) => void;
};

export const P2DOfferBanner = ({ onNavigateToP2D }: P2DOfferBannerProps) => {
  const theme = useTheme();
  const { formatMessage } = useMelioIntl();

  const cardDetailsLink = (
    <Link
      href="#"
      color="inherit"
      onClick={onNavigateToP2D}
      data-testid="p2d-upsell-offer-on-confirm-screen-link"
      label={formatMessage('activities.paymentUpgrade.screens.paymentUpgradeConfirm.P2D.link')}
    />
  );

  return (
    <Box backgroundColor={theme.colors.neutral.lightest} borderRadius="l" padding="s">
      <Group alignItems="center">
        <Icon type="debit-card" />
        <Group variant="vertical" spacing="none">
          <Text textStyle="body3">
            {formatMessage('activities.paymentUpgrade.screens.paymentUpgradeConfirm.P2D.message')}
          </Text>
          <Text color="brand.main" textStyle="body3">
            {cardDetailsLink}
          </Text>
        </Group>
      </Group>
    </Box>
  );
};

P2DOfferBanner.displayName = 'P2DOfferBanner';
