import { Form, useMelioForm } from '@melio/penny';
import { US_STATES } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { CheckAccountFormModel, FormWidgetProps } from '../../../../types';
import { useCheckValidationSchema } from '../useCheckValidationSchema';
export type VendorCheckManuallyAddressPayeeFormProps = FormWidgetProps<CheckAccountFormModel>;
export const VendorCheckManuallyAddressPayeeForm: React.FC<VendorCheckManuallyAddressPayeeFormProps> = forwardRef<
  VendorCheckManuallyAddressPayeeFormProps,
  'form'
>(({ onSubmit, defaultValues, isSaving, onSubmissionStateChange, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();

  const { formProps, registerField } = useMelioForm<CheckAccountFormModel>({
    onSubmit,
    schema: useCheckValidationSchema(),
    defaultValues,
    isSaving,
    onSubmissionStateChange,
    subscribeToDefaultValuesChanges: true,
  });

  return (
    <Form data-component="VendorCheckManuallyAddressPayeeForm" {...props} {...formProps} columns={2} ref={ref}>
      <Form.TextField
        colSpan={2}
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.printName.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.printName.placeholder')}
        helperTextProps={{
          label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.printName.helperText'),
        }}
        {...registerField('printName')}
      />
      <Form.TextField
        {...registerField('line1')}
        colSpan={2}
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.line1.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.line1.placeholder')}
      />
      <Form.TextField
        colSpan={2}
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.line2.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.line2.placeholder')}
        {...registerField('line2')}
      />
      <Form.TextField
        colSpan={2}
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.city.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.city.placeholder')}
        {...registerField('city')}
      />
      <Form.DeprecatedSelect
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.state.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.state.placeholder')}
        {...registerField('state')}
        options={US_STATES.map((state) => ({ value: state, label: formatMessage(`local.USA.states.${state}`) }))}
      />
      <Form.TextField
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.postalCode.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayeeForm.postalCode.placeholder')}
        {...registerField('postalCode')}
      />
    </Form>
  );
});

VendorCheckManuallyAddressPayeeForm.displayName = 'VendorCheckManuallyAddressPayeeForm';
