import { Box } from '@chakra-ui/react';
import { Card, Checkbox, Container, Group, Text } from '@melio/penny';
import { useIsMobile } from '@melio/vex-widgets';
import { ChangeEventHandler } from 'react';

import type { PersistentFastLabels } from './types';

type Props = {
  isChecked: boolean;
  isDisabled: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  labels: PersistentFastLabels['offer'];
};

export const PersistentFastPreferenceOfferBanner = ({ isChecked, isDisabled, onChange, labels }: Props) => {
  const isMobile = useIsMobile();

  return (
    <Card data-testid="persistent-fast-offer-banner" variant="flat" paddingX="none" paddingY="none">
      <Container paddingX="s" paddingTop="xs-s" paddingBottom="s">
        <Group>
          <Box paddingTop={isMobile ? undefined : '2px'}>
            <Checkbox
              data-testid="persistent-fast-offer-banner-checkbox"
              isChecked={isChecked}
              isDisabled={isDisabled}
              onChange={onChange}
              size={isMobile ? 'small' : 'large'}
            />
          </Box>
          <Group spacing="xs" variant="vertical">
            <Text textStyle={isMobile ? 'body4' : 'body2'} as="p" data-testid="persistent-fast-offer-banner-title">
              {labels.title}
            </Text>
            <Text
              textStyle="body4"
              color="neutral.darker"
              as="p"
              data-testid="persistent-fast-offer-banner-description"
            >
              {labels.description}
            </Text>
          </Group>
        </Group>
      </Container>
    </Card>
  );
};

PersistentFastPreferenceOfferBanner.displayName = 'PersistentFastPreferenceOfferBanner';
