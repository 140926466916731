import { Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { SubscriptionBillingCycleEnum } from '../../../../api';
import { usePartnerGroups } from '../../../../hooks';

type ContextualPlanPriceSectionProps = {
  billingCycle: SubscriptionBillingCycleEnum;
  price: number;
};

export const ContextualPlanPriceSection = ({ billingCycle, price }: ContextualPlanPriceSectionProps) => {
  const { isFiservPartner } = usePartnerGroups();
  const { formatCurrency, formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical">
      {price > 0 ? (
        <Group alignItems="center" spacing="xs">
          <Text textStyle="heading3Semi">{formatCurrency(price)}</Text>
          <Text textStyle="body3">
            /{formatMessage(`activities.subscription.contextualPlans.billing.title.${billingCycle}`)}
          </Text>
        </Group>
      ) : (
        <Text textStyle="heading3Semi">
          {formatMessage('activities.subscription.contextualPlans.billing.price.free')}
        </Text>
      )}
      {!isFiservPartner && (
        <Text textStyle="body3" color="neutral.darker">
          {formatMessage(`activities.subscription.contextualPlans.billing.description.${price ? 'annually' : 'free'}`)}
        </Text>
      )}
    </Group>
  );
};
