import { atom, DefaultValue, selector, useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { KycComplianceState } from '@melio/ap-activities';

import { getPartnerNameFromUrl } from '@/utils/partner.utils';

const partnerName = getPartnerNameFromUrl();
const COMPLIANCE_STATE_KEY = `${partnerName}-accountComplianceState`;

const accountComplianceSessionValue = sessionStorage.getItem(COMPLIANCE_STATE_KEY);

const persistDefaultValue = accountComplianceSessionValue
  ? (JSON.parse(accountComplianceSessionValue!) as KycComplianceState)
  : {};

export const complianceState = atom<KycComplianceState>({
  key: COMPLIANCE_STATE_KEY,
  default: persistDefaultValue,
});

export const accountsComplianceSelector = selector<KycComplianceState>({
  key: `${COMPLIANCE_STATE_KEY}Selector`,
  get: ({ get }) => {
    return get(complianceState);
  },
  set: ({ get, set }, accountCompliance) => {
    if (accountCompliance instanceof DefaultValue) {
      return;
    }
    const state = get(complianceState);
    const nextState = { ...state, ...accountCompliance };
    sessionStorage.setItem(COMPLIANCE_STATE_KEY, JSON.stringify(nextState));
    set(complianceState, nextState);
  },
});

export const useSetAccountComplianceRecoilState = () => {
  const set = useSetRecoilState(accountsComplianceSelector);
  const reset = useResetRecoilState(accountsComplianceSelector);

  return (accountCompliance: KycComplianceState | null) => {
    if (accountCompliance) {
      set((state) => ({ ...state, ...accountCompliance }));
    } else {
      sessionStorage.removeItem(COMPLIANCE_STATE_KEY);
      reset();
    }
  };
};

export const useAccountComplianceRecoilState = () => {
  const [state] = useRecoilState(accountsComplianceSelector);
  return state;
};
