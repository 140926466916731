import { ApiUpdateRequest, extractData } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { MutationFunction, useIsMutating, useMutation, UseMutationOptions } from 'react-query';

import {
  ApiError,
  ApiQueryKeyV2,
  MutationCallbackOptions,
  MutationContext,
  Parser,
  PromiseFunctionReturnData,
  UpdateMutationPayload,
} from './types';
import { useOnUpdateErrorV2, useOnUpdateMutateV2, useOnUpdateSuccessV2 } from './utilV2';

type UseUpdateMutationOptions<TQueryData, TVariables, TData = TQueryData, TError = ApiError> = MutationCallbackOptions<
  TData,
  TVariables,
  TVariables,
  TError
> & {
  optimisticUpdate?: boolean;
  prepareParams?: Parser<TVariables>;
};

export function useUpdateMutationV2<
  TQueryFn extends ApiUpdateRequest,
  TData = PromiseFunctionReturnData<TQueryFn>,
  TVariables = Required<Parameters<TQueryFn>>[1],
  TError = ApiError
>(
  _mutationFn: TQueryFn | undefined,
  queryKey: ApiQueryKeyV2,
  options: UseUpdateMutationOptions<TData, TVariables, TData, TError> = {}
) {
  type TQueryFnData = PromiseFunctionReturnData<TQueryFn>;
  type Payload = UpdateMutationPayload<TVariables>;

  const mutationFn: MutationFunction<TQueryFnData, Payload> | undefined =
    _mutationFn &&
    (({ id, params }) =>
      _mutationFn(id, options.prepareParams?.(params) ?? params).then(extractData<TQueryFnData, TData>));

  const onSuccess = useOnUpdateSuccessV2<TData, TVariables, TError>(queryKey);
  const onMutate = useOnUpdateMutateV2<TData, TVariables, TError>(queryKey);
  const onError = useOnUpdateErrorV2<TData, TVariables, TError>(queryKey);

  const mutationOptions: UseMutationOptions<TData, TError, Payload, MutationContext<TData>> = {
    onSuccess: (data, payload, context) => {
      void onSuccess(data, payload.params, context);
      void options.onSuccess?.(options.select ? options.select(data) : data, payload.params, context);
      void options.onSuccessInternal?.(data, payload.params, context);
    },
    onError: (data, payload, context) => {
      if (options.optimisticUpdate) {
        void onError(data, payload.params, context);
      }
      void options.onError?.(data, payload.params, context);
    },
  };

  if (options.optimisticUpdate) {
    mutationOptions.onMutate = (payload) => onMutate(payload.params);
  }

  const mutation = useMutation<TData, TError, Payload, MutationContext<TData>>(queryKey, mutationFn, mutationOptions);
  const update = (id: string, params: TVariables) => mutation.mutateAsync({ id, params });
  const createCallback = (id: string) => (params: TVariables) => update(id, params);
  const isMutating = !!useIsMutating(queryKey);

  return {
    ...mutation,
    isMutating,
    update,
    createCallback,
  };
}
