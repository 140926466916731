import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useSetRecoilState } from 'recoil';
import { PartnerName } from '@melio/platform-api';

import { appPathPrefixSelector, selectedPartner } from '@/store/app/app.model';

export const useInitPartner = (partner: PartnerName, withPartnerPrefix?: boolean) => {
  const setCurrentPartner = useSetRecoilState(selectedPartner);
  const setAppPathPrefix = useSetRecoilState(appPathPrefixSelector);

  useEffect(() => {
    setCurrentPartner(partner);
    datadogRum.setGlobalContextProperty('partnerName', partner);
  }, [partner, setCurrentPartner]);

  useEffect(() => {
    setAppPathPrefix(withPartnerPrefix ? `/${partner}` : '');
  }, [setAppPathPrefix, partner, withPartnerPrefix]);
};
