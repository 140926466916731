import { Logger } from '@melio/platform-logger';

import { usePlansTiers } from '../../../../hooks/usePlansTiers';
import { FeatureRequest, getIsFeatureInPlan } from '../../../../utils/planFeatures.utils';

export const useFirstPlanWithFeature = () => {
  const { plans } = usePlansTiers();

  const getFirstPlanWithFeature = (feature: FeatureRequest) => {
    const firstPlanWithFeature = plans.find((plan) => getIsFeatureInPlan(plan, feature).isEligible);

    if (!firstPlanWithFeature) {
      const error = `a plan with the feature not found. featureName: ${feature.featureName}`;
      Logger.log(error, 'error');
      throw new Error(error);
    }

    return firstPlanWithFeature;
  };

  return { getFirstPlanWithFeature };
};
