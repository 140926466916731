import { Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { isNil } from 'lodash';

import type { Plan, SubscriptionBillingCycleEnum } from '../../../../api';
import { usePlanInfo, usePlansTiers } from '../../../../hooks';

type PlanCardFeeFooterProps = {
  plan: Plan;
  selectedBillingCycle: SubscriptionBillingCycleEnum;
};
export const PlanCardFeeFooter = ({ plan, selectedBillingCycle }: PlanCardFeeFooterProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { getNextPlanWithUnlimitedSeats } = usePlansTiers();
  const nextUnlimitedSeatsPlan = getNextPlanWithUnlimitedSeats(plan.id);
  const nextUnlimitedSeatsPlanInfo = usePlanInfo(nextUnlimitedSeatsPlan?.id);

  const unitPrice = plan.cycles[selectedBillingCycle]?.unitPrice;
  const canAddSeats = !isNil(unitPrice);
  return (
    <Container>
      <Container paddingBottom="xs">
        <Text as="h3" textStyle="body3Semi">
          {formatMessage('activities.subscription.plans.planCard.fees')}
        </Text>
      </Container>
      <Group variant="horizontal" justifyContent="space-between">
        <Text as="div" textStyle="body3" color="neutral.darkest">
          {formatMessage('activities.subscription.plans.planCard.additionalSeats')}
        </Text>
        {canAddSeats ? (
          <Text as="div" textStyle="body3" color="neutral.darkest">
            {formatCurrency(unitPrice ?? 0, 'USD')}
            {formatMessage('activities.subscription.plans.planCard.price.perMonth')}
          </Text>
        ) : (
          <Text as="div" textStyle="body3" color="neutral.darkest">
            {formatMessage('activities.subscription.upgradeModal.title', {
              planName: nextUnlimitedSeatsPlanInfo.planName,
            })}
          </Text>
        )}
      </Group>
    </Container>
  );
};
