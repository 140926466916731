/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useToast } from '@melio/penny';

import { useSetAppToast } from '@/store/app/app.model';
import { appToastSelector } from '@/store/app/app.model';

const TOAST_POSITION = 'top';

export const ToastProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const appToast = useRecoilValue(appToastSelector);
  const setAppToast = useSetAppToast();

  React.useEffect(() => {
    if (appToast) {
      toast({
        ...appToast,
        duration: null,
        position: TOAST_POSITION,
        onCloseComplete: () => setAppToast(undefined),
      });
    }
  }, [appToast]);

  return <>{children}</>;
};
